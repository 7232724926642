import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
	FormatDateTimeFromYear,
	FormatPhoneNumber,
	FormatDateTime,
	FormatTime,
	FormatHours,
	FormatTimeIntoHM,
} from '../../imports/API/TextFormatingFunctions.js';
import { useNavigate } from 'react-router-dom';

const KioskAlert = ({ kioskAlert, callLogs, color, SetKioskIDIssueForCreateJob, alertInfo, issues, onClose }) => {
	const [state, setState] = useState({
		StepAlertIsAt: kioskAlert.StepAlertIsAt,
		updateToDoNext: JSON.parse(kioskAlert.ToDoNext),
		WaitTime: kioskAlert.WaitTime,
		input: '',
		alertToDoHistory: [],
		AssignAlertToJobID: '',
		alertNotes: kioskAlert.Notes,
		CallLogHistory: callLogs,
	});

	const navigate = useNavigate();

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			OnLoad();
			GetLogsForKiosk();
		}

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		setState((prevState) => ({
			...prevState,
			alertToDoHistory: GetAlertHistory(),
		}));
	}, [kioskAlert]);

	const GetAlertHistory = () => {
		let update_to_do = JSON.parse(kioskAlert.ToDoNext);
		let StepAlertIsAt = kioskAlert.StepAlertIsAt.charCodeAt(0);
		let alertToDoHistory = [];
		for (let i = 65; i < StepAlertIsAt; i++) {
			alertToDoHistory.push(update_to_do[String.fromCharCode(i)]);
		}
		return alertToDoHistory;
	};

	const GetLogsForKiosk = () => {
		try {
			const logs = callLogs;
			const KioskID = `${kioskAlert.KioskID} ${kioskAlert.ServerID}`;
			const list = logs.filter((log) => KioskID === log.KioskID);
			setState((prevState) => ({ ...prevState, CallLogHistory: list }));
		} catch (error) {
			console.error('Error fetching logs for kiosk:', error.message);
			throw error;
		}
	};

	const CloseActionItems = (e) => {
		document.getElementById('ToDoList' + e.target.id).style.display = 'none';
		document.getElementById('GreyBackground').style.display = 'none';
	};

	const ToDoNext = (e) => {
		let ToDoList = document.getElementsByClassName('ToDoList');
		let step = state.StepAlertIsAt.charCodeAt(0);
		let currentStep = String.fromCharCode(step - 1);

		for (let i = 0; i < ToDoList.length; i++) {
			ToDoList[i].style.display = 'none';
		}

		if (currentStep === 'A' && state.CallLogHistory.length >= 2) {
			alert('This kiosk has reported 2 or more issues within the last 30 days! Please monitor closely.');
		}

		document.getElementById('ToDoList' + e.target.id.replace(/(A)/g, '')).style.display = 'block';
		document.getElementById('GreyBackground').style.display = 'block';
	};

	const RenderAlertHistory = () => {
		return state.alertToDoHistory.map((alertSteps, index) => <li key={index}>{alertSteps}</li>);
	};

	const CreateJobFromKioskAlert = (e) => {
		try {
			const idLength = kioskAlert.AlertID.toString().length + 6;
			SetKioskIDIssueForCreateJob(e.target.id, idLength);
			document.getElementById('ToDoList' + e.target.id.slice(6, idLength)).style.display = 'none';
			document.getElementById('GreyBackground').style.display = 'none';
			document.getElementById('CreateJobDialog').style.display = 'block';
			document.getElementById('KioskIDInput').value = `${kioskAlert.KioskID} ${kioskAlert.ServerID}`;
			document.getElementById('IssueInput').value = kioskAlert.Description;
			document.getElementById('CreateButton').disabled = false;
		} catch (error) {
			console.error('Error creating job from kiosk alert:', error.message);
			throw error;
		}
	};

	const FormatToDoNext = (e) => {
		try {
			const alertID = e.target.id.replace(/(A)/g, '');
			const currentStep = state.StepAlertIsAt.charCodeAt(0);
			const jobID = document.getElementById(`AssignAlertToJobID-${kioskAlert.AlertID}`).value;

			if (kioskAlert.Description.startsWith('Incomplete') && jobID !== '') {
				alert(`Cannot assign an incomplete alert to a job. Instead assign the alert that triggered the incomplete.`);
				return;
			}
			if (document.getElementById('checkBox' + alertID).checked === true || e.checked) {
				let stepsKeys = Object.keys(state.updateToDoNext);
				if (stepsKeys[stepsKeys.length - 1] !== state.StepAlertIsAt) {
					if (stepsKeys[stepsKeys.length - 2] === state.StepAlertIsAt) {
						document.getElementById('checkBox' + alertID).disabled = true;
					}

					const nextStep = e.checked
						? e.target.issue === 'Internet Issue'
							? 'E'
							: 'G'
						: String.fromCharCode(currentStep + 1);

					const step_instructions = state.updateToDoNext ? state.updateToDoNext[nextStep].split(' ') : null;
					const wait_time =
						step_instructions && step_instructions[0] === 'Wait' && step_instructions[2] === 'minutes'
							? parseInt(step_instructions[1])
							: null;

					if (wait_time) {
						const currentTime = new Date().getTime();
						const time_to_wait = currentTime + 60000 * wait_time;
						socket.emit(
							`insertWaitTime`,
							{ AlertID: kioskAlert.AlertID, WaitTime: FormatDateTimeFromYear(time_to_wait) },
							(res) => {
								if (!res) {
									console.error('There was an issue inserting the wait time for this alert.');
								} else {
									updateStep(nextStep, alertID, jobID);
								}
							}
						);
					} else {
						updateStep(nextStep, alertID, jobID);
					}
				}
				document.getElementById('checkBox' + alertID).checked = false;
			}
			if (jobID) {
				socket.emit('updateJobIDWithAlert', alertID, jobID, () => {
					try {
						ClearIssueInDatabase(e);
					} catch (error) {
						alert('Kiosk Alert was not deleted.');
					}
				});
			}
			setState((prevState) => ({ ...prevState, AssignAlertToJobID: '' }));
			document.getElementById('ToDoList' + alertID).style.display = 'none';
			document.getElementById('GreyBackground').style.display = 'none';
		} catch (error) {
			console.error('Error formatting ToDoNext:', error);
		}
	};

	const updateStep = (nextStep, alertID, jobID) => {
		try {
			const alertToDoHistory = [...state.alertToDoHistory, state.updateToDoNext[nextStep]];
			socket.emit('updateStepToAlert', { AlertID: alertID, StepAlertIsAt: nextStep, JobID: jobID }, (boolean) => {
				if (boolean) {
					document.getElementById(`A${alertID}`).style.backgroundColor = '#ff8080';
					setState((prevState) => ({
						...prevState,
						StepAlertIsAt: nextStep,
						alertToDoHistory: alertToDoHistory,
					}));
				} else {
					console.error('There was an issue updating this alert to the next step.');
				}
			});
		} catch (error) {
			console.error('Error updating step:', error.message);
			throw error;
		}
	};

	const OnLoad = () => {
		try {
			const kioskAlertCheckBox = document.getElementsByClassName('kioskAlertCheckBox');
			for (let i = 0; i < kioskAlertCheckBox.length; i++) {
				if (kioskAlertCheckBox[i].value.split(' ')[0] == 'Wait' || kioskAlertCheckBox[i].value == 'Create Job') {
					kioskAlertCheckBox[i].disabled = true;
				} else if (kioskAlertCheckBox[i].value == 'Clear') {
					kioskAlertCheckBox[i].disabled = false; // Corrected from checkBox.disabled to kioskAlertCheckBox[i].disabled
					kioskAlertCheckBox[i].style.visibility = 'hidden';
				} else {
					kioskAlertCheckBox[i].disabled = false; // Corrected from checkBox.disabled to kioskAlertCheckBox[i].disabled
				}
			}
		} catch (error) {
			console.error('Error during OnLoad:', error.message);
			throw error;
		}
	};

	const RenderIfButton = (phoneNumber, passedString, kioskStatus) => {
		let alertDesc = kioskAlert.Description;

		if (kioskStatus === 'Cleared') {
			return 'Issue has cleared, Check if technician is at the kiosk. If not, delete the job associated with this alert.';
		}

		if (alertDesc == 'Incomplete Transaction') {
			if (passedString.includes('Clear Issue')) {
				return (
					<button
						className='ClearAlertBtn'
						id={kioskAlert.KioskID + kioskAlert.AlertID + kioskAlert.Description}
						onClick={ClearIssueInDatabase}
					>
						Clear Alert
					</button>
				);
			} else if (passedString == 'Call store customer') {
				return 'Call Store at ' + phoneNumber + ' to see if the customer is still at the store';
			}
		} else {
			if (passedString == 'Create Job') {
				return (
					<button
						className='CreateJobBtn'
						id={kioskAlert.KioskID + kioskAlert.AlertID + kioskAlert.Description}
						onClick={CreateJobFromKioskAlert}
					>
						Dispatch Job
					</button>
				);
			} else if (passedString === 'Create Job Immediately') {
				return (
					<button
						className='CreateJobNowBtn'
						id={kioskAlert.KioskID + kioskAlert.AlertID + kioskAlert.Description}
						onClick={CreateJobFromKioskAlert}
					>
						Dispatch Job Immediately
					</button>
				);
			} else if (passedString == 'Clear') {
				return (
					<button
						className='ClearAlertBtn'
						id={kioskAlert.KioskID + kioskAlert.AlertID + kioskAlert.Description}
						onClick={ClearIssueInDatabase}
					>
						Clear Alert
					</button>
				);
			} else if (passedString === 'Call store') {
				return 'Call Store at ' + phoneNumber + ' to power cycle';
			} else if (passedString === 'Call store customer') {
				return 'Call Store at ' + phoneNumber + ' to see if the customer is still at the store';
			} else if (!passedString) {
				return passedString;
			} else if (passedString.split(' ')[0] === 'Wait' && passedString.split(' ')[2] === 'minutes') {
				let currentTime = new Date().getTime();
				let setWaitTime = !state.WaitTime
					? currentTime + 60000 * parseInt(passedString.split(' ')[1])
					: new Date(state.WaitTime).getTime();
				return 'Wait until ' + FormatTime(setWaitTime);
			} else {
				return passedString;
			}
		}
	};

	const ClearIssueInDatabase = (e) => {
		try {
			const idLength = kioskAlert.AlertID.toString().length + 6;
			const pattern = '^.{' + idLength + '}';
			const re = new RegExp(pattern, 'g');
			socket.emit(
				'clearAlert',
				{ kiosk: e.target.id.slice(0, 6), issue: e.target.id.replace(re, ''), origin: 'console' },
				async (result) => {
					if (result) {
						await axios.post('/api/refreshAlert');
					} else {
						alert('Kiosk Alert was not deleted.');
					}
				}
			);

			document.getElementById('ToDoList' + e.target.id.slice(6, idLength)).style.display = 'none';
			document.getElementById('GreyBackground').style.display = 'none';
		} catch (error) {
			console.error('Error clearing issue in database:', error.message);
			throw error;
		}
	};

	const sortAlerts = (props, kioskID, issues) => {
		if (props.length == 0) return;

		let KioskAlertArray = props.filter((prop) => prop.KioskID == kioskID);
		KioskAlertArray.sort();

		if (KioskAlertArray.length == 0) return;

		let IssueAndDateArray = KioskAlertArray.slice(0, 5).map((alert) => {
			let timeStamp = new Date(alert.OccuranceDateTimeStamp);
			let kioskAlert = issues.find((issue) => issue.KioskIssueID == alert.KioskIssueID)?.Description || '';
			return { KioskIssue: kioskAlert, Date: timeStamp };
		});

		return IssueAndDateArray.map((issue, index) => (
			<p key={index}>
				{issue.KioskIssue} / {FormatDateTime(issue.Date)}
			</p>
		));
	};

	const handleKioskMap = () => {
		onClose();
		navigate(`/map?id=${kioskAlert.KioskID}&type=kiosk`, { state: { dataRefresh: true } });
		// navigate(`/map_test?id=${kioskAlert.KioskID}&type=kiosk`, { state: { dataRefresh: true } });
	};

	return (
		<div className='AlertContainer' id={kioskAlert.AlertID}>
			<div className='KioskAlertContainer'>
				<div className='AlertKioskServerID'>
					<label className='AlertKioskID'> {kioskAlert.KioskID}</label>/
					<label className='AlertServerID'> {kioskAlert.ServerID}</label>
				</div>
				<label className='AlertDateTime'>{FormatDateTime(kioskAlert.OccuranceDateTimeStamp)}</label>
				<br />
			</div>
			<div className='KioskAlertDownTime'>
				<label>Down For: {FormatTimeIntoHM(kioskAlert.down_time)}</label>
			</div>
			<div className='IssueContainer'>
				<label
					className='AlertIssue'
					id={'issue' + kioskAlert.AlertID}
					title='If there is an internet issue and the kiosk uses the store internet, do not dispatch this job.'
				>
					{kioskAlert.Description}
				</label>
				<div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
					<label className='AlertService' id={'service' + kioskAlert.AlertID}>
						Modem: {kioskAlert.ModemType}
					</label>
					<label className='AlertService'>Carrier: {kioskAlert.Carrier || 'N/A'}</label>
				</div>
				<div className='AlertNotificationDiv'>
					<b>
						{state.CallLogHistory.length >= 2
							? 'This Kiosk has reported 2 or more issues within the last 30 days! Please monitor closely.'
							: ''}
					</b>
				</div>
				<div className='AlertNotesDiv'>{state.alertNotes}</div>
				<ul>{RenderAlertHistory()}</ul>
				<button
					style={{ backgroundColor: color }}
					className='ToDoNext'
					id={'A' + kioskAlert.AlertID}
					onClick={ToDoNext}
				>
					Action Item
				</button>

				<div className='ToDoList' id={'ToDoList' + kioskAlert.AlertID}>
					<h1>Action Items</h1>
					<ul>{RenderAlertHistory()}</ul>
					<div style={{ textAlign: 'center', marginBottom: 20 }}>
						<a
							style={{ fontSize: 18, color: 'blue', textDecoration: 'underline' }}
							href={`https://wi.registration-technology.com:8183/report/${kioskAlert.KioskID}`}
							target='_blank'
							rel='noreferrer'
						>
							Go to Heartbeat
						</a>
					</div>
					<label className='AlertToDoNext'>
						{state.updateToDoNext[state.StepAlertIsAt].includes('Clear') ? (
							<button
								className='ClearAlertBtn'
								id={kioskAlert.KioskID + kioskAlert.AlertID + kioskAlert.Description}
								onClick={ClearIssueInDatabase}
							>
								Clear Alert
							</button>
						) : (
							<input
								type='checkbox'
								className='kioskAlertCheckBox'
								id={'checkBox' + kioskAlert.AlertID}
								value={state.updateToDoNext[state.StepAlertIsAt]}
							/>
						)}

						{RenderIfButton(
							FormatPhoneNumber(kioskAlert.StorePhone),
							state.updateToDoNext[state.StepAlertIsAt],
							kioskAlert.KioskStatus
						)}
					</label>
					<br />
					<br />
					<input
						className='AssignAlertToJobID'
						id={`AssignAlertToJobID-${kioskAlert.AlertID}`}
						type='text'
						placeholder='Assign Alert to Job ID'
						style={{ width: '150px', position: 'inherit', margin: 'auto', left: '0', right: '0', textAlign: 'center' }}
					/>
					<br />
					<button
						className='CreateJobNowBtn'
						id={kioskAlert.KioskID + kioskAlert.AlertID + kioskAlert.Description}
						onClick={CreateJobFromKioskAlert}
					>
						Dispatch Job Immediately
					</button>
					<button className='CloseActionItemsBtn' id={kioskAlert.AlertID} onClick={CloseActionItems}>
						Close
					</button>{' '}
					<button className='AlertToDoNextConfirm' id={kioskAlert.AlertID} onClick={FormatToDoNext}>
						Confirm
					</button>
				</div>
			</div>
			<div style={{ textAlign: 'center', fontSize: '10px', lineHeight: '0.25' }}>
				<p></p>
				<b>Last 5 Issues:</b>
				{sortAlerts(alertInfo, kioskAlert.KioskID, issues)}
			</div>
			<div className='text-center'>
				<a style={{ color: 'blue', textDecoration: 'underline' }} href='#' onClick={handleKioskMap}>
					View on Map
				</a>
			</div>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
				<label>
					{kioskAlert.Temp ? Math.round(kioskAlert.Temp) : ''}
					&#8457;&nbsp;
				</label>
				<label>{kioskAlert.Weather ? kioskAlert.Weather : ''}</label>
				<img src={kioskAlert.Icon} width='30px' alt='Weather Icon' />
			</div>
			<div className='StoreInfoContainer'>
				<div className='StoreInformationLeft'>
					<label
						className='AlertStoreNumber'
						id={'Phone' + kioskAlert.AlertID}
						value={FormatPhoneNumber(kioskAlert.StorePhone)}
					>
						{FormatPhoneNumber(kioskAlert.StorePhone)}
						<br />
						{FormatHours(kioskAlert.StoreHours)}
					</label>
				</div>
				<div className='StoreInformationRight'>
					<label className='AlertStoreName'>{kioskAlert.StoreName}</label>
					<br />
					<label className='AlertStoreAddress'>{kioskAlert.StoreAddress}</label>
					<br />
					<label className='AlertStoreCityStateZip'>
						{kioskAlert.StoreCity}, {kioskAlert.StoreState}. {kioskAlert.StoreZip}
					</label>
				</div>
			</div>
		</div>
	);
};

KioskAlert.propTypes = {
	AlertID: PropTypes.number,
	kioskAlert: PropTypes.object,
	callLogs: PropTypes.array,
	color: PropTypes.string,
	SetKioskIDIssueForCreateJob: PropTypes.func,
	alertInfo: PropTypes.array,
	issues: PropTypes.array,
	onClose: PropTypes.func,
	length: PropTypes.number,
	filter: PropTypes.func,
};

export default KioskAlert;
