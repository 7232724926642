import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../../StyleSheets/JobModal.css';
import { CurrentTime } from '../API/Moment';
import AlertBar from '../../components/AlertBar';
import { FormatPhoneNumber, FormatDateTimeWithoutYear, FormatBetweenDates } from '../API/TextFormatingFunctions.js';
import $ from 'jquery';

//Formats the KioskInfo Modal
const JobModal = (props) => {
	const [MessagesSentToList, setMessagesSentToList] = useState([]);
	const [ResponsesList, setResponsesList] = useState([]);
	const [TechInputETA, setTechInputETA] = useState('');
	const [TechInputETAObject, setTechInputETAObject] = useState([]);
	const [DispatchDirectly, setDispatchDirectly] = useState('');
	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});
	const [DispatchDirectlyObject, setDispatchDirectlyObject] = useState([]);
	useEffect(() => {
		let mounted = true;
		if (props.jobKey && mounted) {
			const messages =
				props.paperJobs && props.paperJobs.length
					? props.paperJobs.find((job) => job.JobID[0] != 'P' && props.jobModal.JobID == job.JobID).MessagesSent
					: props.jobModal.MessagesSentTo;

			const responses =
				props.paperJobs && props.paperJobs.length
					? props.paperJobs.find((job) => job.JobID[0] != 'P' && props.jobModal.JobID == job.JobID).Responses
					: props.jobModal.Responses;

			setMessagesSentToList(JSON.parse(messages));
			setResponsesList(JSON.parse(responses));
		}

		return () => {
			mounted = false;
		};
	}, [props.jobModal.JobID, props.jobModal.Responses]); // looks to see if the responses prop has changed and updates state

	//Updates TextFields with what the User is inputing through setstate
	const onChange = (whichValue, e) => {
		switch (whichValue) {
			case 'TechObjectInputETA':
				setTechInputETA(e.target.value);
				break;
			case 'TechObjectDispatchDirectly':
				setDispatchDirectly(e.target.value);
				break;
		}
	};

	// See if a paper jobs exists for the selected job
	const seeIfPaperJobExists = (paperJobs) => {
		const exists = paperJobs ? paperJobs.find((job) => job.JobID[0] == 'P') : null;

		if (props.jobModal.JobID[0] != 'P' && exists) {
			let open = false;
			const open_jobs = JSON.parse(exists.Kiosks);
			open_jobs.forEach((job) => {
				if (job.KioskID == props.jobModal.KioskID) {
					if (job.KioskStatus == 'Open') {
						// if job is completed, set allow to true
						open = true;
					}
				}
			});

			if (open) {
				return (
					<div style={{ backgroundColor: '#FFFF00', textAlign: 'center', fontSize: '14px' }}>
						Open Paper Job For This Kiosk {exists.JobID}. Please Send {exists.TempTechnician}
					</div>
				);
			}
		}
	};

	const OverrideServiceReport = () => {
		let kiosk = props.PaperKioskIDKey == 0 ? props.jobModal.KioskID : props.PaperKioskIDKey;

		socket.emit('updatePhoneInfo', kiosk, 'Yes', (boolean) => {
			if (!boolean) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'ERROR: There was an issue overriding this service report for Kiosk ' + kiosk,
					severity: 'error',
				}));
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message:
						'SUCCESS! Override for phone input on service report is complete.  Please have the technician refresh the service report page and submit the report again.',
					severity: 'success',
				}));
			}
		});
	};

	function FormatStatus(status) {
		if (status == 'Awaiting ETAs' || status == 'Awaiting Response') {
			return status;
		}
		return (
			'Assigned ' +
			FormatDateTimeWithoutYear(props.jobModal.AssignedDate) +
			' - ETA ' +
			props.jobModal.EstimateTimeArrival.replace(/"/g, '') +
			' (' +
			props.jobModal.Technician +
			')'
		);
	}

	const TechSelectedHandler = (e) => {
		let id = e.target.id;
		let className = e.target.className;

		if (className && className == 'InputETATechObjectList') {
			setTechInputETA(JSON.parse(id));
			setTechInputETAObject(JSON.parse(id));
			$('#TableInputETAList').toggle();
			$('#TableDispatchDirectlyList').toggle();
		} else if (className && className == 'DispatchDirectlyTechObjectList') {
			setDispatchDirectly(JSON.parse(id));
			setDispatchDirectlyObject(JSON.parse(id));
			$('#TableInputETAList').toggle();
			$('#TableDispatchDirectlyList').toggle();
		}
	};

	//Reactive Table List that will only Display a list that matches string from the input
	function RenderList(passedValue, passedArray, passedListClassName, passedTableListClassName) {
		//Filters the passedArray so the only values saved in filteredArray are the ones that partially match the passedValue
		let value = '',
			filteredArray = [];
		if (passedArray) {
			for (let j = 0; j < passedArray.length; ++j) {
				value = passedArray[j];
				if (value.toLowerCase().substring(0, passedValue.length) === passedValue.toLowerCase()) {
					filteredArray.push(value);
				}
			}
		}

		//Saves the filteredArray into the array that will display as a list under the text inputs
		let returnArray = [];
		if (filteredArray) {
			for (let i = 0; i < filteredArray.length; i++) {
				returnArray.push(
					<li
						key={i}
						className={passedListClassName}
						id={JSON.stringify(filteredArray[i])}
						value={filteredArray[i]}
						onClick={TechSelectedHandler}
					>
						{filteredArray[i]}
					</li>
				);
			}
		}

		//Returns an Unordered List
		return (
			<ul className={passedTableListClassName} id={passedTableListClassName}>
				{returnArray}
			</ul>
		);
	}

	//Hides ResendOptionsBtn
	//Displays ResendToPrimarysBtn and SendToBackupsBtn
	function ResendOptions() {
		document.getElementById('ResendOptionsBtn').style.display = 'none';
		if (props.jobModal.JobID.charAt(0) == 'P') {
			document.getElementById('ResendToPaperChangerBtn').style.display = 'inline';
			document.getElementById('PrintLetterBtn').style.display = 'inline';
			document.getElementById('EmailLetterBtn').style.display = 'inline';
		} else {
			document.getElementById('ResendToPrimarysBtn').style.display = 'inline';
			document.getElementById('SendToBackupsBtn').style.display = 'inline';
		}
	}

	//Resends a Job Request Txt Msg to The Primary Techs
	//TODO Update MessagesSentTo Column in table
	const ResendToPrimarys = async () => {
		//Resends Job Request Txt to Primarys
		//Excluding those who have responded
		let messagesToSend = [];
		const kioskID = props.jobModal.KioskID;
		const jobID = props.jobModal.JobID;
		socket.emit(
			'getTechListToSendMsg',
			kioskID,
			'PrimaryTechs',
			async function (returnObject) {
				//Filter out Responses
				messagesToSend = RemoveResponsesFromNone(ResponsesList, returnObject);

				//Send Text To Everyone in messagesToSend
				for (let i = 0; i < messagesToSend.length; i++) {
					const techNumber = await getTechNumbers(messagesToSend[i].Name);
					if (!techNumber) continue; // Skip if no valid number

					const message = {
						body:
							'This is the 2nd request for Job# ' +
							jobID +
							'!\n' +
							'Kiosk: ' +
							kioskID +
							' ' +
							props.jobModal.ServerID +
							' needs a visit for a(n) ' +
							props.jobModal.Description +
							'\n' +
							'Please reply with your Job# and ETA. \n' +
							'Example: ' +
							jobID +
							' 8:30PM \n' +
							'Thank You!',
						to: techNumber,
						from: props.fullName,
						time: CurrentTime(),
						type: 'tech',
					};

					$.post('/api/send_message_async', { messages: message }, (response) => {
						if (!response) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: 'There was an issue sending the message to ' + messagesToSend[i].Name,
								severity: 'error',
							}));
						}
						else if (response.status == 200) {
							//if messagesToSend.Name is not in this.state.MessagesSentToList[].Name
							let MessagesSentToObject = MessagesSentToList;
							for (let k = 0; k < MessagesSentToObject.length; k++) {
								//If a repeat is found, Break out of For Loop
								if (MessagesSentToObject[k].Name == messagesToSend[i].Name) {
									break;
								}
	
								//If there are no Repeats, Combine Lists and Save to MessagesSentTo List in Job Table
								if (k == MessagesSentToObject.length - 1) {
									setMessagesSentToList(MessagesSentToObject.concat(messagesToSend[i]));
									socket.emit(
										'updateMessagesSentToJob',
										props.jobModal.JobID,
										JSON.stringify(MessagesSentToList),
										function (response) {
											if (!response) {
												setSnackbar((prevState) => ({
													...prevState,
													visible: true,
													message: 'There was an issue updating the message sent list.',
													severity: 'error',
												}));
											}
										}
									);
	
									break;
								}
							}
						}
					});
				}
				//Update messagesSentTo list with list from messagesToSend
			}.bind(this)
		);

		//Displays ResendOptionsBtn
		//Hides ResendToPrimarysBtn and SendToBackupsBtn
		document.getElementById('ResendOptionsBtn').style.display = 'inline';

		document.getElementById('ResendToPrimarysBtn').style.display = 'none';
		document.getElementById('SendToBackupsBtn').style.display = 'none';
		document.getElementById('ResendToPaperChangerBtn').style.display = 'none';
		document.getElementById('PrintLetterBtn').style.display = 'none';
		document.getElementById('EmailLetterBtn').style.display = 'none';
	};

	//Sends a Job Request Txt Msg to The Backup Techs
	//TODO Update MessagesSentTo Column in table
	const SendToBackups = async () => {
		//Sends Job Request Txt to Backups
		let messagesToSend = [];
		const kioskID = props.jobModal.KioskID;
		const jobID = props.jobModal.JobID;
		socket.emit(
			'getTechListToSendMsg',
			kioskID,
			'BackupTechs',
			async function (returnObject) {
				//Filter out Responses
				messagesToSend = RemoveResponsesFromNone(ResponsesList, returnObject);

				//Send Text To Everyone
				for (let i = 0; i < messagesToSend.length; i++) {
					const techNumber = await getTechNumbers(messagesToSend[i].Name);
					if (!techNumber) continue; // Skip if no valid number

					const message = {
						body:
							'Hi ' +
							messagesToSend[i].Name.split(' ')[0] +
							', \n' +
							'Kiosk: ' +
							kioskID +
							' ' +
							props.jobModal.ServerID +
							' needs a visit for a(n) ' +
							props.jobModal.Description +
							'.\n' +
							'Please reply with your Job# and ETA. \n' +
							'Example: ' +
							jobID +
							' 8:30PM \n' +
							'Your Job# is: ' +
							jobID +
							'.\n' +
							'Thank You!',
						to: techNumber,
						from: props.fullName,
						time: CurrentTime(),
						type: 'tech',
					};

					$.post('/api/send_message_async', { messages: message }, (response) => {
						if (!response) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: 'There was an issue sending the message to ' + messagesToSend[i].Name,
								severity: 'error',
							}));
						}
						else if (response.status == 200) {
							//if messagesToSend.Name is not in this.state.MessagesSentToList[].Name
							let MessagesSentToObject = MessagesSentToList;
							for (let k = 0; k < MessagesSentToObject.length; k++) {
								//If a repeat is found, Break out of For Loop
								if (MessagesSentToObject[k].Name == messagesToSend[i].Name) {
									break;
								}
	
								//If there are no Repeats, Combine Lists and Save to MessagesSentTo List in Job Table
								if (k == MessagesSentToObject.length - 1) {
									setMessagesSentToList(MessagesSentToObject.concat(messagesToSend[i]));
									socket.emit(
										'updateMessagesSentToJob',
										props.jobModal.JobID,
										JSON.stringify(MessagesSentToList.concat(messagesToSend[i])),
										function (response) {
											if (!response) {
												setSnackbar((prevState) => ({
													...prevState,
													visible: true,
													message: 'There was an issue updating the message sent list.',
													severity: 'error',
												}));
											}
										}
									);
	
									break;
								}
							}
						}
					});
				}

				//Update messagesSentTo list with list from messagesToSend
			}.bind(this)
		);

		//Displays ResendOptionsBtn
		//Hides ResendToPrimarysBtn and SendToBackupsBtn
		document.getElementById('ResendOptionsBtn').style.display = 'inline';
		document.getElementById('ResendToPrimarysBtn').style.display = 'none';
		document.getElementById('SendToBackupsBtn').style.display = 'none';
		document.getElementById('ResendToPaperChangerBtn').style.display = 'none';
		document.getElementById('PrintLetterBtn').style.display = 'none';
		document.getElementById('EmailLetterBtn').style.display = 'none';
	};

	function getTechNumbers(tech) {
		return new Promise((resolve, reject) => {
			try {
				socket.emit('getTechNumber', tech, (number) => {
					if (number) {
						resolve(number);
					} else {
						reject('Number not found');
					}
				});
			} catch (err) {
				reject(err);
			}
		});
	}

	const ResendToPaperChanger = async () => {
		//Resends Job Request Txt to Primarys
		//Excluding those who have responded
		let messagesToSend = [];
		const kioskID = props.jobModal.KioskID;
		const jobID = props.jobModal.JobID;

		const NoResponseBodyName = document.getElementsByClassName('NoResponseBodyName');

		for (let k = 0; k < NoResponseBodyName.length; k++) {
			messagesToSend.push({ Name: NoResponseBodyName[k].innerHTML });
		}

		//Send Text To Everyone in messagesToSend
		for (let i = 0; i < messagesToSend.length; i++) {
			const techNumber = await getTechNumbers(messagesToSend[i].Name);
			if (!techNumber) continue; // Skip if no valid number

			const message = {
				body:
					'This is the 2nd Request! \n.' +
					'Hi, ' +
					messagesToSend[i].Name +
					'.\n' +
					'Kiosks: ' +
					kioskID +
					'\n' +
					'JobID: ' +
					jobID +
					'\n' +
					'Between: ' +
					FormatBetweenDates(props.jobModal.EstimateTimeArrival) +
					'\n\n' +
					'Reply with JobID, then your response. \n' +
					'Example: ' +
					jobID +
					' Yes',
				to: techNumber,
				from: props.fullName,
				time: CurrentTime(),
				type: 'tech',
			};

			$.post('/api/send_message_async', { messages: message }, (response) => {
				if (!response) {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'There was an issue sending the message to ' + messagesToSend[i].Name,
						severity: 'error',
					}));
				}
			});
		}

		//Displays ResendOptionsBtn
		//Hides ResendToPrimarysBtn and SendToBackupsBtn
		document.getElementById('ResendOptionsBtn').style.display = 'inline';

		document.getElementById('ResendToPrimarysBtn').style.display = 'none';
		document.getElementById('SendToBackupsBtn').style.display = 'none';
		document.getElementById('ResendToPaperChangerBtn').style.display = 'none';
		document.getElementById('PrintLetterBtn').style.display = 'none';
		document.getElementById('EmailLetterBtn').style.display = 'none';
	};

	//Shows or Hides the Input ETA Options
	//User can Directly Assign Techs to a job with this option
	function ShowHideInputETA(e) {
		if (document.getElementById('ShowHideInputETA').checked) {
			document.getElementById('InputETAForTech').style.display = 'block';
			DeselectOtherAssignResponseToJobRadio(e);
		} else {
			document.getElementById('InputETAForTech').style.display = 'none';
		}

		document.getElementById('TableInputETAList').style.display = 'none';
		document.getElementById('TableDispatchDirectlyList').style.display = 'none';
	}

	const AssignTech = async (support) => {
		let responseSaveToDB = [];

		//If Input ETA is selected and all text feilds are filled appropriately
		//then save it to the responseSaveToDB variable
		if (
			document.getElementById('ShowHideInputETA').checked == true &&
			document.getElementById('InputETATechName').value != '' &&
			document.getElementById('InputETATechName').value == TechInputETAObject &&
			document.getElementById('InputETAText').value != ''
		) {
			responseSaveToDB = {
				ETA: document.getElementById('InputETAText').value,
				Name: TechInputETAObject,
				Origin: 'Dispatcher',
				Recieved: new Date()
					.toLocaleTimeString('en-GB', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
					})
					.replace(/,/g, '')
					.replace(/\//g, '-'),
				TechList: 'Dispatch',
			};
			//Add Response to Job Table along side existing responses
			if (ResponsesList) {
				setResponsesList(ResponsesList.concat(responseSaveToDB));
			} else {
				setResponsesList([responseSaveToDB]);
			}
		}

		//else check if one of the Responded Technicians are Selected
		//Then save to the responseSaveToDB Variable
		else {
			const AssignResponseToJobRadio = document.getElementsByClassName('AssignResponseToJobRadio');
			for (let i = 0; i < AssignResponseToJobRadio.length; i++) {
				if (AssignResponseToJobRadio[i].checked == true) {
					responseSaveToDB = JSON.parse(AssignResponseToJobRadio[i].id);
					break;
				}
			}
		}

		//If something was saved to the responseSaveToDB Variable
		//Then send "Job was assigned to You" Text to Selected Technician

		if (responseSaveToDB != [] && responseSaveToDB.length != 0) {
			let allow = true;
			let open = false;
			const exists = props.paperJobs ? props.paperJobs.find((job) => job.JobID[0] == 'P') : null;

			if (props.jobModal.JobID[0] != 'P') {
				if (exists) {
					// is a paper job open for this kiosk
					const open_jobs = JSON.parse(exists.Kiosks);
					open_jobs.forEach((job) => {
						if (job.KioskID == props.jobModal.KioskID) {
							if (job.KioskStatus == 'Open') {
								// if job is completed, set allow to true
								open = true;
							}
						}
					});

					if (open) {
						// paper job is still open for this kiosk
						if (responseSaveToDB.Name != exists.TempTechnician) {
							allow = false;
						}
					}
				}
			}

			if (
				allow ||
				confirm(
					`This job has a paper job open for ${exists.TempTechnician}\n\nAre you sure you want to assign ${responseSaveToDB.Name}?\n\nIf yes, click OK, otherwise click CANCEL`
				)
			) {
				document.getElementById('AssignBtn').disabled = true;
				document.getElementById('AssignWSupportBtn').disabled = true;
				const kioskID = props.jobModal.KioskID;
				const jobID = props.jobModal.JobID;

				//If InputETA is Checked, Save name to Responses List in Job Table as well

				//Add Tech Name, ETA, JobStatus = Assigned, Assigned Date = NOW to Job Table
				socket.emit(
					'updateAssignedJob',
					props.jobModal.JobID,
					JSON.stringify(responseSaveToDB),
					async function (response) {
						if (response) {
							//If its a manual input ETA, Save Response to database
							if (document.getElementById('ShowHideInputETA').checked == true) {
								socket.emit(
									'updateResponsesJob',
									props.jobModal.JobID,
									JSON.stringify(ResponsesList),
									function (response) {
										if (!response) {
											setSnackbar((prevState) => ({
												...prevState,
												visible: true,
												message: 'There was an issue updating the responses list.',
												severity: 'error',
											}));
										}
									}
								);
							}
							//Send Text To AssignedTech
							let vMessage;
							props.close_modal();

							//Sets the message to send for a paper job or a regular job.
							const techNumber = await getTechNumbers(responseSaveToDB.Name);
							if (!techNumber) return; // Skip if no valid number

							if (jobID.charAt(0) == 'P') {
								const support_msg = support
									? '**Please make sure to contact Tech Support when you arrive at the kiosk**'
									: '';
								vMessage = {
									body:
										'Hi ' +
										responseSaveToDB.Name.split(' ')[0] +
										', \n' +
										'You have been Assigned to the following Paper Change Job:\n' +
										'Kiosk: ' +
										kioskID +
										'\n' +
										'JobID: ' +
										jobID +
										'\n\n' +
										'You will receive intructions in the mail within the next 5 business days.\n\n' +
										support_msg,
									to: techNumber,
									from: props.fullName,
									time: CurrentTime(),
									type: 'tech',
								};
							} else {
								let changePaper = false;
								const results = props.kiosksInfo.filter((obj) => {
									return obj.KioskID === props.jobModal.KioskID;
								});

								if (results[0].PrintCountCOVR <= 100) {
									changePaper = true;
								} else if (results[0].PrintCountCOVR === undefined) {
									changePaper = false;
								} else {
									changePaper = false;
								}

								let paperMessage = '';

								if (changePaper === true) {
									paperMessage = 'Also, please change the Star paper when you go to the kiosk.\n\n';
								}

								const support_msg = support
									? '**Please make sure to contact Tech Support when you arrive at the kiosk**.\n\n'
									: '';

								vMessage = {
									body:
										'Hi ' +
										responseSaveToDB.Name.split(' ')[0] +
										', \n' +
										'You have been Assigned to Job# ' +
										jobID +
										' at ' +
										kioskID +
										' ' +
										props.jobModal.ServerID +
										'.\n' +
										'Issue: ' +
										props.jobModal.Description +
										'\n' +
										props.jobModal.StoreAddress +
										' ' +
										props.jobModal.StoreCity +
										', ' +
										props.jobModal.StoreState +
										'. ' +
										props.jobModal.StoreZip +
										'\n\n' +
										paperMessage +
										support_msg,
									to: techNumber,
									from: props.fullName,
									time: CurrentTime(),
									type: 'tech',
								};
							}

							const message = vMessage;
							$.post('/api/send_message_async', { messages: message }, async (response) => {
								if (response.status == 200) {
									const rejectMsg = await MessagesSentToList.reduce(async (messages, tech) => {
										messages = await messages; // Ensure messages is resolved
										if (!Array.isArray(messages)) {
											messages = []; // Convert to array if not already
										}
										const techNumber = await getTechNumbers(tech.Name);
										if (!techNumber) return messages; // Skip if no valid number

										if (tech.Name !== responseSaveToDB.Name) {
											// send rejection to techs not assigned to job
											messages.push({
												body:
													'Job ' + jobID + '  for kiosk ' + kioskID + ' has been assigned to another technician. \n',
												to: techNumber,
												from: 'Auto Msg',
												time: CurrentTime(),
												type: 'tech',
											});
										}

										return messages;
									}, []);

									if (rejectMsg.length) {
										$.post('/api/send_message_bulk', { messages: rejectMsg }, (res) => {
											if (res != 200) {
												console.error(
													'jobModal.api-send-message: There was an issue sending Job Rejection message to non assigned technicians.'
												);
											}
										});
									}

									//MOBILE MODE
									let mobileModeSend = JSON.parse(props.lists.MsgTo);

									if (support && props.support && mobileModeSend) {
										mobileModeSend = [...mobileModeSend, props.support];
									} else {
										mobileModeSend = [props.support];
									}

									if (mobileModeSend) {
										const msgs = mobileModeSend.reduce((messages, user) => {
											const support_msg = support
												? '**Technician has been asked to call Tech Support when arriving at the kiosk**'
												: '';
											messages.push({
												body:
													'Job ' +
													jobID +
													' Assigned to ' +
													responseSaveToDB.Name +
													'(' +
													responseSaveToDB.ETA +
													')\n' +
													'Kiosk: ' +
													kioskID +
													'\n' +
													'Issue: ' +
													props.jobModal.Description +
													'\n\n' +
													support_msg,
												to: user,
												from: 'Mobile Mode',
												time: CurrentTime(),
												type: 'standard',
											});
											return messages;
										}, []);

										$.post('/api/send_message_bulk', { messages: msgs }, (res) => {
											if (res == 'OK') {
												axios.post('/api/refreshJob');
											} else {
												console.error(
													'jobModal.api-send-message: There was an issue sending Job Assigned message to Tech Support.'
												);
											}
										});
									}
								}
							});
						}
					}.bind(this)
				);
			}
		}
	};

	const UnlockJob = () => {
		socket.emit('UnlockJob', props.jobModal.JobID, (response) => {
			if (!response) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'There was an issue unlocking this job.',
					severity: 'error',
				}));
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'Job has been unlocked',
					severity: 'success',
				}));
			}
		});
	};

	//Display tech name and ETA from Technicians Who have Responded
	//RadioButton allows user to select a tech to later assign them to the job
	function RenderResponses(passedObject, driveTimeArray, kioskID, paperKioskID) {
		//Get the ResponsesJSON
		//Display the ResponsesJSON in a:
		// select * from dispatch_console.drivetimes where TechName in() and KioskID = kioskID
		//"[{"ETA": "yes", "Name": "Chad Muellenberg", "Origin": "Dispatcher", "Recieved": "05-04-2021 15:10:36", "TechList": "Dispatch"}, {"ETA": "Parts arriving 4/6/2021", "Name": "Chad Muellenberg", "Origin": "Dispatcher", "Recieved": "05-04-2021 15:11:02", "TechList": "Dispatch"}]"

		let colorStyleString = '';
		let returnArray = [];
		let driveTime = 'Not Found';

		if (passedObject) {
			for (let i = 0; i < passedObject.length; i++) {
				if (passedObject[i].TechList == 'PrimaryTechs') {
					colorStyleString = { backgroundColor: '#baffba' };
				} //Green
				if (passedObject[i].TechList == 'BackupTechs') {
					colorStyleString = { backgroundColor: '#ffbaba' };
				} //Red
				if (passedObject[i].TechList == 'Dispatch') {
					colorStyleString = { backgroundColor: '#ffffba' };
				} //Yellow
				if (passedObject[i].TechList == 'PaperChanger') {
					colorStyleString = { backgroundColor: '#baffff' };
				} //blue

				// Normal Job
				if (paperKioskID == 0) {
					for (let y = 0; y < driveTimeArray.length; y++) {
						if (driveTimeArray[y].KioskID == kioskID && driveTimeArray[y].TechName == passedObject[i].Name) {
							driveTime = driveTimeArray[y].DriveTime;
						}
					}
				} else {
					for (let y = 0; y < driveTimeArray.length; y++) {
						if (driveTimeArray[y].KioskID == paperKioskID && driveTimeArray[y].TechName == passedObject[i].Name) {
							driveTime = driveTimeArray[y].DriveTime;
						}
					}
				}

				returnArray.push(
					<tr key={i} style={colorStyleString}>
						<td className='ResponseBodyRadio'>
							<input
								className='AssignResponseToJobRadio'
								id={JSON.stringify(passedObject[i])}
								type='radio'
								onClick={DeselectOtherAssignResponseToJobRadio}
							/>
						</td>
						<td className='ResponseBodyName'>{passedObject[i].Name}</td>
						<td className='ResponseBodyName'>{driveTime}</td>
						<td className='ResponseBodyETA'>{passedObject[i].ETA}</td>
					</tr>
				);
			}
		}

		return (
			<table>
				<tbody>{returnArray}</tbody>
			</table>
		);
	}

	const DeselectOtherAssignResponseToJobRadio = (e) => {
		const AssignResponseToJobRadio = document.getElementsByClassName('AssignResponseToJobRadio');
		for (let i = 0; i < AssignResponseToJobRadio.length; i++) {
			AssignResponseToJobRadio[i].checked = false;
		}
		if (e.target.className == 'AssignResponseToJobRadio') {
			document.getElementById(e.target.id).checked = true;
			document.getElementById('ShowHideInputETA').checked = false;
			ShowHideInputETA(e);
		}
	};

	//Saves all MessagesSentTo to a new Array if their name is not in the ResponsesList
	//This array is then displayed in the NoResponses list in the RenderNoResponses function
	function RemoveResponsesFromNone(passedResponses, passedNone) {
		let returnNone = [];
		if (passedNone) {
			if (passedResponses) {
				for (let j = 0; j < passedNone.length; j++) {
					for (let k = 0; k < passedResponses.length; k++) {
						if (passedResponses[k].Name == passedNone[j].Name) {
							break;
						}
						if (k == passedResponses.length - 1) {
							returnNone.push(passedNone[j]);
						}
					}
				}
			} else {
				return passedNone;
			}
		}
		return returnNone;
	}

	//Use the "Responses" JSON to Display Techs From the "MessageSentTo" Json Who have not Responded
	//Do not display names in "MessageSentTo" where names in "Responses" match
	function RenderNoResponses(passedObject, driveTimeArray, kioskID, paperKioskID) {
		//Get the ResponsesJSON
		//Get the MessageSentToJson
		let driveTime = 'Not Found';

		//Remove any names from MessageSentToJson that match with names from ResponsesJson and save it to NoResponsesJson

		let colorStyleString = '';
		let returnArray = [];

		if (passedObject) {
			for (let i = 0; i < passedObject.length; i++) {
				if (passedObject[i].TechList == 'PrimaryTechs') {
					colorStyleString = { backgroundColor: '#baffba' };
				}
				if (passedObject[i].TechList == 'BackupTechs') {
					colorStyleString = { backgroundColor: '#ffbaba' };
				}
				if (passedObject[i].TechList == 'Dispatch') {
					colorStyleString = { backgroundColor: '#ffffba' };
				}
				if (passedObject[i].TechList == 'PaperChanger') {
					colorStyleString = { backgroundColor: '#baffff' };
				} //blue

				// Normal Job
				if (paperKioskID == 0) {
					for (let y = 0; y < driveTimeArray.length; y++) {
						if (driveTimeArray[y].KioskID == kioskID && driveTimeArray[y].TechName == passedObject[i].Name) {
							driveTime = driveTimeArray[y].DriveTime;
						}
					}
				} else {
					for (let y = 0; y < driveTimeArray.length; y++) {
						if (driveTimeArray[y].KioskID == paperKioskID && driveTimeArray[y].TechName == passedObject[i].Name) {
							driveTime = driveTimeArray[y].DriveTime;
						}
					}
				}

				returnArray.push(
					<tr key={i} style={colorStyleString}>
						{/*<td className="NoResponseBodyCheckBox">{/*<input type="checkbox"/> }</td>*/}
						<td className='NoResponseBodyName'>{passedObject[i].Name}</td>
						<td className='ResponseBodyName'>{driveTime}</td>
						<td className='NoResponseBodyNumber'>{FormatPhoneNumber(passedObject[i].Number)}</td>
					</tr>
				);
			}
		}

		return (
			<table>
				<tbody>{returnArray}</tbody>
			</table>
		);
	}

	//Shows or Hides the Dispatch Directly Options
	//User will send a job request to a specific tech only. This does not assign the tech to the job yet.
	//They must still respond with an ETA
	function ShowHideDispatchDirectly() {
		if (document.getElementById('ShowHideDispatchDirectly').checked) {
			document.getElementById('DispatchDirectlyTech').style.display = 'block';
		} else {
			document.getElementById('DispatchDirectlyTech').style.display = 'none';
		}
		document.getElementById('TableInputETAList').style.display = 'none';
		document.getElementById('TableDispatchDirectlyList').style.display = 'none';
	}

	//Send a Job Request Txt to Whoevers name is filled in the Text Box
	//Adds their name to the MessagesSentToList with a TechList = "Direct"
	const DispatchTechDirectly = async () => {
		let messagesSentToSaveToDB = [];

		//if (DispatchDirectlyText & a name is filled out) and Dispatch directly is checked
		//Then Send Msg to Tech and save the TechsNames, Their mobileNumber, and TechLst = "Dispatch" to MessagesSentTo List in job Table
		if (
			document.getElementById('ShowHideDispatchDirectly').checked == true &&
			document.getElementById('DispatchDirectlyText').value != '' &&
			document.getElementById('DispatchDirectlyText').value == DispatchDirectlyObject
		) {
			messagesSentToSaveToDB = {
				Name: DispatchDirectlyObject,
				TechList: 'Dispatch',
			};

			document.getElementById('TableInputETAList').style.display = 'none';
			document.getElementById('TableDispatchDirectlyList').style.display = 'none';

			const kioskID = props.jobModal.KioskID;
			const jobID = props.jobModal.JobID;
			//Send Text To Direct Dispatch
			let vMessage;
			const techNumber = await getTechNumbers(messagesSentToSaveToDB.Name);
			if (!techNumber) return; // Skip if no valid number

			if (jobID.charAt(0) == 'P') {
				vMessage = {
					body:
						'Hi ' +
						messagesSentToSaveToDB.Name +
						',\n' +
						'Kiosks: ' +
						kioskID +
						'\n' +
						'JobID: ' +
						jobID +
						'\n' +
						'Between: ' +
						FormatBetweenDates(props.jobModal.EstimateTimeArrival) +
						'\n\n' +
						'Reply with JobID, then your response. \n' +
						'Example: ' +
						jobID +
						' Yes',
					to: techNumber,
					from: props.fullName,
					time: CurrentTime(),
					type: 'tech',
				};
			} else {
				vMessage = {
					body:
						'Hi ' +
						messagesSentToSaveToDB.Name.split(' ')[0] +
						',\n' +
						'Kiosk: ' +
						kioskID +
						' ' +
						props.jobModal.ServerID +
						' needs a visit for a(n) ' +
						props.jobModal.Description +
						'\n' +
						'Please reply with your Job# and ETA. \n' +
						'Example: ' +
						jobID +
						' 8:30PM \n' +
						'Your Job# is: ' +
						jobID +
						'.\n' +
						'Thank You!',
					to: techNumber,
					from: props.fullName,
					time: CurrentTime(),
					type: 'tech',
				};
			}

			const message = vMessage;
			//if messagesSentToSaveToDB.Name is not in this.state.MessagesSentToList[].Name
			let MessagesSentToObject = MessagesSentToList;

			//Allows to Directly Dispatch if there are no techs in the list
			if (MessagesSentToObject.length == 0) {
				MessagesSentToObject.push(messagesSentToSaveToDB);
				setMessagesSentToList(MessagesSentToObject);
				socket.emit(
					'updateMessagesSentToJob',
					props.jobModal.JobID,
					JSON.stringify(MessagesSentToList),
					function (response) {
						if (!response) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: 'There was an issue updating the message sent list.',
								severity: 'error',
							}));
						}
					}
				);
			}

			for (let k = 0; k < MessagesSentToObject.length; k++) {
				//If a repeat is found, Break out of For Loop
				if (MessagesSentToObject[k].Name == messagesSentToSaveToDB.Name) {
					$.post('/api/send_message_async', { messages: message }, (response) => {
						if (!response) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: 'There was an issue sending the message to ' + messagesSentToSaveToDB.Name,
								severity: 'error',
							}));
						}
					});
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'Message has been sent',
						severity: 'success',
					}));

					break;
				}

				//If there are no Repeats, Combine Lists and Save to MessagesSentTo List in Job Table
				if (k == MessagesSentToObject.length - 1) {
					MessagesSentToObject.push(messagesSentToSaveToDB);
					setMessagesSentToList(MessagesSentToObject);
					socket.emit(
						'updateMessagesSentToJob',
						props.jobModal.JobID,
						JSON.stringify(MessagesSentToList),
						function (response) {
							if (!response) {
								setSnackbar((prevState) => ({
									...prevState,
									visible: true,
									message: 'There was an issue updating the message sent list.',
									severity: 'error',
								}));
							}
						}
					);

					$.post('/api/send_message_async', { messages: message }, (response) => {
						if (!response) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: 'There was an issue sending the message to ' + messagesSentToSaveToDB.Name,
								severity: 'error',
							}));
						}
					});

					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'Message list was updated',
						severity: 'success',
					}));
				}
			}
			alert('DispatchDirectly');
		}
	};

	//Temporary Feature
	//Opens an Alert Window that tells the User to Type "Delete" then confirm in order to delete a job
	const DeleteJob = async () => {
		const deleteJobConfirm = prompt(
			"Please enter 'DELETE' to confirm deletion of this Job. If you do not want to notify the technician of this deletion, enter 'DELETENOMSG'.",
			''
		);
		if (deleteJobConfirm) {
			if (
				deleteJobConfirm.toLowerCase() == 'delete' ||
				deleteJobConfirm.toLowerCase() == 'y' ||
				deleteJobConfirm.toLowerCase() == 'deletenomsg' ||
				deleteJobConfirm.toLowerCase() == 'yn'
			) {
				if (
					props.jobModal.Technician &&
					deleteJobConfirm.toLowerCase() != 'deletenomsg' &&
					deleteJobConfirm.toLowerCase() != 'yn'
				) {
					//Send Text To Direct Dispatch
					const message = {
						body:
							'Hi ' +
							props.jobModal.Technician.split(' ')[0] +
							',\n' +
							'Job# ' +
							props.jobModal.JobID +
							' has been deleted.\n' +
							'Kiosk: ' +
							props.jobModal.KioskID +
							'\n' +
							props.jobModal.ServerID +
							'\n' +
							'Issue: ' +
							props.jobModal.Description +
							'\n' +
							'Please DO NOT go to the Kiosk.',
						to: await getTechNumbers(props.jobModal.Technician),
						from: props.fullName,
						time: CurrentTime(),
						type: 'tech',
					};
					$.post('/api/send_message_async', { messages: message }, (response) => {
						if (!response) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: 'There was an issue sending the message to ' + props.jobModal.Technician,
								severity: 'error',
							}));
						}
					});
				}

				socket.emit(
					'updateJobToDeleted',
					props.jobModal.JobID,
					function (boolean) {
						if (boolean) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: 'Job Deleted',
								severity: 'success',
							}));
							props.close_modal();
							if (!props.paperJobs) {
								// only call refresh if job is from the paper tab
								props.refresh_data();
							}
							axios.post('/api/refreshJob');
							axios.post('/api/refreshAlert');
						}
					}.bind(this)
				);
			}
		}
	};

	const CompleteJob = () => {
		const kioskID = props.PaperKioskIDKey == 0 ? props.jobModal.KioskID : props.PaperKioskIDKey;
		const jobID = props.jobModal.JobID;
		socket.emit(
			'updateJobToCompleted',
			jobID,
			kioskID,
			function (boolean) {
				if (boolean) {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'Job Completed',
						severity: 'success',
					}));
					props.close_modal();
					axios.post('/api/refreshJob');
					axios.post('/api/refreshAlert');
				}
			}.bind(this)
		);
	};

	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	//Renders the Job Modal Which will Allow Users to
	//Assign Techs to a Job,
	//Resend a Request Text Message to all Primary or Backup Techs,
	//Directly Dispatch to an Open Job which sends a request text message,
	//Input a Techs ETA and assign them to a job,
	//Or Delete the Job
	return props.jobKey ? (
		<div className='JobMaintenanceContainer' id='JobMaintenanceContainer'>
			<AlertBar
				visible={snackbar.visible}
				message={snackbar.message}
				severity={snackbar.severity}
				onClose={handleCloseSnack}
			/>
			<div className='JobModalID' id='JobModalID'>
				<h1 className='JobModalKioskID' id='JobModalKioskID'>
					Kiosk {props.PaperKioskIDKey == 0 ? props.jobModal.KioskID : props.PaperKioskIDKey}
				</h1>
				<h1 className='JobModalJobID' id='JobModalJobID'>
					Job {props.jobModal.JobID}
				</h1>
			</div>

			<h3 className='JobDesctription'>{props.jobModal.Description}</h3>
			<p className='JobDispatched'>
				{'Created ' + FormatDateTimeWithoutYear(props.jobModal.CreatedDate) + ' - ' + props.jobModal.Dispatcher}
			</p>
			<p className='JobStatus'>{FormatStatus(props.jobModal.JobStatus)}</p>

			<div>{seeIfPaperJobExists(props.paperJobs)}</div>

			<div className='DispatchResponseContainer'>
				<h2 className='DispatchResponseTitle'>Responses Recieved</h2>

				<div className='ResponseTableContainer'>
					<div className='ResponseHead'>
						<table>
							<thead>
								<tr>
									<td className='ResponseHeadRadio'></td>
									<td className='ResponseHeadName'>Technician Name</td>
									<td className='ResponseHeadName'>Drive Time(One Way)</td>
									<td className='ResponseHeadETA'>ETA</td>
								</tr>
							</thead>
						</table>
					</div>

					<div className='ResponseBody'>
						{RenderResponses(ResponsesList, props.drive_times, props.jobModal.KioskID, props.PaperKioskIDKey)}
					</div>
				</div>

				<div className='InputETA'>
					<h4 className='InputETACheckBox'>
						<input className='ShowHideInputETA' id='ShowHideInputETA' type='checkbox' onClick={ShowHideInputETA} />
						Manually Input ETA (For Primaries and Backups)
					</h4>
					<div className='InputETAForTech' id='InputETAForTech'>
						<input
							className='InputETATechName'
							id='InputETATechName'
							type='text'
							placeholder='Type Technician Name...'
							value={TechInputETA}
							onClick={() => {
								$('#TableInputETAList').toggle();
								$('#TableDispatchDirectlyList').toggle();
							}}
							onChange={(e) => onChange('TechObjectInputETA', e)}
						/>
						{RenderList(TechInputETA, props.technicianList, 'InputETATechObjectList', 'TableInputETAList')}
						<input className='InputETAText' id='InputETAText' type='text' placeholder='ETA' />
					</div>
				</div>
				<button className='AssignBtn' id='AssignBtn' onClick={() => AssignTech(false)}>
					Assign
				</button>
				<button className='AssignBtn' id='AssignWSupportBtn' onClick={() => AssignTech(true)}>
					Assign w/ Support
				</button>
				<button className='UnlockBtn' id='UnlockBtn' onClick={UnlockJob}>
					Unlock Job
				</button>
				<button className='OverrideBtn' id='OverrideBtn' onClick={OverrideServiceReport}>
					Override SR
				</button>
			</div>
			<div className='DispatchNoResponseContainer'>
				<h2 className='NoResponseTitle'>Awaiting Responses</h2>

				<div className='NoResponseTableContainer'>
					<div className='NoResponseHead'>
						<table>
							<thead>
								<tr>
									{/*<td className="NoResponseHeadCheckBox"></td>*/}
									<td className='NoResponseHeadName'>Technician Name</td>
									<td className='ResponseHeadName'>Drive Time(One Way)</td>
									<td className='NoResponseHeadNumber'>Number</td>
								</tr>
							</thead>
						</table>
					</div>

					<div className='NoResponseBody'>
						{RenderNoResponses(
							RemoveResponsesFromNone(ResponsesList, MessagesSentToList),
							props.drive_times,
							props.jobModal.KioskID,
							props.PaperKioskIDKey
						)}
					</div>
				</div>

				<div className='DispatchDirectly'>
					<h4 className='DispatchDirectlyCheckBox'>
						<input
							className='ShowHideDispatchDirectly'
							id='ShowHideDispatchDirectly'
							type='checkbox'
							onClick={ShowHideDispatchDirectly}
						/>
						Dispatch Directly to a Different Technician
					</h4>
					<div className='DispatchDirectlyTech' id='DispatchDirectlyTech'>
						<input
							className='DispatchDirectlyText'
							id='DispatchDirectlyText'
							type='text'
							placeholder='Type Technician Name...'
							value={DispatchDirectly}
							onClick={() => {
								$('#TableInputETAList').toggle();
								$('#TableDispatchDirectlyList').toggle();
							}}
							onChange={(e) => onChange('TechObjectDispatchDirectly', e)}
						/>
						{RenderList(
							DispatchDirectly,
							props.technicianList,
							'DispatchDirectlyTechObjectList',
							'TableDispatchDirectlyList'
						)}
						<button className='DispatchDirectlyBtn' onClick={DispatchTechDirectly}>
							Dispatch Directly
						</button>
					</div>
				</div>
				<h5 className='NoResponseBtns'>
					<button className='ResendOptionsBtn' id='ResendOptionsBtn' onClick={ResendOptions}>
						Resend Options
					</button>
					<button className='ResendToPrimarysBtn' id='ResendToPrimarysBtn' onClick={ResendToPrimarys}>
						Resend To Primaries
					</button>
					<button className='SendToBackupsBtn' id='SendToBackupsBtn' onClick={SendToBackups}>
						Send To Backups
					</button>
					<button className='ResendToPaperChangerBtn' id='ResendToPaperChangerBtn' onClick={ResendToPaperChanger}>
						Resend To Paper Changer
					</button>
					<button className='PrintLetterBtn' id='PrintLetterBtn' onClick={() => props.print_letter()}>
						Print Letter
					</button>
					<button className='EmailLetterBtn' id='EmailLetterBtn' onClick={() => props.email_letter()}>
						Email Letter
					</button>
				</h5>
			</div>
			<div>
				<button className='RTIButton' id='CloseJobBtn' onClick={() => props.close_modal()}>
					Close
				</button>
				<button className='RTIButton' id='CompleteJobBtn' onClick={CompleteJob} style={{ float: 'right' }}>
					Complete Job
				</button>
				<button className='RTIButton' id='DeleteJobBtn' onClick={DeleteJob}>
					Delete Job
				</button>
			</div>
		</div>
	) : (
		<div>LOADING...</div>
	);
};

JobModal.propTypes = {
	jobKey: PropTypes.any,
	jobModal: PropTypes.object.isRequired,
	paperJobs: PropTypes.array,
	PaperKioskIDKey: PropTypes.number,
	drive_times: PropTypes.array,
	technicianList: PropTypes.array,
	fullName: PropTypes.string,
	close_modal: PropTypes.func,
	print_letter: PropTypes.func,
	email_letter: PropTypes.func,
	refresh_data: PropTypes.func,
	kiosksInfo: PropTypes.array,
	lists: PropTypes.object,
	support: PropTypes.string,
};

export default JobModal;
