import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal.js';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import AlertBar from '../../components/AlertBar.js'; // Import AlertBar component

import { useCustomContext } from '../../hoc/Context.js'; // Import context
import PropTypes from 'prop-types';

const RequestModal = ({
	requestModal,
	pto,
	handleSaveRequest,
	onCloseRequest,
	isApproval,
}) => {
	const { userState } = useCustomContext(); // Get user state from context
	const [formData, setFormData] = useState({
		name: '',
		start: '',
		end: '',
		notes: '',
		type: 'time_off', // 'time_off' or 'event'
		status: 'Pending', // 'Pending', 'Approved', 'Rejected'
		approvalNotes: '', // Add approvalNotes field
		timeoffID: '', // Add timeoffID field
		email: '', // Add email field
	});
	const [snackbar, setSnackbar] = useState({ visible: false, message: '', severity: 'success' }); // Add snackbar state

	useEffect(() => {
		if (requestModal.data) {
			setFormData({
				name: requestModal.data.FullName || '',
				start:
					requestModal.data.start && requestModal.data.start !== 'Invalid Date'
						? dayjs(requestModal.data.start).format('YYYY-MM-DD HH:mm')
						: '',
				end:
					requestModal.data.end && requestModal.data.end !== 'Invalid Date'
						? dayjs(requestModal.data.end).format('YYYY-MM-DD HH:mm')
						: '',
				notes: requestModal.data.Notes || '',
				type: requestModal.data.type || 'time_off',
				status: requestModal.data.status || 'Pending',
				approvalNotes: requestModal.data.approvalNotes || '', // Add approvalNotes field
				timeoffID: requestModal.data.timeoffID || '', // Add timeoffID field
				email: requestModal.data.Email || '', // Add email field
			});
		}
	}, [requestModal.data]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleDateTimeChange = (name, value) => {
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleOpenSnack = ({ message = '', severity = 'success' }) => {
		setSnackbar({ visible: true, message, severity });
	};

	const handleSubmit = () => {
		handleSaveRequest(formData);
		handleOpenSnack({ message: 'Request submitted successfully!', severity: 'success' }); // Show success snackbar
	};

	const handleApproval = (status) => {
		const updatedFormData = { ...formData, status, approvalNotes: formData.approvalNotes || '' };
		handleSaveRequest(updatedFormData);
		handleOpenSnack({ message: `Request ${status.toLowerCase()} successfully!`, severity: 'success' }); // Show success snackbar
	};

	return (
		<>
			<Modal
				modal_id='request-modal'
				modal_display={requestModal.visible}
				dimension={{ width: 1000 }}
				modal_header={isApproval ? 'Approve/Reject Request' : 'Time Off Request'}
				onClose={onCloseRequest}
			>
				<div className='request-modal'>
					<div className='flex-just-between'>
						<div>
							<h4>Employee</h4>
							<TextField fullWidth name='name' value={formData.name} onChange={handleChange} />
						</div>
					</div>
					<div className='flex-just-between' style={{ marginBottom: '16px' }}>
						<div>
							<h4>Start Date</h4>
							<TextField
								fullWidth
								type='datetime-local'
								name='start'
								value={formData.start}
								onChange={(e) => handleDateTimeChange('start', e.target.value)}
							/>
						</div>
						<div>
							<h4>Return Date</h4>
							<TextField
								fullWidth
								type='datetime-local'
								name='end'
								value={formData.end}
								onChange={(e) => handleDateTimeChange('end', e.target.value)}
							/>
						</div>
					</div>
					<div className='flex-just-between'>
						<div>
							<h4>Available Time Off</h4>
							<p>{pto} Hours</p>
						</div>
					</div>
					<div>
						<h4>Reason</h4>
						<TextField
							variant='outlined'
							fullWidth
							multiline
							rows={4}
							name='notes'
							value={formData.notes}
							onChange={handleChange}
						/>
					</div>
					<Divider />
					{isApproval && userState.user.permissions === 'master' ? ( // Check if user has master role
						<div className='vert-margin flex-column'>
							<TextField
								variant='outlined'
								fullWidth
								multiline
								rows={4}
								name='approvalNotes'
								label='Approval/Rejection Notes'
								value={formData.approvalNotes || ''}
								onChange={handleChange}
								style={{ marginBottom: '16px' }}
							/>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<LoadingButton
									color='primary'
									variant='contained'
									onClick={() => handleApproval('Approved')}
									style={{ marginRight: '8px' }}
								>
									Approve
								</LoadingButton>
								<LoadingButton color='error' variant='contained' onClick={() => handleApproval('Rejected')}>
									Reject
								</LoadingButton>
							</div>
						</div>
					) : (
						<div className='vert-margin'>
							<LoadingButton color='primary' variant='contained' onClick={handleSubmit}>
								Submit
							</LoadingButton>
						</div>
					)}
				</div>
			</Modal>
			<AlertBar
				message={snackbar.message}
				visible={snackbar.visible}
				severity={snackbar.severity}
				onClose={() => setSnackbar({ ...snackbar, visible: false })}
			/>
		</>
	);
};

RequestModal.propTypes = {
	requestModal: PropTypes.object.isRequired,
	pto: PropTypes.number.isRequired,
	handleSaveRequest: PropTypes.func.isRequired,
	onCloseRequest: PropTypes.func.isRequired,
	isApproval: PropTypes.bool.isRequired,
};

export default RequestModal;
