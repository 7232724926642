// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-block {
    width: 100%;
}

.log-inner-container {
    padding: 18px;
    display: flex;
}

.log-inner-container select, .log-inner-container input{
    width: 180px;
    height: 26px;
    border: none;
    border-bottom: 1px solid lightgray;
    outline-color: lightgray;
   
}

.multi-select.active-select {
    background-color: dodgerblue;
}

.chip-select {
    background-color: dodgerblue !important;
}

.log-inner-container textarea{
    width: 100%;
    height: 100px;
    border: 1px solid lightgray;
    border-radius: 4px;
    outline-color: lightgray;
    margin: 10px 0px;
}

.log-inner-container .form-block .required_field{
    font-size: 14px;
}

.log-inner-container .form-block label{
    font-size: 11px;
}

.log-inner-container .form-block select, 
.log-inner-container .form-block input, 
.log-inner-container .form-block textarea {
    font-size: 14px;
    font-weight: bold;
}

@media screen and (max-width: 430px){
    .log-inner-container select, .log-inner-container input {
        width: 248px;
    }

    .log-inner-container {
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
    }
}

.log-grid-columns{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
}

.grid-exempt{
    grid-column: 1/-1;
}`, "",{"version":3,"sources":["webpack://./src/StyleSheets/Forms.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kCAAkC;IAClC,wBAAwB;;AAE5B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,WAAW;IACX,aAAa;IACb,2BAA2B;IAC3B,kBAAkB;IAClB,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;;;IAGI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,eAAe;QACf,uBAAuB;QACvB,kBAAkB;IACtB;AACJ;;AAEA;IACI,aAAa;IACb,gCAAgC;IAChC,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".form-block {\n    width: 100%;\n}\n\n.log-inner-container {\n    padding: 18px;\n    display: flex;\n}\n\n.log-inner-container select, .log-inner-container input{\n    width: 180px;\n    height: 26px;\n    border: none;\n    border-bottom: 1px solid lightgray;\n    outline-color: lightgray;\n   \n}\n\n.multi-select.active-select {\n    background-color: dodgerblue;\n}\n\n.chip-select {\n    background-color: dodgerblue !important;\n}\n\n.log-inner-container textarea{\n    width: 100%;\n    height: 100px;\n    border: 1px solid lightgray;\n    border-radius: 4px;\n    outline-color: lightgray;\n    margin: 10px 0px;\n}\n\n.log-inner-container .form-block .required_field{\n    font-size: 14px;\n}\n\n.log-inner-container .form-block label{\n    font-size: 11px;\n}\n\n.log-inner-container .form-block select, \n.log-inner-container .form-block input, \n.log-inner-container .form-block textarea {\n    font-size: 14px;\n    font-weight: bold;\n}\n\n@media screen and (max-width: 430px){\n    .log-inner-container select, .log-inner-container input {\n        width: 248px;\n    }\n\n    .log-inner-container {\n        flex-wrap: wrap;\n        justify-content: center;\n        width: fit-content;\n    }\n}\n\n.log-grid-columns{\n    display: grid;\n    grid-template-columns: auto auto;\n    grid-template-rows: auto auto;\n}\n\n.grid-exempt{\n    grid-column: 1/-1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
