import React from 'react';
import PropTypes from 'prop-types';
import '../StyleSheets/Button.css';

/**
 * Renders a Button component
 * @function Button
 * @param props
 * @param {string} props.name - the name of the button
 * @param {string} props.color - the style of the button to render
 * @param {string} [props.children] - used only if the button is to render html icon codes
 * @param {string} [props.size] - defines the size of the button [small | very-small | circle | square] (defaults to normal button size if not used)
 * @param {string} [props.class] - optional class to append to button style
 * @param {function} [props.click] - reference to the function in the parent component that handles the button click action
 * @param {string} [props.tip] - displays tooltip for the button
 * @param {*} [props.style] - optional style to apply to the button
 * @param {bool} [props.disabled] - defines whether the button is clickable
 * @param {string | number} [props.id] - button identifier
 * @returns - {React.ReactElement} Button component
 */
const Button = (props) => {
	return (
		<div id='button'>
			<button
				className={`ButtonUI ${props.color} ${props.size} ${props.class}`}
				onClick={props.onClick}
				title={props.tip}
				style={props.style}
				disabled={props.disabled}
				id={props.id}
			>
				{props.name ? props.name : props.children}
			</button>
		</div>
	);
};

Button.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
	class: PropTypes.string,
	onClick: PropTypes.func,
	tip: PropTypes.string,
	style: PropTypes.object,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	name: PropTypes.string,
	children: PropTypes.node,
};

export default Button;
