import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import Modal from '../../components/Modal';
import FileUpload from '../../components/FileUpload';
import AlertBar from '../../components/AlertBar';
import Papa from 'papaparse';
import { MdMoreVert } from 'react-icons/md'; // Replaced with react-icons
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import { DateToLocale } from '../API/TextFormatingFunctions';
import { getEmailSignature } from '../../utilities/util';
import { useCustomContext } from '../../hoc/Context.js';
import $ from 'jquery';

const TableHeaders = [
  { id: 'created', name: 'created', sortable: true, numeric: false },
  { id: 'po #', name: 'po #' },
  { id: 'items', name: 'items', sortable: true, numeric: false },
  { id: 'ordered', name: 'ordered', sortable: true, numeric: true },
  { id: 'received', name: 'received', sortable: true, numeric: true },
  { id: 'vendor', name: 'vendor', sortable: true, numeric: false },
  { id: 'status', name: 'status', sortable: true, numeric: false },
  { id: '_blank', name: '' },
];

const SearchOptions = [
  { id: 'po', name: 'Purchase Order', pidx: 1 },
  { id: 'vendor', name: 'Vendor', pidx: 5 },
  { id: 'status', name: 'Status', pidx: 6 },
];

const FILE_HEADERS = [
  'Purchase Order No',
  'Vendor',
  'Date',
  'Product',
  'Product Description',
  'Product Quantity',
  'Product Rate',
  'Product Amount',
  'Manufacture Part No',
  'Unit Of Measure',
];

const MenuOptions = ['Delete'];

const TEST_URL = 'http://localhost:3000';
const PROD_URL = 'https://console.ez-tab.net';

const PurchaseOrders = () => {
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState('');
  const [searchParam, setSearchParam] = useState('');
  const [fileState, setFileState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: '',
    severity: 'success',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorIndex, setAnchorIndex] = useState(null);
  const { userState,  } = useCustomContext();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      retrieveData(true);
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    $('#purchase-order').toggleClass('flex-box');
  }, [modalOpen]);

  const retrieveData = (load) => {
    if (load) setLoading(true);

    // socket.emit('getPurchaseOrders',(res) => {
    //     if(!res){
    //         console.error(`PurchaseOrders.getPurchaseOrders: There was an issue calling this method`);
    //         setLoading(false);
    //     }else{
    //         setOrders(res);
    //         setLoading(false);
    //     }
    // });
  };

  const handleOpenOptions = (event) => {
    const [pref, idx] = event.target.id.split('-');
    setAnchorIndex(idx);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorIndex(null);
    setAnchorEl(null);
  };

  const handleOptionSelect = (event) => {
    const [order, opt, idx] = event.target.id.split('-');

    switch (opt) {
      case 'Delete':
        removePurchaseOrder(order);
        break;
    }
  };

  const removePurchaseOrder = (_order_id) => {
    if (confirm(`Are you sure you want to remove this purchase order?`)) {
      socket.emit('updatePurchaseOrder', { id: _order_id }, 'remove', (_orders) => {
        if (!_orders[0]) {
          setSnackbar((prevState) => ({
            ...prevState,
            visible: true,
            message: `SERVER ERROR: There was an issue calling this method.  Please contact the developer for this issue.`,
            severity: 'error',
          }));
          console.error(`PurchaseOrders.updatePurchaseOrder: There was an issue calling this method`);
        } else {
          setOrders(_orders[0]);
          handleCloseOptions();
          setSnackbar((prevState) => ({
            ...prevState,
            visible: true,
            message: `Success!  Purchase order removed`,
            severity: 'success',
          }));
        }
      });
    } else {
      handleCloseOptions();
    }
  };

  const renderTablebody = () => {
    return orders
      .map((order, index) => ({
        key: order.id,
        cells: [
          { data: DateToLocale(new Date(order.created)) },
          { data: order.po_num },
          { data: order.item_name },
          { data: order.qty },
          { data: 0 },
          { data: order.vendor },
          { data: order.status },
          {
            data: (
              <div>
                <IconButton id={`option-${index}`} aria-haspopup="true" onClick={handleOpenOptions} size="large">
                  <MdMoreVert id={`option-${index}`} /> {/* Updated to use react-icons */}
                </IconButton>
                {index == anchorIndex ? (
                  <Menu
                    id={`selected-menu-op-${index}`}
                    anchorEl={index == anchorIndex ? anchorEl : null}
                    open={index == anchorIndex ? Boolean(anchorEl) : false}
                    onClose={handleCloseOptions}
                  >
                    {MenuOptions.map((opt, idx) => (
                      <MenuItem id={`${order.id}-${opt}-${idx}`} key={opt} onClick={handleOptionSelect}>
                        {opt}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : null}
              </div>
            ),
          },
        ],
      }))
      .filter((row) =>
        searchVal && searchParam
          ? row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
          : row
      );
  };

  const renderTableButtons = () => {
    return (
      <div>
        <Button className="rti-blue-round" variant="contained" onClick={onToggleModal}>
          Import PO
        </Button>
      </div>
    );
  };

  const rowClick = () => {};

  const Search = (param, searchVal) => {
    setSearchVal(searchVal);
    setSearchParam(param);
  };

  const ClearSearch = () => {
    setSearchVal('');
  };

  const onFileUpload = (_state) => {
    setFileState(_state);
  };

  const onToggleModal = () => {
    setModalOpen((prevState) => !prevState);
  };

  const validateFile = (fields = []) => {
    return fields
      .filter((field) => field && field.trim())
      .reduce((valid, header, index) => {
        return header.trim() === FILE_HEADERS[index] ? valid : false;
      }, true);
  };

  const formatFile = (_fileData) => {
    return _fileData.map((el) => {
      return Object.entries(el)
        .map(([key, value]) => ({ [key.trim()]: value }))
        .reduce((headers, el) => {
          return { ...headers, ...el };
        }, {});
    });
  };

  const onPOImport = () => {
    const file = document.getElementById('file-upload').files[0];

    if (file) {
      setUploading(true);
      Papa.parse(file, {
        header: true,
        complete: async (results) => {
          results.data.pop();
          const valid = validateFile(results.meta.fields);
          if (valid) {
            const formattedFile = formatFile(results.data);
            const data = formattedFile.map((entry, idx) => {
              const parsedModel = entry[FILE_HEADERS[4]].split(';');

              return {
                idx,
                date_created: entry[FILE_HEADERS[2]],
                part_num: entry[FILE_HEADERS[8]],
                qb_id: entry[FILE_HEADERS[3]],
                price: entry[FILE_HEADERS[6]],
                description: entry[FILE_HEADERS[4]],
                qty: entry[FILE_HEADERS[5]],
                po_num: entry[FILE_HEADERS[0]],
                units: entry[FILE_HEADERS[9]],
                vendor: entry[FILE_HEADERS[1]],
                model: parsedModel.length ? parsedModel[0].trim() : '',
                mapping: entry[FILE_HEADERS[3]].replace(/[\t\n\r\f\v]/g, ''),
              };
            });

            socket.emit('updatePurchaseOrder', data, 'insert', async ([_newItems, _warehouse, _orders]) => {
              if (!_orders) {
                setSnackbar((prevState) => ({
                  ...prevState,
                  visible: true,
                  message: `SERVER ERROR: There was an issue calling this method.  Please contact the developer for this issue.`,
                  severity: 'error',
                }));
                console.error(`PurchaseOrders.updatePurchaseOrder: There was an issue calling this method`);
              } else {
                const newItems = JSON.parse(_newItems[0]['@_newItems']);
                const emails = _warehouse.map((user) => user.email);

                const msg = `
                  <div>
                    <h1 style="text-align:center;">Purchase Order Created</h1>
                    <p>Hi,</p>
                    <p>A purchase order has been created in inventory.  This means that there are items to be received or will be received in the next coming days.  You can click on the link below to see more information regarding this order.</p>
                    <a href="${
                      userState.user.environment == 'production' ? PROD_URL : TEST_URL
                    }/inventory/purchase-order">See Purchase Orders</a>
                    ${getEmailSignature('Auto Message')}
                  </div>
                `;

                const mailOpts = {
                  to: emails,
                  bcc: 'self',
                  subject: `Purchase Order Created`,
                  msg: msg,
                };

                const smsOpts = _warehouse.map((user) => ({
                  to: user.phone,
                  type: 'standard',
                  body: `Hi,\nA purchase order has been created in inventory. This means that there are items to be received or will be received in the next coming days. You can click on the link below to see more information regarding this order.\n\n${
                    userState.user.environment == 'production' ? PROD_URL : TEST_URL
                  }/inventory/purchase-order\n\n`,
                  from: 'Auto Msg',
                }));

                try {
                  await sendNotificationEmail(mailOpts);
                  await sendNotificationSMS(smsOpts);
                } catch (error) {
                  console.error(error);
                  setSnackbar((prevState) => ({
                    ...prevState,
                    visible: true,
                    message: `SERVER ERROR: There was an issue sending notifications to the warehouse users.  Please notify the developer of this issue.`,
                    severity: 'error',
                  }));
                }

                setOrders(_orders);
                setFileState(false);
                setSnackbar((prevState) => ({
                  ...prevState,
                  visible: true,
                  message: `Success!  Purchase order entered`,
                  severity: 'success',
                }));
                setUploading(false);
                onToggleModal();
              }
            });
          } else {
            setUploading(false);
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `Oops!  It looks like one or more headers in the file that you are trying to upload does not match our criteria.  Please try again.`,
              severity: 'warning',
            }));
          }
        },
      });
    }
  };

  const sendNotificationEmail = async (_mailOpts) => {
    return new Promise((resolve, reject) => {
      $.post('/api/send_email_async', { email: _mailOpts }, (res) => {
        if (res.status && res.status == 'success') {
          resolve({ status: 'success' });
        } else {
          reject({ msg: res.msg });
        }
      });
    });
  };

  const sendNotificationSMS = async (_smsOpts) => {
    return new Promise((resolve, reject) => {
      $.post('/api/send_message_bulk', { messages: _smsOpts }, (res) => {
        if (res == 'OK') {
          resolve({ status: 'success' });
        } else {
          reject({ msg: res });
        }
      });
    });
  };

  const handleCloseSnack = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  return (
    <div className="ViewedContentContainer" id="OpenContainer">
      <div style={{ height: '100%' }}>
        <div className="TitleBarContainer">
          <h1>Purchase Orders</h1>
        </div>
        {modalOpen ? (
          <Modal
            modal_id="purchase-order"
            dimension={{ width: 500 }}
            modal_header="Import Purchase Order"
            onClose={onToggleModal}
          >
            <div className="flex-align-center flex-column">
              <FileUpload onUpload={onFileUpload} />
              {fileState && !uploading ? (
                <div>
                  <Button className="rti-blue-round" variant="contained" onClick={onPOImport}>
                    import
                  </Button>
                </div>
              ) : uploading ? (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <label>Creating order...</label>
                  <br></br>
                  <LinearProgress />
                </div>
              ) : null}
            </div>
          </Modal>
        ) : null}
        <AlertBar
          visible={snackbar.visible}
          onClose={handleCloseSnack}
          message={snackbar.message}
          severity={snackbar.severity}
        />
        <CustomTable
          searchable
          paginate
          loading_data={loading}
          search={Search}
          clear_search={ClearSearch}
          headers={TableHeaders}
          search_options={SearchOptions}
          rows={renderTablebody()}
          onClick={rowClick}
          table_buttons={renderTableButtons()}
        />
      </div>
    </div>
  );
};

export default PurchaseOrders;
