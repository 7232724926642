/**
 * Backend file for connecting to the database and retrieving information about information and analytics tables.
 * @component
 * @module tableManagementTB
 * @category Database API
 */

import pool from './pool.js';
import path from 'path';

const currentUrl = new URL(import.meta.url);
const _file = path.basename(currentUrl.pathname);

/**ALERTS**/
//Gets a List of information about each Alert
/**
 * Selects data from the alerts table for table management.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
 *
 * @method
 */
export const selectFromAlertsForTM = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const readPendingAlerts = `CALL selectFromAlertsForTM();`;
			const response = await pool.query(readPendingAlerts);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectFromAlertsForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Deletes an alert for table management.
 * @param {number} AlertID - The ID of the alert to be deleted.
 * @returns - {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If an error occurs while deleting the alert.
 *
 * @method
 */
export const deleteAlertForTM = async (AlertID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `CALL deleteAlertForTM(${AlertID})`;
			const response = await pool.query(statement, JSON.stringify(AlertID));
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.deleteAlertForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves the user's PTO (Paid Time Off) information.
 *
 * @param {string} _uid - The user's unique identifier.
 * @returns - {Promise} A promise that resolves with the user's PTO information.
 * @throws {Error} If an error occurs while retrieving the user's PTO information.
 *
 * @method
 */
export const getUserPTO = (_uid) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.getUserPTO(${_uid});`;
			const response = await pool.query(action, JSON.stringify(_uid));
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getUserPTO: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Updates PTO for all users.
 * @param {Object} _data - The data to be passed to the updatePTOForAll function.
 * @returns - {Promise} A promise that resolves with the response from the updatePTOForAll function.
 * @throws {Error} If an error occurs during the updatePTOForAll function.
  *
  @method
 */
export const updatePTOForAllUsers = (_data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL updatePTOForAll(?);`;
			const response = await pool.query(action, [JSON.stringify(_data)]);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updatePTOForAllUsers: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Marks a kiosk as clean.
 *
 * @param {string} _kiosk - The kiosk to mark as clean.
 * @returns - {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs while marking the kiosk as clean.
  *
  @method
 */
export const markKioskClean = (_kiosk) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL markKioskClean('${_kiosk}');`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.markKioskClean: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves dispatchers from the tableManagementTB.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
 *
 * @method selectFromDispatchersForTM
 */
/**DISPATCHERS**/
//Gets a List of information about each Dispatcher
export const selectFromDispatchersForTM = async () => {
	try {
		const readPendingUsers = `CALL selectFromDispatchersForTM();`;

		const response = await pool.query(readPendingUsers);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromDispatchersForTM: ${error} - ${new Date()}`);
		throw new Error('Could not get dispatcher information');
	}
};

/**
 * Updates the dispatcher for table management.
 *
 * @param {Object} dispatcherObject - The dispatcher object containing the updated information.
 * @param {number} dispatcherObject.UserID - The ID of the dispatcher.
 * @param {string} dispatcherObject.Username - The username of the dispatcher.
 * @param {string} dispatcherObject.UserType - The type of the dispatcher.
 * @param {string} dispatcherObject.FirstName - The first name of the dispatcher.
 * @param {string} dispatcherObject.LastName - The last name of the dispatcher.
 * @param {string} dispatcherObject.PhoneNumber - The phone number of the dispatcher.
 * @param {string} dispatcherObject.Email - The email of the dispatcher.
 * @param {string} dispatcherObject.AccrualRate - The accrual rate of the dispatcher.
 * @param {string} dispatcherObject.AccrualType - The accrual type of the dispatcher.
 * @param {number} dispatcherObject.PTO - The PTO (Paid Time Off) of the dispatcher.
 * @param {string} dispatcherObject.Birthday - The birthday of the dispatcher.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If there is an error updating the dispatcher.
  *
  @method updateDispatcherForTM
 */
//Updates a Dispatcher Info in MySQL
export const updateDispatcherForTM = async (dispatcherObject) => {
	return new Promise(async (resolve, reject) => {
		try {
			const updateSolutionData = `
      CALL updateDispatcherForTM(
      "${dispatcherObject.UserID}",
      "${dispatcherObject.Username}",
      "${dispatcherObject.UserType}",
      "${dispatcherObject.FirstName}",
      "${dispatcherObject.LastName}",
      "${dispatcherObject.FullName}",
      '${dispatcherObject.PhoneNumber}',
      "${dispatcherObject.Email}",
      "${dispatcherObject.Birthday}",
      "${dispatcherObject.AccrualType}",
      "${dispatcherObject.PTO}",
      "${dispatcherObject.AccrualRate}"
      );`;
			const response = await pool.query(updateSolutionData);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updateDispatcherForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Deletes a dispatcher from the table management database.
 * @param {number} UserID - The ID of the dispatcher to be deleted.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If an error occurs while deleting the dispatcher.
  *
  @method deleteDispatcherForTM
 */
//Updates a Dispatcher Info in MySQL
export const deleteDispatcherForTM = async (UserID) => {
	return new Promise(async (resolve, reject) => {
		//["DispatcherID", "FirstName", "LastName", "PhoneNumber", "Email"]
		try {
			const statement = `CALL RemoveDispatcherFromLists(${UserID})`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.deleteDispatcherForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves all records from the "kiosk_issues" table.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectFromKiosksIssuesForTM
 */
/**ISSUES**/
//Gets a List of information about each Issue
export const selectFromKiosksIssuesForTM = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const readPendingKiosksIssues = `SELECT * FROM kiosk_issues;`;
			const response = await pool.query(readPendingKiosksIssues);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectFromKiosksIssuesForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Inserts an issue into the kiosk_issues table.
 * @param {Object} obj - The object containing the issue details.
 * @param {string} obj.ErrorCode - The error code of the issue.
 * @param {string} obj.Description - The description of the issue.
 * @param {Array} obj.ToDoNext - The array of next steps to be taken for the issue.
 * @returns - {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If there is an error inserting the issue.
  *
  @method insertIssueForTM
 */
//Insert a issue Info in MySQL
export const insertIssueForTM = async (obj) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `
                INSERT INTO dispatch_console.kiosk_issues
                ${'(ErrorCode, Description, ToDoNext)'}
                VALUES('${obj.ErrorCode}', '${obj.Description}', '${JSON.stringify(obj.ToDoNext)}');
                `;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.insertIssueForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Updates an issue in the kiosk_issues table.
 * @param {Object} issueObject - The issue object containing the properties to update.
 * @param {string} issueObject.KioskIssueID - The ID of the kiosk issue.
 * @param {string} issueObject.ErrorCode - The error code of the kiosk issue.
 * @param {string} issueObject.Description - The description of the kiosk issue.
 * @param {Array} issueObject.ToDoNext - The array of next steps for the kiosk issue.
 * @returns - {Promise} A promise that resolves with the response from the database update.
 * @throws {Error} If there is an error updating the issue in the database.
  *
  @method updateIssueForTM

 */
//Updates a Issue Info in MySQL
export const updateIssueForTM = async (issueObject) => {
	return new Promise(async (resolve, reject) => {
		//["KioskIssueID", "ErrorCode", "Description", "ToDoNext"]
		try {
			const updateSolutionData = `UPDATE kiosk_issues
                    SET
                    KioskIssueID = "${issueObject.KioskIssueID}",
                    ErrorCode = "${issueObject.ErrorCode}",
                    Description = "${issueObject.Description}",
                    ToDoNext = '${JSON.stringify(issueObject.ToDoNext)}'
                    WHERE KioskIssueID = "${issueObject.KioskIssueID}"
                `;
			const response = await pool.query(updateSolutionData);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updateIssueForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Deletes a kiosk issue for table management.
 * @param {string} KioskIssueID - The ID of the kiosk issue to be deleted.
 * @returns - {Promise<any>} A promise that resolves with the response from the database.
 * @throws {Error} If an error occurs while deleting the kiosk issue.
  *
  @method deleteKioskIssueForTM

 */
//Deletes a Kiosks Info in MySQL
export const deleteKioskIssueForTM = async (KioskIssueID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `DELETE FROM dispatch_console.kiosk_issues WHERE KioskIssueID = '${KioskIssueID}'`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR tableManagementTB.deleteKioskIssueForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves a list of jobs with associated information for table management.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
 *
 * @method selectFromJobsForTM
 */
/**JOBS**/
//Gets a List of information about each Job
export const selectFromJobsForTM = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const readPendingJobs = `SELECT
                        dispatch_console.jobs.JobID,
                        dispatch_console.jobs.KioskID,
                        dispatch_console.jobs.AlertID,
                        dispatch_console.jobs.KioskIssueID,
                        dispatch_console.jobs.CreatedDate,
                        dispatch_console.jobs.AssignedDate,
                        dispatch_console.jobs.CompletedDate,
                        dispatch_console.jobs.Dispatcher,
                        dispatch_console.jobs.EstimateTimeArrival,
                        dispatch_console.jobs.JobStatus,
                        dispatch_console.jobs.TechnicianID,
                        dispatch_console.technicians.FirstName,
                        dispatch_console.technicians.LastName,
                        dispatch_console.kiosk_issues.Description,
                        dispatch_console.kiosks.StoreCity,
                        dispatch_console.kiosks.StoreName
                    FROM jobs
                    INNER JOIN dispatch_console.technicians
                    ON dispatch_console.jobs.TechnicianID = dispatch_console.technicians.TechnicianID
                    INNER JOIN dispatch_console.kiosk_issues
                    ON dispatch_console.jobs.KioskIssueID = dispatch_console.kiosk_issues.KioskIssueID
                    INNER JOIN dispatch_console.kiosks
                    ON dispatch_console.jobs.KioskID = dispatch_console.kiosks.KioskID
                    ORDER BY dispatch_console.jobs.CreatedDate DESC;
                    `;
			const response = await pool.query(readPendingJobs);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectFromJobsForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves all records from the "kiosks" table.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectFromKiosksForTM
 */
/**KIOSKS**/
//Gets a List of information about each Kiosk
export const selectFromKiosksForTM = async () => {
	try {
		const readPendingKiosks = `SELECT * FROM kiosks`;
		const response = await pool.query(readPendingKiosks);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromKiosksForTM: ${error.message} - ${new Date()}`);
		throw new Error('Error: tableManagementTB.selectFromKiosksForTM');
	}
};

/**
 * Retrieves data from the "kiosk_archive" table in the "dispatch_console" database.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
 *
 * @method selectFromArchiveKiosksForTM
 */
export const selectFromArchiveKiosksForTM = async () => {
	try {
		const selectArchives = `SELECT * FROM dispatch_console.kiosk_archive`;
		const response = await pool.query(selectArchives);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromArchiveKiosksForTM: ${error.message} - ${new Date()}`);
		throw new Error('Error: tableManagementTB.selectFromArchiveKiosksForTM');
	}
};

/**
 * Retrieves a list of kiosks with address changes.
 * @returns - {Promise<Array>} A promise that resolves to an array of kiosks with address changes.
 * @throws {Error} If there is an error retrieving the kiosks.
  *
  @method getKiosksWithAddressChange
 */
export const getKiosksWithAddressChange = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const selectArchives = `CALL dispatch_console.getKiosksWithAddressChange();`;
			const response = await pool.query(selectArchives);
			resolve(response[0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getKiosksWithAddressChange: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves jobs by technician ID.
 *
 * @param {_tid} _tid - The technician ID.
 * @returns - {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs while querying the database.
  *
  @method getJobsByTechID
 */
export const getJobsByTechID = (_tid) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.getJobsByTechID(${_tid});`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getJobsByTechID: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves a job by its ID.
 *
 * @param {_tid} _tid - The ID of the table.
 * @param {_jid} _jid - The ID of the job.
 * @returns - {Promise} A promise that resolves with the job data.
 * @throws {Error} If an error occurs while retrieving the job.
  *
  @method getJobByJobID
 */
export const getJobByJobID = (_tid, _jid) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.getJobByJobID(${_tid},'${_jid}');`;
			const response = await pool.query(action);
			resolve(response[0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getJobByJobID: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Updates the email list in the tableManagementTB.
 *
 * @param {Array} _list - The list of emails to be updated.
 * @returns - {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error updating the email list.
  *
  @method updateEmailList
 */
export const updateEmailList = (_list) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.updateEmailList(?);`;
			const response = await pool.query(action, JSON.stringify(_list));
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updateEmailList: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves technical solutions from the database.
 * @param {string} _query - The query to be executed.
 * @returns - {Promise<Array>} - A promise that resolves to an array of technical solutions.
 * @throws {Error} - If an error occurs while retrieving the technical solutions.
  *
  @method getTechSolutions
 */
// Tech Solutions CRUD operations
export const getTechSolutions = (_query) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.getTechSolutions('${_query}');`;
			const response = await pool.query(action);
			resolve(response[0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getTechSolutions: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Adds a tech solution to the table management database.
 * @param {Object} _data - The data of the tech solution to be added.
 * @param {string} _data.title - The title of the tech solution.
 * @param {string} _data.solution - The solution of the tech solution.
 * @returns - {Promise} A promise that resolves with the response from the database or rejects with an error.
  * @throws {Error} If an error occurs while adding the tech solution.
  *
  @method addTechSolution
 */
export const addTechSolution = (_data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.addTechSolution(?,?);`;
			const response = await pool.query(action, [JSON.stringify(_data.title), JSON.stringify(_data.solution)]);
			resolve(response, [0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.addTechSolution: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Edits a technical solution in the dispatch_console.tech_help table.
 * @param {Object} _data - The data of the technical solution to be edited.
 * @param {number} _data.id - The ID of the technical solution.
 * @param {string} _data.title - The title of the technical solution.
 * @param {string} _data.solution - The solution of the technical solution.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If an error occurs while editing the technical solution.
  *
  @method editTechSolution
 */
export const editTechSolution = (_data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `UPDATE dispatch_console.tech_help set title = ?, solution = ? where id = ${_data.id};`;
			const response = await pool.query(action, [JSON.stringify(_data.title), JSON.stringify(_data.solution)]);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.editTechSolution: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Removes a tech solution from the dispatch_console.tech_help table.
 *
 * @param {_sid} _sid - The ID of the tech solution to be removed.
 * @returns - {Promise} - A promise that resolves with the response from the database query.
 * @throws {Error} - If an error occurs during the removal process.
  *
  @method removeTechSolution
 */
export const removeTechSolution = (_sid) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `delete from dispatch_console.tech_help where id = ${_sid};`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.removeTechSolution: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

// ########## RTI Solutions CRUD operations ##########
/**
 * Retrieves RTI solutions based on the provided query.
 *
 * @param {string} _query - The query to filter the RTI solutions.
 * @returns - {Promise<Array>} - A promise that resolves to an array of RTI solutions.
 * @throws {Error} - If an error occurs while retrieving the RTI solutions.
 *
 * @method getRTISolutions
 */
export const getRTISolutions = (_query) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.getRTISolutions('${_query}');`;
			const response = await pool.query(action);
			resolve(response[0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getRTISolutions: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Adds an RTI solution to the table management database.
 * @param {Object} _data - The data object containing the title and solution of the RTI solution.
 * @returns - {Promise} - A promise that resolves with the response from the database.
 * @throws {Error} - If there is an error while adding the RTI solution to the database.
 *
 * @method addRTISolution
 */
export const addRTISolution = (_data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.addRTISolution(?,?);`;
			const response = await pool.query(action, [JSON.stringify(_data.title), JSON.stringify(_data.solution)]);
			resolve(response, [0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.addRTISolution: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Edits an RTI solution in the dispatch_console.rti_solutions table.
 * @param {Object} _data - The data of the RTI solution to be edited.
 * @param {number} _data.id - The ID of the RTI solution.
 * @param {string} _data.title - The title of the RTI solution.
 * @param {string} _data.solution - The solution of the RTI solution.
 * @returns - {Promise} A promise that resolves with the response from the database update.
 * @throws {Error} If an error occurs during the database update.
 *
 * @method editRTISolution
 */
export const editRTISolution = (_data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `update dispatch_console.rti_solutions set title = ?, solution = ? where id = ${_data.id};`;
			const response = await pool.query(action, [JSON.stringify(_data.title), JSON.stringify(_data.solution)]);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.editRTISolution: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Removes an RTI solution from the dispatch_console.rti_solutions table.
 *
 * @param {_sid} _sid - The ID of the RTI solution to be removed.
 * @returns - {Promise} - A promise that resolves with the response from the database query.
 * @throws {Error} - If there is an error while removing the RTI solution.
 *
 * @method removeRTISolution
 */
export const removeRTISolution = (_sid) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `delete from dispatch_console.rti_solutions where id = ${_sid};`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.removeRTISolution: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/* ########## ENDEX OF RTI FAQ OPERATIONS ########## */

//Updates a Kiosks Info in MySQL
/**
 * Updates a kiosk in the tableManagementTB.
 * @param {Object} objt - The object containing the updated kiosk information.
 * @param {string} objt.KioskID - The ID of the kiosk.
 * @param {string} objt.KioskID_Loc - The location ID of the kiosk.
 * @param {string} objt.KioskInstallDate - The installation date of the kiosk.
 * @param {string} objt.StoreName - The name of the store.
 * @param {string} objt.StoreHours - The hours of operation of the store (in JSON string format).
 * @param {string} objt.StorePhone - The phone number of the store.
 * @param {string} objt.StoreAddress - The address of the store.
 * @param {string} objt.StoreCity - The city of the store.
 * @param {string} objt.StoreState - The state of the store.
 * @param {string} objt.StoreZip - The ZIP code of the store.
 * @param {string} objt.StoreCounty - The county of the store.
 * @param {string} objt.ServerID - The ID of the server.
 * @param {string} objt.ModemType - The type of modem.
 * @param {string} objt.Carrier - The carrier of the kiosk.
 * @param {string} objt.CarrierNumber - The phone number of the carrier.
 * @param {string} objt.PrimaryTechs - The primary technicians assigned to the kiosk (in JSON string format).
 * @param {string} objt.BackupTechs - The backup technicians assigned to the kiosk (in JSON string format).
 * @param {string} objt.PaperChanger - The paper changer of the kiosk.
 * @param {string} objt.Notes - The notes for the kiosk.
 * @param {string} objt.ActiveOrInactive - The status of the kiosk (active or inactive).
 * @param {string} objt.RestartSwitch - The presence of a restart switch for the kiosk.
 * @param {string} objt.PaperBridge - The paper bridge of the kiosk.
 * @param {string} objt.Cleaned - The cleaning status of the kiosk.
 * @param {string} objt.StarFirmware - The firmware version of the kiosk.
 * @param {string} objt.NumberOfStarRolls - The number of star rolls in the kiosk.
 * @param {string} objt.Notes - The notes for the kiosk.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If there is an error updating the kiosk.
 *
 * @method updateKioskForTM
 */
export const updateKioskForTM = async (objt) => {
	try {
		const statement = `UPDATE JK_KIOSK_TEST 
            SET
            KioskID = ?,
            KioskID_Loc = ?,
            KioskInstallDate = ?,
            StoreName = ?,
            StoreHours = ?,
            StorePhone = ?,
			Latitude = ?,
			Longitude = ?,
            StoreAddress = ?,
            StoreCity = ?,
            StoreState = ?,
            StoreZip = ?,
            StoreCounty = ?,
            ServerID = ?,
            ModemType = ?,
            Carrier = ?,
            CarrierNumber = ?,
            PrimaryTechs = ?,
            BackupTechs = ?,
            PaperChanger = ?,
            Notes = ?,
            ActiveOrInactive = ?,
            has_switch = ?,
            paper_bridge = ?,
            cleaned = ?,
            star_firmware = ?,
            ski_slope = ?,
            os_installed = ?,
            NumberOfStarRolls = ?
            WHERE KioskID = ?;
            `;

		const params = [
			objt.KioskID,
			objt.KioskID_Loc,
			objt.KioskInstallDate,
			objt.StoreName,
			JSON.stringify(objt.StoreHours),
			objt.StorePhone,
			objt.Latitude,
			objt.Longitude,
			objt.StoreAddress,
			objt.StoreCity,
			objt.StoreState,
			objt.StoreZip,
			objt.StoreCounty,
			objt.ServerID,
			objt.ModemType,
			objt.Carrier,
			objt.CarrierNumber,
			JSON.stringify(objt.PrimaryTechs),
			JSON.stringify(objt.BackupTechs),
			objt.PaperChanger,
			objt.Notes,
			objt.ActiveOrInactive,
			objt.has_switch,
			objt.paper_bridge,
			objt.cleaned,
			objt.star_firmware,
			objt.ski_slope,
			objt.os_installed,
			objt.NumberOfStarRolls,
			objt.KioskID,
		];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateKioskForTM: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 * Retrieves the monthly transaction average from the database.
 * 2024-10-30: this file can be removed as a stored proc always gathers this data in the kiosks table; this code does not perform and meaningful function.
 * @returns - {Promise<Array>} A promise that resolves to an array containing the monthly transaction average.
 * @throws {Error} If an error occurs while retrieving the monthly transaction average.
  *
  @method getMonthlyTxnAvg
 */
export const getMonthlyTxnAvg = async () => {
	try {
		const action = `call dispatch_console.getMonthlyTxnAvg();`;
		const response = await pool.query(action);

		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getMonthlyTxnAvg: ${error} - ${new Date()}`);
		throw new Error('Error: tableManagementTB.getMonthlyTxnAvg');
	}
};

/**
 * Updates the KioskMapping table with the provided list of data.
 *
 * @param {Array} list - The list of data to update the table with.
 * @returns - {Promise} - A promise that resolves with the response from the database.
 * @throws {Error} - If an error occurs during the update process.
  *
  @method updateKioskMapping
 */
export const updateKioskMapping = (list) =>
	new Promise(async (resolve, reject) => {
		try {
			for (let i = 0; i < list.length; i++) {
				const writeSolutionData = `UPDATE KioskMapping SET
                Customer = '${list[i].Customer.replace(/\'/g, '_')}',
                Company = '${list[i].Company.replace(/\'/g, '_')}',
                MainPhone = '${list[i].MainPhone}',
                AltPhone = '${list[i].AltPhone}',
                Bill1 = '${list[i].Bill1.replace(/\'/g, '_')}',
                Bill2 = '${list[i].Bill2.replace(/\'/g, '_')}',
                Bill3 = '${list[i].Bill3.replace(/\'/g, '_')}',
                Ship1 = '${list[i].Ship1.replace(/\'/g, '_')}',
                Ship2 = '${list[i].Ship2.replace(/\'/g, '_')}',
                Ship3 = '${list[i].Ship3.replace(/\'/g, '_')}'
                WHERE ID = '${list[i].ID}';`;
				const response = await pool.query(writeSolutionData);
				resolve(response);
			}
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updateKioskMapping: ${error} - ${new Date()}`);
			reject(error);
		}
	});

/**
 * Inserts a new mapping into the KioskMapping table.
 *
 * @param {Array} itemList - The list of items to be inserted.
 * @returns - {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If an error occurs during the insertion process.
  *
  @method insertNewMapping
 */
export const insertNewMapping = (itemList) =>
	new Promise(async (resolve, reject) => {
		try {
			for (let i = 0; i < itemList.length; i++) {
				const writeSolutionData = `INSERT INTO dispatch_console.KioskMapping (Customer, Company, MainPhone, AltPhone, Bill1, Bill2, Bill3, Ship1, Ship2, Ship3) VALUES
                ('${itemList[i].Customer.replace(/\'/g, '_')}',
                 '${itemList[i].Company.replace(/\'/g, '_')}',
                 '${itemList[i].MainPhone}',
                 '${itemList[i].AltPhone}',
                 '${itemList[i].Bill1.replace(/\'/g, '_')}',
                 '${itemList[i].Bill2.replace(/\'/g, '_')}',
                 '${itemList[i].Bill3.replace(/\'/g, '_')}',
                 '${itemList[i].Ship1.replace(/\'/g, '_')}',
                 '${itemList[i].Ship2.replace(/\'/g, '_')}',
                 '${itemList[i].Ship3.replace(/\'/g, '_')}');`;
				const response = await pool.query(writeSolutionData);
				resolve(response);
			}
		} catch (error) {
			console.error(`ERROR: tableManagementTB.insertNewMapping: ${error} - ${new Date()}`);
			reject(error);
		}
	});

/**
 * Retrieves all records from the KioskMapping table.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectAllFromKioskMapping
 */
export const selectAllFromKioskMapping = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const readPendingKiosksIssues = `SELECT * FROM KioskMapping;`;
			const response = await pool.query(readPendingKiosksIssues);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAllFromKioskMapping: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Inserts a kiosk into the dispatch_console.kiosks table.
 *
 * @param {Object} obj - The kiosk object containing the following properties:
 *   - KioskID {string} - The ID of the kiosk.
 *   - KioskInstallDate {string} - The installation date of the kiosk.
 *   - StoreName {string} - The name of the store.
 *   - StoreHours {string} - The hours of operation of the store (as a JSON string).
 *   - StorePhone {string} - The phone number of the store.
 *   - StoreAddress {string} - The address of the store.
 *   - StoreCity {string} - The city where the store is located.
 *   - StoreState {string} - The state where the store is located.
 *   - StoreZip {string} - The ZIP code of the store.
 *   - StoreCounty {string} - The county where the store is located.
 *   - ServerID {string} - The ID of the server.
 *   - ModemType {string} - The type of modem.
 *   - Carrier {string} - The carrier of the kiosk.
 *   - CarrierNumber {string} - The phone number of the carrier.
 *   - PrimaryTechs {string} - The primary technicians assigned to the kiosk (as a JSON string).
 *   - BackupTechs {string} - The backup technicians assigned to the kiosk (as a JSON string).
 *   - PaperChanger {string} - The paper changer of the kiosk.
 *   - Notes {string} - Additional notes about the kiosk.
 *   - RestartSwitch {boolean} - Indicates if the kiosk has a restart switch.
 *   - PaperBridge {boolean} - Indicates if the kiosk has a paper bridge.
 *   - Cleaned {string} - Indicates if the kiosk has been cleaned.
 *   - StarFirmware {string} - The firmware version of the kiosk.
 *   - KioskID_Loc {string} - The location ID of the kiosk.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If there is an error inserting the kiosk.
  *
  @method insertKioskForTM
 */
export const insertKioskForTM = async (obj) => {
	try {
		const statement = `INSERT INTO dispatch_console.kiosks
            (KioskID,
                KioskInstallDate,
                StoreName,
                StoreHours,
                StorePhone,
                StoreAddress,
                StoreCity,
                StoreState,
                StoreZip,
                StoreCounty,
                ServerID,
                ModemType,
                Carrier,
                CarrierNumber,
                PrimaryTechs,
                BackupTechs,
                PaperChanger,
                Notes,
                has_switch,
                paper_bridge,
                cleaned,
                star_firmware,
                ski_slope,
				os_installed,
                ActiveOrInactive,
                NumberOfStarRolls,
                Latitude,
                Longitude,
                KioskID_Loc)
            VALUES(?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?);`;

		const params = [
			obj.KioskID,
			obj.KioskInstallDate,
			obj.StoreName,
			JSON.stringify(obj.StoreHours),
			obj.StorePhone,
			obj.StoreAddress,
			obj.StoreCity,
			obj.StoreState,
			obj.StoreZip,
			obj.StoreCounty,
			obj.ServerID,
			obj.ModemType,
			obj.Carrier,
			obj.CarrierNumber,
			JSON.stringify(obj.PrimaryTechs),
			JSON.stringify(obj.BackupTechs),
			obj.PaperChanger,
			obj.Notes,
			obj.has_switch,
			obj.paper_bridge,
			obj.cleaned,
			obj.star_firmware,
			obj.ski_slope,
			obj.os_installed,
			obj.ActiveOrInactive,
			obj.NumberOfStarRolls,
			obj.Latitude,
			obj.Longitude,
			obj.KioskID_Loc,
		];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertKioskForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

/**
 * Retrieves email lists from the database.
 * @returns - {Promise<Array>} A promise that resolves to an array of email lists.
 * @throws {Error} If there is an error retrieving the email lists.
  *
  @method getEmailLists
 */
export const getEmailLists = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `call dispatch_console.getAllEmailLists();`;
			const response = await pool.query(action);
			resolve(response[0]);
		} catch (error) {
			console.error(`${_file},${getEmailLists.name},${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Creates a batch of jobs.
 * @param {Array} data - The data to be passed to the query.
 * @returns - {Promise} - A promise that resolves with the response from the query.
 * @throws {Error} - If an error occurs during the query execution.
  *
  @method createJobsBatch
 */
export const createJobsBatch = (data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.createJobsBatch(?);`;
			const response = await pool.query(action, data);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.createJobsBatch: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Inserts tech drive times into the database.
 *
 * @param {Array} list - The list of drive times to insert.
 * @returns - {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If an error occurs during the insertion process.
  *
  @method insertTechDriveTimes
 */
export const insertTechDriveTimes = async (list) => {
	try {
		const values = list
			.filter((item) => item !== undefined && item !== null && item.TechName) // Filter out undefined or null items
			.map((item) => {
				return `('${item.TechID}', '${item.TechName}', '${item.TechAddress}', '${item.KioskID}', '${item.DriveTime}', '${item.KioskID_Loc}')`;
			})
			.join(',');

		const driveTimes = list.map((item) => item.DriveTime).join(',');

		const writeSolutionData = `
		INSERT INTO dispatch_console.mapDriveTime
            ( TechID, TechName, TechAddress, KioskID, DriveTime, KioskID_Loc)
            VALUES ${values}
            ON DUPLICATE KEY UPDATE
                TechName = VALUES(TechName),
                TechAddress = VALUES(TechAddress),
                DriveTime = VALUES(DriveTime);
        `;

		const response = await pool.query(writeSolutionData);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertTechDriveTimes: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 * Updates the drive times for technicians in the table.
 *
 * @param {Array} list - The list of objects containing technician information and drive times.
 * @returns - {Promise} - A promise that resolves with the response from the database query.
 * @throws {Error} - If an error occurs during the update process.
  *
  @method updateTechDriveTimes
 */
export const updateTechDriveTimes = async (list) => {
	try {
		// using a promise.all to update all the drive times in parallel
		const responses = await Promise.all(
			list.map((item) => {
				const action = `UPDATE dispatch_console.mapDriveTime SET DriveTime = ? WHERE TechName = ? AND KioskID = ?;`;
				const params = [item.DriveTime, item.TechName, item.KioskID];
				return pool.query(action, params);
			})
		);

		return responses;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateTechDriveTimes: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 * Updates the primary and backup techs for a specific kiosk.
 *
 * @param {string[]} primaryTechs - The primary techs to be updated.
 * @param {string[]} backupTechs - The backup techs to be updated.
 * @param {string} paperChanger - The paper changer to be updated.
 * @param {string} kioskId - The ID of the kiosk to be updated.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs while updating the kiosk techs.
 */
export const updateKioskTechs = async (primaryTechs, backupTechs, paperChanger, kioskId) => {
	try {
		const primaryTechsStr = JSON.stringify(primaryTechs.map((tech) => tech.replace(/['"]+/g, '')));
		const backupTechsStr = JSON.stringify(backupTechs.map((tech) => tech.replace(/['"]+/g, '')));
		const paperChangerTechStr = paperChanger.replace(/['"]+/g, '');
		const updateQuery = `
            UPDATE dispatch_console.kiosks
            SET PrimaryTechs = ?, BackupTechs = ?, PaperChanger = ?
            WHERE KioskID = ?;
        `;

		const response = await pool.query(updateQuery, [primaryTechsStr, backupTechsStr, paperChangerTechStr, kioskId]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateKioskTechs: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 * Updates all kiosk primary and backup technicians in the database in a single batch.
 *
 * @param {Array} kiosks - The array of kiosks to update.
 * @returns - {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If there is an error updating the kiosk technicians.
  *
  @method updateKioskTechsBatch
 */
export const updateKioskTechsBatch = async (kiosks) => {
	try {
		const action = `call dispatch_console.updateKioskTechsBatch(?);`;

		const params = [JSON.stringify(kiosks)];
		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateKioskTechsBatch: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 * Updates the job status of a paper job in the dispatch console's paper_jobs table.
 *
 * @param {Object} paper_jobs - The paper job object containing the updated job details.
 * @param {string} job_id - The ID of the paper job to update.
 * @returns - {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs during the database query.
  *
  @method updatePaperJobStatus
 */
export const updatePaperJobStatus = (paper_jobs, job_id) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `UPDATE dispatch_console.paper_jobs
                SET Kiosks = '${paper_jobs.Jobs}',
                CompletedDate = if(${paper_jobs.Complete} = true, now(), NULL),
                JobStatus = if(${paper_jobs.Complete} = true,'Completed','Pending')
                WHERE PaperJobID = '${job_id}';
                `;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updatePaperJobStatus: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Archives a kiosk for table management.
 * @param {Object} obj - The object containing kiosk information.
 * @param {string} obj.KioskID - The ID of the kiosk.
 * @param {string} obj.KioskInstallDate - The installation date of the kiosk.
 * @param {string} obj.StoreName - The name of the store.
 * @param {string} obj.StorePhone - The phone number of the store.
 * @param {string} obj.StoreAddress - The address of the store.
 * @param {string} obj.StoreCity - The city of the store.
 * @param {string} obj.StoreState - The state of the store.
 * @param {string} obj.StoreZip - The ZIP code of the store.
 * @param {string} obj.StoreCounty - The county of the store.
 * @param {string} obj.ServerID - The ID of the server.
 * @param {string} obj.ModemType - The type of the modem.
 * @param {string} obj.Carrier - The carrier of the kiosk.
 * @param {string} obj.CarrierNumber - The carrier number of the kiosk.
 * @param {string} obj.PaperChanger - The paper changer of the kiosk.
 * @param {string} obj.Notes - Additional notes for the kiosk.
 * @param {string} obj.ActiveOrInactive - The status of the kiosk.
 * @param {string} obj.KioskID_Loc - The location ID of the kiosk.
 * @returns - {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If there is an error while archiving the kiosk.
  *
  @method archiveKioskForTM
 */
//Archives a Kiosks Info in MySQL
export const archiveKioskForTM = async (obj) => {
	try {
		const statement = `INSERT INTO dispatch_console.kiosk_archive(
			KioskID,
			KioskInstallDate,
			StoreName,
			StoreHours,
			StorePhone,
			Latitude,
			Longitude,
			StoreAddress,
			StoreCity,
			StoreState,
			StoreZip,
			StoreCounty,
			ServerID,
			ModemType,
			Carrier,
			CarrierNumber,
			PrimaryTechs,
			BackupTechs,
			PaperChanger,
			Notes,
			has_switch,
			paper_bridge,
			cleaned,
			star_firmware,
			ski_slope,
			os_installed,
			ActiveOrInactive,
			NumberOfStarRolls,
			DateArchived,
			KioskID_Loc)
			VALUES(?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,NOW(),?);`;

		const params = [
			obj.KioskID,
			obj.KioskInstallDate,
			obj.StoreName,
			JSON.stringify(obj.StoreHours),
			obj.StorePhone,
			obj.Latitude,
			obj.Longitude,
			obj.StoreAddress,
			obj.StoreCity,
			obj.StoreState,
			obj.StoreZip,
			obj.StoreCounty,
			obj.ServerID,
			obj.ModemType,
			obj.Carrier,
			obj.CarrierNumber,
			JSON.stringify(obj.PrimaryTechs),
			JSON.stringify(obj.BackupTechs),
			obj.PaperChanger,
			obj.Notes,
			obj.has_switch,
			obj.paper_bridge,
			obj.cleaned,
			obj.star_firmware,
			obj.ski_slope,
			obj.os_installed,
			obj.ActiveOrInactive,
			obj.NumberOfStarRolls,
			obj.KioskID_Loc,
		];

		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.archiveKioskForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

/**
 * Inserts daily transactions into the transaction_history table.
 *
 * @param {Array} kiosks - An array of kiosks containing transaction information.
 * @returns - {Promise} - A promise that resolves with the response from the database.
 * @throws {Error} - If an error occurs during the insertion process.
  *
  @method insertDailyTransactions
 */
export const insertDailyTransactions = (kiosks) => {
	return new Promise(async (resolve, reject) => {
		try {
			for (let i = 0; i < kiosks.length; i++) {
				const action = `INSERT INTO dispatch_console.transaction_history (kiosk_id,total_transactions,sticker_year,date)
                VALUES(
                    '${kiosks[i].kiosk_id}',
                    '${kiosks[i].count}',
                    '${kiosks[i].sticker_year}',
                    NOW()
                );`;
				const response = await pool.query(action, JSON.stringify(kiosks[i].transactions));
				resolve(response);
			}
		} catch (error) {
			console.error(`${_file}, ${insertDailyTransactions.name}, ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves the paper trend by kiosk.
 * @param {Array} args - The arguments for the function.
 * @returns - {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs during the database query.
  *
  @method getPaperTrendByKiosk
 */
export const getPaperTrendByKiosk = (args) => {
	return new Promise(async (resolve, reject) => {
		try {
			let year_list = `"${args.map((e) => `${e}`).join(',')}"`;
			const action = `call dispatch_console.GetPaperTrendByKiosk(${year_list});`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getPaperTrendByKiosk: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves drive times within a specified distance for a given tech.
 * @param {string} _tech - The name of the tech.
 * @param {number} _distance - The maximum distance in minutes.
 * @returns - {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs while querying the database.
  *
  @method getDTWithin
 */
export const getDTWithin = (_tech, _distance) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `SELECT
                TechID AS tech_id,
                TechName AS tech_name,
                KioskID as kiosk_id,
                calcMapDriveTime(DriveTime) as dt,
                DriveTime as dt_orig
                FROM dispatch_console.mapDriveTime
                WHERE TechName = '${_tech}' having dt <= ${_distance};
            `;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getDTWithin: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves the sticker end of life information for a given list of years and current project.
 * @param {number[]} years - The list of years to retrieve sticker end of life information for.
 * @param {string} cur_proj - The current project.
 * @returns - {Promise<any>} - A promise that resolves with the response from the API call.
 * @throws {Error} - If an error occurs during the API call.
  *
  @method getStickerEndOfLife
 */
export const getStickerEndOfLife = (years, cur_proj) => {
	return new Promise(async (resolve, reject) => {
		try {
			let year_list = `"${years.map((e) => `${e}`).join(',')}"`;
			const action = `call dispatch_console.getStickerEndOfLife(${year_list},${cur_proj});`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getStickerEndOfLife: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves the technicians within a specified kiosk based on the given coordinates.
 *
 * @param {Object} coordinates - The latitude and longitude coordinates.
 * @param {number} coordinates.latitude - The latitude coordinate.
 * @param {number} coordinates.longitude - The longitude coordinate.
 * @param {string} kiosk_id - The ID of the kiosk.
 * @returns - {Promise<Array>} - A promise that resolves to an array of technicians within the kiosk.
 * @throws {Error} - If there is an error retrieving the technicians.
  *
  @method getTechsWithinKiosk
 */
export const getTechsWithinKiosk = async (coordinates, kiosk_id) => {
	try {
		const action = `call dispatch_console.GetTechsWithinKioskCoords(?, ?, ?);`;
		const params = [coordinates.latitude, coordinates.longitude, kiosk_id];

		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getTechsWithinKiosk: ${error.message} - ${new Date()}`);
		throw new Error('Error retrieving technicians within kiosk');
	}
};

/**
 * Retrieves support logs for after hours.
 * @param {Object} args - The arguments for retrieving support logs.
 * @param {string} args.user - The user for whom to retrieve support logs.
 * @param {string} args.start_date - The start date for retrieving support logs.
 * @param {string} args.end_date - The end date for retrieving support logs.
 * @returns - {Promise} - A promise that resolves with the response from the database query.
 * @throws {Error} - If there is an error retrieving the support logs.
  *
  @method getSupportAfterHours
 */
export const getSupportAfterHours = (args) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `call dispatch_console.getAfterHourSupportLogs('${args.user}','${args.start_date}','${args.end_date}');`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getSupportAfterHours: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves the kiosks within a specified geographical area based on the given coordinates.
 * @param {Object} coordinates - The latitude and longitude coordinates.
 * @param {number} coordinates.latitude - The latitude value.
 * @param {number} coordinates.longitude - The longitude value.
 * @returns - {Promise<Array>} - A promise that resolves to an array of kiosks within the specified area.
 * @throws {Error} - If an error occurs while retrieving the kiosks.
  *
  @method getKiosksWithinTech
 */
export const getKiosksWithinTech = async (coordinates) => {
	try {
		const action = `call dispatch_console.GetKiosksWithinTechCoords(?, ?);`;

		const params = [coordinates.latitude, coordinates.longitude];
		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getKiosksWithinTech: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 * Deletes a kiosk for table management.
 *
 * @param {string} KioskID - The ID of the kiosk to be deleted.
 * @returns - {Promise<any>} A promise that resolves with the response from the database.
 * @throws {Error} If an error occurs while deleting the kiosk.
  *
  @method deleteKioskForTM
 */
export const deleteKioskForTM = async (KioskID) => {
	try {
		const statement = `call dispatch_console.removeKiosk(?);`;
		const params = [KioskID];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.deleteKioskForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

/**
 * Retrieves information about a specific kiosk.
 * @param {string} kiosk_id - The ID of the kiosk.
 * @returns - {Promise<any>} - A promise that resolves with the kiosk information.
 * @throws {Error} - If there is an error retrieving the kiosk information.
  *
  @method getKioskInfo
 */
export const getKioskInfo = (kiosk_id) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `SELECT * from dispatch_console.kiosks WHERE KioskID = '${kiosk_id}';`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getKioskInfo: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/*MOBILE MOE AND EMAIL MESSAGING LISTS*/
/**
 * Retrieves mobile and email lists from the database.
 * @returns - {Promise<Array>} A promise that resolves to an array of mobile and email lists.
 * @throws {Error} If there is an error retrieving the lists from the database.
  *
  @method selectMobileEmailLists
 */
export const selectMobileEmailLists = async () => {
	try {
		const statement = `CALL SelectMobileEmailLists()`;
		const response = await pool.query(statement);

		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectMobileEmailLists: ${error.message} - ${new Date()}`);
		throw new Error('Error retrieving mobile and email lists');
	}
};

/**
 * Retrieves the message list from the tableManagementTB.
 * @returns - {Promise<Array>} A promise that resolves to an array of messages.
 * @throws {Error} If there is an error retrieving the message list.
  *
  @method getMsgList
 */
export const getMsgList = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `call dispatch_console.getMsgList();`;
			const response = await pool.query(action);
			resolve(response[0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getMsgList: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves the list of kiosks assigned to a technician.
 *
 * @param {string} technician - The name of the technician.
 * @returns - {Promise<Array>} - A promise that resolves to an array of kiosks.
 * @throws {Error} - If there is an error retrieving the kiosks.
  *
  @method getKiosksForTech
 */
export const getKiosksForTech = (technician) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `CALL GetKiosksForTech('${technician}');`;
			const response = await pool.query(statement);
			resolve(response[0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getKiosksForTech: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Updates the MobileEmailLists table in the dispatch_console database.
 *
 * @param {Array} obj - An array of key-value pairs representing the data to be updated.
 * @returns - {Promise<number>} - A promise that resolves to the number of affected rows.
 * @throws {Error} - If an error occurs during the update process.
  *
  @method updateMobileEmailLists
 */
export const updateMobileEmailLists = (obj) => {
	return new Promise(async (resolve, reject) => {
		try {
			obj.forEach(async ([key, report]) => {
				const statement = `update dispatch_console.MobileEmailLists set MsgToList = ?, EmailToList = ? where ListName = '${key}';`;
				const response = await pool.query(statement, [
					JSON.stringify(report.MsgToList),
					JSON.stringify(report.EmailToList),
				]);
				resolve(response.affectedRows);
			});
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updateMobileEmailLists: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**Tech Support Schedule**/
//Gets a List of information about each TechHelpSolution
/**
 * Retrieves the tech support schedule for the table management.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectTechSupportScheduleForTM
 */
export const selectTechSupportScheduleForTM = async () => {
	try {
		const statement = `SELECT * FROM dispatch_console.tech_support_schedule;`;
		const response = await pool.query(statement);

		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectTechSupportScheduleForTM: ${error} - ${new Date()}`);
		throw new Error('Error retrieving tech support schedule');
	}
};

/**
 * Retrieves the phone number of the tech support user for a given day and time.
 *
 * @param {number} day - The day of the week (0-6, where 0 represents Sunday).
 * @param {string} current_time - The current time in the format "HH:MM:SS".
 * @returns - {Promise} A promise that resolves with the response containing the phone number.
 * @throws {Error} If an error occurs while retrieving the phone number.
  *
  @method getTechSupportByDay
 */
export const getTechSupportByDay = (day, current_time) => {
	return new Promise(async (resolve, reject) => {
		try {
			let time = current_time;
			const [hours] = current_time.split(':');
			if (hours < 8) {
				time = '08:00:00';
			}
			const statement = `SELECT PhoneNumber
            FROM dispatch_console.users
            WHERE UserID = (SELECT ${
							day == 0
								? 'Sunday'
								: day == 1
								? 'Monday'
								: day == 2
								? 'Tuesday'
								: day == 3
								? 'Wednesday'
								: day == 4
								? 'Thursday'
								: day == 5
								? 'Friday'
								: 'Saturday'
						} AS user_id
            FROM dispatch_console.tech_support_schedule
            WHERE Time <= '${time}'
            ORDER BY ScheduleID desc limit 1);`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getTechSupportByDay: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves kiosk issues by interval.
 * @param {number} interval - The interval value.
 * @returns - {Promise<Array>} - A promise that resolves to an array of kiosk issues.
 * @throws {Error} - If there is an error retrieving the kiosk issues.
  *
  @method getKioskIssuesByInterval
 */
export const getKioskIssuesByInterval = (interval) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `CALL dispatch_console.GetKioskIssuesByInterval(${interval});`;
			const response = await pool.query(statement);
			resolve(response[0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getKioskIssuesByInterval: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves alerts between two specified dates from the dispatch_console.kiosk_alerts table.
 * @param {string} after - The starting date (inclusive) for the alert occurrence.
 * @param {string} before - The ending date (inclusive) for the alert occurrence.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectAlertsBetweenDates
 */
export const selectAlertsBetweenDates = (after, before) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT *
            FROM dispatch_console.kiosk_alerts
            WHERE OccuranceDateTimeStamp BETWEEN '${after}' AND '${before}' AND KioskStatus = 'Fixed';`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAlertsBetweenDates: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves jobs between two specified dates from the dispatch_console.jobs table.
 * @param {string} after - The starting date (inclusive) for the job selection.
 * @param {string} before - The ending date (inclusive) for the job selection.
 * @returns - {Promise<object>} A promise that resolves with the response containing the selected jobs.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectJobsBetweenDates
 */
export const selectJobsBetweenDates = (after, before) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT *
            FROM dispatch_console.jobs
            WHERE CreatedDate BETWEEN '${after}' AND '${before}' AND AlertID != 'NULL';`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectJobsBetweenDates: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves reports between two specified dates from the dispatch_console.service_report table.
 *
 * @param {string} after - The starting date (inclusive) for the report selection.
 * @param {string} before - The ending date (inclusive) for the report selection.
 * @returns - {Promise<object>} - A promise that resolves with the response containing the selected reports.
 * @throws {Error} - If there is an error while executing the database query.
  *
  @method selectReportsBetweenDates
 */
export const selectReportsBetweenDates = (after, before) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT *
            FROM dispatch_console.service_report
            WHERE ServiceDate BETWEEN '${after}' AND '${before}' AND JobID NOT LIKE 'P%';`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectReportsBetweenDates: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Updates the schedule for table management.
 * @param {Object} obj - The object containing the schedule information.
 * @returns - {Promise<boolean>} - A promise that resolves to a boolean indicating the success of the update.
 * @throws {Error} - If an error occurs during the update process.
  *
  @method updateScheduleForTM
 */
export const updateScheduleForTM = async (obj) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `CALL UpdateSchedule('${JSON.stringify(obj)}');`;
			const response = await pool.query(statement);
			resolve(response[0][0].successful);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updateScheduleForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**TECH HELP**/
//Gets a List of information about each TechHelpSolution
/**
 * Retrieves all tech help solutions from the database.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectTechHelpSolutionsForTM
 */
export const selectTechHelpSolutionsForTM = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT * FROM tech_help_solutions;`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectTechHelpSolutionsForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**TECHNICIANS**/
//Gets a List of information about each Technician
/**
 * Retrieves daily alerts by kiosk.
 * @param {boolean} _fullReport - Indicates whether to retrieve the full report or not.
 * @returns - {Promise<Array|Object>} - A promise that resolves to an array of alert issues or a single alert issue object.
 * @throws {Error} - If an error occurs while retrieving the daily alerts.
  *
  @method getDailyAlertsByKiosk
 */
export const getDailyAlertsByKiosk = (_fullReport) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `call dispatch_console.GetAlertIssuesByKiosk(3,${_fullReport});`;
			const response = await pool.query(statement);
			resolve(!_fullReport ? response[0] : response);
		} catch (error) {
			console.error(`ERROR: TableManagementTB.getDailyAlertsByKiosk: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

// Select all Technician Information
/**
 * Retrieves a list of active technicians from the database.
 *
 * @returns - {Promise<Array>} A promise that resolves to an array of technician objects.
 * @throws {Error} If there is an error while retrieving the technicians.
  *
  @method selectFromTechniciansForTM
 */
export const selectFromTechniciansForTM = async () => {
	try {
		const statement = `SELECT * FROM technicians WHERE Active = 1 ORDER BY FirstName;`;
		const response = await pool.query(statement);

		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromTechniciansForTM: ${error.message} - ${new Date()}`);
		throw new Error('Error retrieving technicians');
	}
};

//Updates a Technician Info in MySQL
/**
 * Updates a technician for TM.
 * @param {Object} obj - The object containing the technician information.
 * @param {string} obj.TechnicianID - The ID of the technician.
 * @param {string} obj.Notes - The notes for the technician.
 * @param {string} obj.FirstName - The first name of the technician.
 * @param {string} obj.LastName - The last name of the technician.
 * @param {string} obj.StartDate - The start date of the technician.
 * @param {string} obj.MobileNumber - The mobile number of the technician.
 * @param {string} obj.CompanyName - The company name of the technician.
 * @param {string} obj.Address - The address of the technician.
 * @param {string} obj.City - The city of the technician.
 * @param {string} obj.State - The state of the technician.
 * @param {string} obj.Zip - The zip code of the technician.
 * @param {string} obj.BirthDate - The birth date of the technician.
 * @param {string} obj.PersonalEmail - The personal email of the technician.
 * @param {boolean} obj.PaperChanger - Indicates if the technician is a paper changer.
 * @param {boolean} obj.EmailReminder - Indicates if the technician has email reminders.
 * @param {string} obj.Shipping - The shipping information of the technician.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If there is an error updating the technician.
  *
  @method updateTechnicianForTM
 */
export const updateTechnicianForTM = async (obj) => {
	return new Promise(async (resolve, reject) => {
		//["TechnicianID", "Notes", "FirstName", "LastName", "StartDate", "MobileNumber", "CompanyName", "Latitude", "Longitude", "Address", "City", "State", "Zip", "BirthDate", "PersonalEmail"]
		try {
			const statement = `UPDATE technicians
                SET
                TechnicianID = "${obj.TechnicianID}",
                Notes = "${obj.Notes}",
                FirstName = "${obj.FirstName}",
                LastName = '${obj.LastName}',
                StartDate = "${obj.StartDate}",
                MobileNumber = "${obj.MobileNumber}",
                CompanyName = "${obj.CompanyName}",
                Address = "${obj.Address}",
                City = "${obj.City}",
                State = "${obj.State}",
                Zip = "${obj.Zip}",
                BirthDate = "${obj.BirthDate}",
                PersonalEmail = '${obj.PersonalEmail}',
                PaperChanger = '${obj.PaperChanger ? 1 : 0}',
                EmailReminder = '${obj.EmailReminder ? 1 : 0}',
                Shipping = '${obj.Shipping}'
                WHERE TechnicianID = "${obj.TechnicianID}"
                `;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updateTechnicianForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

//Insert a Technician Info in MySQL
/**
 * Inserts a technician into the dispatch_console.technicians table.
 *
 * @param {Object} obj - The technician object to be inserted.
 * @param {string} obj.Notes - The notes for the technician.
 * @param {string} obj.FirstName - The first name of the technician.
 * @param {string} obj.LastName - The last name of the technician.
 * @param {string} obj.StartDate - The start date of the technician.
 * @param {string} obj.MobileNumber - The mobile number of the technician.
 * @param {string} obj.CompanyName - The company name of the technician.
 * @param {string} obj.Address - The address of the technician.
 * @param {string} obj.City - The city of the technician.
 * @param {string} obj.State - The state of the technician.
 * @param {string} obj.Zip - The zip code of the technician.
 * @param {string} obj.BirthDate - The birth date of the technician.
 * @param {string} obj.PersonalEmail - The personal email of the technician.
 * @param {boolean} obj.IsAvaliable - The availability status of the technician.
 * @param {boolean} obj.PaperChanger - The paper changer status of the technician.
 * @param {boolean} obj.EmailReminder - The email reminder status of the technician.
 * @param {string} obj.Shipping - The shipping information of the technician.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If there is an error while inserting the technician.
  *
  @method insertTechnicianForTM
 */
export const insertTechnicianForTM = async (obj) => {
	return new Promise(async (resolve, reject) => {
		//["TechnicianID", "Notes", "FirstName", "LastName", "StartDate", "MobileNumber", "CompanyName", "Latitude", "Longitude", "Address", "City", "State", "Zip", "BirthDate", "PersonalEmail"]
		try {
			const statement = `INSERT INTO dispatch_console.technicians (Notes,FirstName,LastName,StartDate,MobileNumber,CompanyName,Address,City,State,Zip,BirthDate,PersonalEmail,IsAvaliable,PaperChanger,Shipping,EmailReminder)
            VALUES("${obj.Notes}", '
            ${obj.FirstName}',
            '${obj.LastName}',
            '${obj.StartDate}',
            '${obj.MobileNumber}',
            '${obj.CompanyName}',
            '${obj.Address}',
            '${obj.City}',
            '${obj.State}',
            '${obj.Zip}',
            '${obj.BirthDate}',
            '${obj.PersonalEmail}',
            '${obj.IsAvaliable}',
            '${obj.PaperChanger ? 1 : 0}',
            '${obj.EmailReminder ? 1 : 0}',
            '${obj.Shipping}');`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.insertTechnicianForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Deletes a technician from all kiosks.
 * @param {number} TechnicianID - The ID of the technician to be deleted.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If an error occurs during the deletion process.
  *
  @method deleteTechnicianForTM
 */
//Deletes a Technician Info in MySQL
export const deleteTechnicianForTM = async (TechnicianID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `CALL RemoveTechFromAllKiosks(${TechnicianID})`; //remove tech from all kiosks
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.deleteTechnicianForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**TIME ENTRY**/
//Gets a List of information about each Technician
/**
 * Retrieves all records from the TimeEntry table in the dispatch_console database.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectFromTimeEntryForTM
 */
export const selectFromTimeEntryForTM = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT * FROM dispatch_console.TimeEntry;`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectFromTimeEntryForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

//Gets a List of information about each Technician
/**
 * Retrieves a list of technicians' names from the database.
 *
 * @returns - {Promise<Array>} A promise that resolves with an array of technician names.
 * @throws {Error} If there is an error retrieving the technician names.
  *
  @method selectFromTechniciansNameForTM
 */
export const selectFromTechniciansNameForTM = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT CONCAT(FirstName, ' ', LastName) AS names
            FROM technicians ORDER BY names;`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectFromTechniciansNameForTM: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Inserts latitude and longitude values into the database.
 * @param {Object} passedLatLongObject - The object containing the latitude and longitude values.
 * @param {string} passedLatLongObject.ForTable - The table name.
 * @param {string} passedLatLongObject.ID - The ID value.
 * @param {string} passedLatLongObject.Latitude - The latitude value.
 * @param {string} passedLatLongObject.Longitude - The longitude value.
 * @returns - {Promise} - A promise that resolves with the response from the database.
 * @throws {Error} - If there is an error inserting the latitude and longitude values.
  *
  @method insertLatLong
 */
//inserts Job Into PaperJobs
export const insertLatLong = async (passedLatLongObject) => {
	try {
		const statement = `CALL InsertLatLong(?,?,?,?)`;

		const params = [
			passedLatLongObject.ForTable,
			passedLatLongObject.ID,
			passedLatLongObject.Latitude,
			passedLatLongObject.Longitude,
		];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertLatLong: ${error} - ${new Date()}`);
		throw error;
	}
};

/**
 * Retrieves all alerts from the database based on the specified criteria.
 *
 * @param {string} prior - The prior date.
 * @param {string} today - The current date.
 * @returns - {Promise<object>} A promise that resolves with the response from the database query.
 * @throws {Error} If an error occurs while executing the database query.
  *
  @method selectAllAlerts
 */
export const selectAllAlerts = (prior, today) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT * FROM dispatch_console.kiosk_alerts
            WHERE KioskID IN(SELECT KioskID FROM dispatch_console.kiosk_alerts
                WHERE KioskStatus = 'Pending'
                AND left(KioskID,2) = 'WI'
                AND right(KioskID,1) > 0)
            AND date(OccuranceDateTimeStamp) BETWEEN curdate() - interval 1 year
            AND curdate()
            AND KioskIssueID in('73103', '73104', '73105', '73106', '73107', '73121', '73122')
            ORDER BY AlertID desc;`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAllAlerts: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves alerts with their descriptions.
 *
 * @param {number} days - The number of days to retrieve alerts for.
 * @returns - {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error retrieving the alerts.
  *
  @method getAlertsWithDesc
 */
export const getAlertsWithDesc = (days) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT a.*,
                j.JobID,
                i.Description,
                i.ToDoNext,
                JSON_EXTRACT(i.ToDoNext,concat("$.",a.StepAlertIsAt)) as last_step
            FROM dispatch_console.kiosk_alerts a
            JOIN dispatch_console.kiosk_issues i
            ON (a.KioskIssueID = i.KioskIssueID)
            LEFT JOIN dispatch_console.jobs j
            ON (a.AlertID = j.AlertID)
            WHERE left(a.KioskID,2) = 'WI'
            and DATE(a.OccuranceDateTimeStamp) BETWEEN CURDATE() - INTERVAL '${days}' DAY
            AND CURDATE()
            AND a.KioskIssueID IN ('73103' , '73104','73105','73106','73107','73121','73122')
            ORDER BY a.AlertID DESC;`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getAlertsWithDesc: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves all completed jobs from the tableManagementTB.
 *
 * @param {number} days - The number of days to consider for retrieving completed jobs.
 * @returns - {Promise<Array>} - A promise that resolves to an array of completed jobs.
 * @throws {Error} - If there is an error while retrieving the completed jobs.
  *
  @method selectAllCompletedJobs
 */
export const selectAllCompletedJobs = (days) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL dispatch_console.getAllCompletedJobs(${days});`;
			const response = await pool.query(action);
			resolve(response[0]);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAllCompletedJobs: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves all star paper counts from the star_paper_holder_table.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectAllStarPaperCounts
 */
export const selectAllStarPaperCounts = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT * FROM dispatch_console.star_paper_holder_table;`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAllStarPaper: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Inserts a star paper into the table management database.
 * @param {Object} paperObject - The paper object to be inserted.
 * @returns - {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If there is an error during the insertion process.
  *
  @method insertStarPaper
 */
export const insertStarPaper = async (paperObject) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statment = `CALL insertStarCounts('${paperObject}');`;
			const response = await pool.query(statment);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.insertStarPaper: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves all map drive times for a given tech.
 *
 * @param {string} tech - The ID of the tech.
 * @returns - {Promise} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectAllMapDriveTimesForTech
 */
//Get all drive times for the map
export const selectAllMapDriveTimesForTech = (tech) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT * FROM dispatch_console.mapDriveTime where TechID = '${tech}';`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAllMapDriveTimes: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves all map drive times from the dispatch_console.mapDriveTime table.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectAllMapDriveTimes
 */
export const selectAllMapDriveTimes = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `WITH RankedEntries AS (
								SELECT
								DriveTimeID, 
								KioskID, 
								TechID, 
								TechName, 
								TechAddress, 
								DriveTime,           
								ROW_NUMBER() OVER (PARTITION BY KioskID, TechName ORDER BY DriveTimeID desc) as rn
								FROM mapDriveTime m

								LEFT JOIN technicians t ON t.FullName = m.TechName
								WHERE t.Active = 1
								)
								SELECT
								* from RankedEntries

								WHERE rn = 1
								GROUP BY KioskID, TechName
								;`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAllMapDriveTimes: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Fetches call logs based on the specified log type and date.
 * @param {string} log_type - The type of call log to fetch.
 * @param {string} date - The date for which to fetch call logs.
 * @returns - {Promise<any>} - A promise that resolves with the fetched call logs.
 * @throws {Error} - If an error occurs while fetching the call logs.
  *
  @method fetchCallLogs
 */
export const fetchCallLogs = (log_type, date) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `CALL fetchCallLogs('${log_type}', '${date}');`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.fetchCallLogs: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Inserts a new star paper into the star_paper_holder_table.
 *
 * @param {Object} StarPaperObject - The star paper object to be inserted.
 * @param {string} StarPaperObject.KioskID - The ID of the kiosk.
 * @param {number} StarPaperObject.StarPaperCount - The count of star paper.
 * @param {string} StarPaperObject.TodaysDate - The date of the update.
 * @param {string} StarPaperObject.KioskID_Loc - The location of the kiosk.
 * @returns - {Promise} A promise that resolves with the response from the database or rejects with an error.
  * @throws {Error} If there is an error during the insertion process.
  *
  @method insertNewStarPaper
 */
//Sets a new job into the Database
export const insertNewStarPaper = async (StarPaperObject) => {
	try {
		const statement = `INSERT INTO dispatch_console.star_paper_holder_table (KioskID, StarPaperCount, DateUpdated, KioskID_Loc) VALUES (?, ?, ?, ?);`;

		const params = [
			StarPaperObject.KioskID,
			StarPaperObject.StarPaperCount,
			StarPaperObject.TodaysDate,
			StarPaperObject.KioskID_Loc,
		];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertStarPaperKiosk: ${error} - ${new Date()}`);
		throw error;
	}
};

/**
 * Retrieves all paper jobs from the dispatch_console.paper_jobs table.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectAllPaperJobs
 */
export const selectAllPaperJobs = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `SELECT * FROM dispatch_console.paper_jobs;`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAllPaperJobs: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves map analytics data for a given number of days.
 * @param {number} days - The number of days to retrieve analytics data for.
 * @returns - {Promise<any>} - A promise that resolves with the analytics data.
 * @throws {Error} - If there is an error retrieving the analytics data.
  *
  @method getMapAnalytics
 */
export const getMapAnalytics = async (days) => {
	try {
		const action = `call dispatch_console.getMapAnalytics(?);`;
		const params = [days];
		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getMapAnalytics: ${error} - ${new Date()}`);
		throw error;
	}
};
/**
 * Retrieves map marker information.
 * @param {string} id - The ID of the marker.
 * @param {string} type - The type of the marker.
 * @returns - {Promise<any>} A promise that resolves with the marker information.
 * @throws {Error} If an error occurs while retrieving the marker information.
  *
  @method getMapMarkerInfo
 */

export const getMapMarkerInfo = async (id, type) => {
	try {
		const action = `call dispatch_console.getMapMarkerInfo('${id}','${type}');`;
		const params = [id, type];
		const response = await pool.query(action, params);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getMapMarkerInfo: ${error} - ${new Date()}`);
		throw new Error('Unable to retrieve map marker information');
	}
};

/**
 * Retrieves heat map data based on the specified number of days and data type.
 * @param {number} days - The number of days to retrieve data for.
 * @param {string} data_type - The type of data to retrieve.
 * @returns - {Promise<any>} - A promise that resolves with the retrieved heat map data.
 * @throws {Error} - If an error occurs while retrieving the heat map data.
  *
  @method getHeatMapData
 */
export const getHeatMapData = async (days, data_type) => {
	try {
		const action = `call dispatch_console.getHeatMapData(${days},'${data_type}');`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getHeatMapData: ${error} - ${new Date()}`);
		throw error;
	}
};

/**
 * Retrieves all paper jobs for ROC from the database.
 * @async
 * @returns - {Promise<Array>} A promise that resolves to an array of paper jobs.
 * @throws {Error} If there is an error retrieving the paper jobs.
  *
  @method selectAllPaperForROC
 */
export const selectAllPaperForROC = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `SELECT dispatch_console.paper_jobs.ID,
                dispatch_console.paper_jobs.PaperJobID,
                dispatch_console.paper_jobs.KiosksString,
                dispatch_console.paper_jobs.Kiosks,
                dispatch_console.paper_jobs.JobStatus,
                dispatch_console.paper_jobs.CreatedDate,
                dispatch_console.paper_jobs.TempTechnician
            FROM dispatch_console.paper_jobs;
            `;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectAllPaperJobs: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Selects a paper job from the dispatch_console.paper_jobs table based on the provided job ID.
 * @param {string} jobID - The ID of the paper job to select.
 * @returns - {Promise<object>} - A promise that resolves with the response from the database query.
 * @throws {Error} - If there is an error executing the database query.
  *
  @method selectPaperJob
 */
export const selectPaperJob = async (jobID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `SELECT * FROM dispatch_console.paper_jobs WHERE PaperJobID = '${jobID}';`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectPaperJob: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Updates the pending paper job in the dispatch_console.paper_jobs table.
 *
 * @param {string} _kiosks - The kiosks string to update.
 * @param {string} _jobs - The jobs string to update.
 * @param {number} _id - The ID of the job to update.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If an error occurs while updating the job.
  *
  @method updatePendingPaperJob
 */
export const updatePendingPaperJob = (_kiosks, _jobs, _id) => {
	return new Promise(async (resolve, reject) => {
		try {
			const statement = `update dispatch_console.paper_jobs set KiosksString = '${_kiosks}', Kiosks = '${_jobs}' where ID = ${_id}`;
			const response = await pool.query(statement);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.updatePendingPaperJob: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Updates the repurpose count of a specific inventory bin.
 *
 * @param {number} ID - The ID of the inventory bin.
 * @param {number} count - The new repurpose count.
 * @returns - {Promise<any>} - A promise that resolves with the response from the database.
 * @throws {Error} - If an error occurs during the update process.
  *
  @method updateRepurposeCount
 */
// Updates the respective row of the SQL with the new number that was changed on the sticker-counter page
export const updateRepurposeCount = async (ID, count) => {
	try {
		const action = `UPDATE dispatch_console.inventory_bins
            SET qty = ?
            WHERE id = ?;`;
		const params = [ID, count];
		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateRepurposeCount: ${error} - ${new Date()}`);
		throw error;
	}
};

/**
 * Retrieves the repurpose count for the current year, next year, and future year.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error retrieving the repurpose count.
  *
  @method getRepurposeCount
 */
// Gets current repurposed sticker counts and the ID of that row in the SQL DB
export const getRepurposeCount = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const currentYear = new Date().getFullYear();
			const nextYear = currentYear + 1;
			const futureYear = currentYear + 2;
			const action = `SELECT
            DISTINCT (SELECT qty FROM dispatch_console.inventory_bins WHERE name = '${currentYear} Repurposed Stickers' ) AS '${currentYear} Repurposed Stickers',
            (SELECT qty FROM dispatch_console.inventory_bins WHERE name = '${nextYear} Repurposed Stickers') AS '${nextYear} Repurposed Stickers',
            (SELECT qty FROM dispatch_console.inventory_bins WHERE name = '${futureYear} Repurposed Stickers')  AS '${futureYear} Repurposed Stickers',
            (SELECT id FROM dispatch_console.inventory_bins WHERE name = '${currentYear} Repurposed Stickers' ) AS '${currentYear} ID',
            (SELECT id FROM dispatch_console.inventory_bins WHERE name = '${nextYear} Repurposed Stickers') AS '${nextYear} ID',
            (SELECT id FROM dispatch_console.inventory_bins WHERE name = '${futureYear} Repurposed Stickers')  AS '${futureYear} ID'
            FROM dispatch_console.inventory_bins;`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getRepurposeCount ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Saves the error, dateTimeID, and errorMessage to the database.
 *
 * @param {Error} error - The error object.
 * @param {string} dateTimeID - The ID of the date and time.
 * @param {string} errorMessage - The error message.
 * @returns - {Promise} A promise that resolves with the response from the server.
 * @throws {Error} If an error occurs while saving to the database.
  *
  @method saveToDatabase
 */
// save to database function
export const saveToDatabase = (error, dateTimeID, errorMessage) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axios.post('/api/database_error_logging');
			resolve(response);
		} catch (error) {
			console.error('Error', error);
			reject(error);
		}
	});
};

/** * CREATED_DATE: 24JUN14 * @returns paper upload report */
export const downloadPaperUploadReport = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL downloadPaperUploadReport();`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.downloadPaperUploadReport: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/** * CREATED_DATE: 24JUN20 * @returns kmlReport */
export const getKmlReport = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `select * from kmlReport;`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.getKmlReport: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Retrieves the options for selecting call logs.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
  *
  @method selectCallLogOptions
 */
export const selectCallLogOptions = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const action = `CALL selectCallLogOptions();`;
			const response = await pool.query(action);
			resolve(response);
		} catch (error) {
			console.error(`ERROR: tableManagementTB.selectCallLogOptions: ${error} - ${new Date()}`);
			reject(error);
		}
	});
};

/**
 * Logs an error to the production error log.
 *
 * @param {string} fileNameID - The ID of the file where the error occurred.
 * @param {string} functionNameID - The ID of the function where the error occurred.
 * @param {string} timestampID - The timestamp when the error occurred.
 * @param {string} errorMessage - The error message.
 * @param {string} stackTrace - The stack trace of the error.
 * @param {string} Priority - The priority level of the error.
 * @param {string} Severity - The severity level of the error.
 * @returns {Promise<Object>} The response from the database query.
 * @throws Will throw an error if the database query fails.
 */
export const logError = async (
	fileNameID,
	functionNameID,
	timestampID,
	errorMessage,
	stackTrace,
	Priority,
	Severity
) => {
	try {
		const response = await pool.query('INSERT INTO productionErrorLog SET ?', [
			{ fileNameID, functionNameID, timestampID, errorMessage, stackTrace, Priority, Severity },
		]);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

// 55 total queries to convert into stored procedures
// 45 queries already converted into stored procedures
