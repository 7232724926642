/* global $ */
import React from 'react';
import axios from 'axios';
import { CurrentTime } from '../API/Moment';
import { FormatDateTime, GetTodaysDate } from '../API/TextFormatingFunctions.js';
// dummy comment to test git hub action for docs and linting
async function getTechNumber(techName) {
	return new Promise((resolve, reject) => {
		socket.emit('getTechNumber', techName, function (result) {
			if (result) {
				resolve(result);
			} else {
				reject('No result found');
			}
		});
	});
}
export default class ServiceReport extends React.Component {
	constructor(props) {
		super(props);

		const date = new Date();
		let hours = new Array(24);
		for (let i = 0; i < hours.length; i++) {
			hours[i] = i;
		}

		let mins = new Array(60);
		for (let i = 0; i < mins.length; i++) {
			mins[i] = i;
		}

		this.state = {
			OpenJobs: null,
			kioskServerJobID: null,
			KiosksServerID: null,
			JobIDs: null,
			ServiceDate: GetTodaysDate(new Date()),
			unrepeatedTechNames: [],
			DateMonth: date.getMonth() + 1,
			DateDay: date.getDate(),
			DateYear: date.getFullYear(),
			workTime: 0,
			driveTime: 10,
			lists: [],
			filteredPaperJobs: 0,
			KiosksDB: [],
			PhoneNumbers: [],
			KioskID: '',
			PaperToComplete: [],
			JobSelected: '',
			TechSelected: '',
			KioskSelected: '',
			PaperSelected: '',
			newFeature: false,
			Hours: hours,
			Mins: mins,
		};
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('keydown', this.handleKeyDown.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown.bind(this));
	}

	componentDidMount() {
		this.getTechs();
		socket.emit(
			'selectMobileEmailLists',
			function (result) {
				this.setState({
					lists: result.filter((list) => list.ListName == 'Service Report')[0],
				});
			}.bind(this)
		);

		socket.emit(
			'selectAllPhoneNumbers',
			function (result) {
				this.setState({ PhoneNumbers: result });
			}.bind(this)
		);

		socket.emit(
			'selectFromKiosksForTM',
			function (result) {
				this.setState({ KiosksDB: result });
			}.bind(this)
		);
	}

	handleKeyDown(e) {
		const focus = document.activeElement.id;
		switch (e.keyCode) {
			case 40: //Down Arrow
			case 37: //Left Arrow
				if (focus === 'WorkTimeSelect' || focus === 'DriveTimeSelect') {
					//e.preventDefault();
					this.SubtractTime(focus, this);
				}
				break;

			case 38: //Up Arrow
			case 39: //Right Arrow
				if (focus === 'WorkTimeSelect' || focus === 'DriveTimeSelect') {
					//e.preventDefault();
					this.AddTime(focus, this);
				}

				break;
		}
	}

	//Get Tech Names, Job IDS assigned to them, and Kiosk Ids
	getTechs() {
		socket.emit(
			'selectFromJobsWhereAssignedForServiceReport',
			function (result) {
				this.setState({ OpenJobs: result });
			}.bind(this)
		);
	}

	/**
	 * Filters the technician's kiosks from the list of open jobs.
	 *
	 * @param {string} tech - The name of the technician.
	 * @returns - {Array} - An array of filtered kiosks.
	 */
	FilterTechKiosks(tech) {
		let jobs = this.state.OpenJobs.filter((e) => e.TechnicianName == tech);
		let kiosks = [];
		for (let i = 0; i < jobs.length; i++) {
			// check if this is a paper job
			let temp =
				jobs[i].Issue == 'Paper Change' && jobs[i].JobID[0] == 'P' ? JSON.parse(jobs[i].KioskID) : jobs[i].KioskID;
			if (jobs[i].Issue == 'Paper Change' && jobs[i].JobID[0] == 'P') {
				// is a paper job
				temp
					.filter((e) => e.KioskStatus == 'Open')
					.forEach((e) => {
						kiosks.push({
							JobID: jobs[i].JobID,
							Issue: jobs[i].Issue,
							Jobs: JSON.parse(jobs[i].KioskID),
							KioskID: e.KioskID,
							AlertStatus: e.AlertStatus,
							ServerID: e.ServerID,
							KioskStatus: e.KioskStatus,
						});
					});
			} else {
				// is a regular job
				kiosks.push({
					JobID: jobs[i].JobID,
					Issue: jobs[i].Issue,
					Jobs: [],
					KioskID: jobs[i].KioskID,
					AlertStatus: jobs[i].AlertStatus,
					ServerID: jobs[i].ServerID,
					KioskStatus: jobs[i].KioskStatus,
				});
			}
		}

		return kiosks;
	}

	/**
	 * Filters the tech jobs based on the selected kiosk ID.
	 *
	 * @returns - {Array} The filtered tech jobs.
	 */
	FilterTechJobs() {
		let kiosk_selected = document.getElementById('KioskIDSelect').value.split('/')[0].trim();
		let jobs = this.state.KiosksServerID.filter((e) => e.KioskID == kiosk_selected);
		return jobs;
	}

	/**
	 * Handles the change event for the specified value.
	 *
	 * @param {string} whichValue - The value to handle the change event for.
	 * @param {Event} e - The event object.
	 */
	onChange(whichValue, e) {
		switch (whichValue) {
			case 'ServiceDate':
				this.setState({ ServiceDate: e.target.value });
				break;
			case 'TechName': {
				let kiosk_list = this.FilterTechKiosks($('#TechSelect :selected').text());
				const _tech = document.getElementById('TechSelect').value;

				this.setState({
					KiosksServerID: kiosk_list,
					AlertStatus: null,
					KioskSelected: '',
					TechSelected: _tech,
					// newFeature: _tech == 'Bill Wanta' ? true : false,
					newFeature: _tech === 'Bill Wanta' || _tech === 'Jackson Kupris' ? true : false,
				});
				document.getElementById('JobIDSelect').value = '';
				document.getElementById('KioskIDSelect').value = '';
				break;
			}
			case 'JobID': {
				let jobObject = JSON.parse(e.target.options[e.target.selectedIndex].id);

				if (document.getElementById('PaperChangeSelect')) {
					document.getElementById('PaperChangeSelect').value = '';
				}

				this.setState({
					KioskID: jobObject.KioskID,
					PaperToComplete: this.GetPaperToComplete(jobObject.KioskID),
					AlertStatus: jobObject.AlertStatus,
				});

				if (jobObject.AlertStatus == 'Pending') {
					document.getElementById('SaveAndSendServiceReportBtn').disabled = true;
					alert(
						'This Jobs issue has not cleared on our end yet. ' +
							'Try rebooting the kiosk and waiting up to 5 minutes. ' +
							'You should receive a text message confirming the issue has cleared, so you do not need to refresh this page constantly to check. ' +
							'If you do not receive a message within 5 minutes, please call tech support at (763) 225-1171.'
					);
				} else {
					document.getElementById('SaveAndSendServiceReportBtn').disabled = false;
				}
				break;
			}
			case 'KioskID': {
				let kioskID = e.target.options[e.target.selectedIndex].id.split('-')[1];
				let job_list = this.FilterTechJobs();

				if (document.getElementById('JobIDSelect')) {
					document.getElementById('JobIDSelect').value = '';
				}

				if (document.getElementById('PaperChangeSelect')) {
					document.getElementById('PaperChangeSelect').value = '';
				}

				this.setState({
					filteredPaperJobs: this.CheckPaperJobExists(job_list),
					KioskID: kioskID,
					kioskServerJobID: job_list,
				});

				break;
			}
			case 'DateMonth':
				this.setState({ DateMonth: e.target.value });
				break;
			case 'DateDay':
				this.setState({ DateDay: e.target.value });
				break;
			case 'DateYear':
				this.setState({ DateYear: e.target.value });
				break;
		}
	}

	/**
	 * Checks if there are any paper jobs in the given job list.
	 *
	 * @param {Array} job_list - The list of jobs to check.
	 * @returns - {number} - The number of paper jobs found.
	 */
	CheckPaperJobExists(job_list) {
		return job_list.filter((e) => e.JobID[0] == 'P').length;
	}

	/**
	 * Retrieves the paper types that are yet to be completed for a given kiosk ID.
	 *
	 * @param {string} kiosk_id - The ID of the kiosk.
	 * @returns - {Array} - An array containing the paper types that are yet to be completed.
	 */
	GetPaperToComplete(kiosk_id) {
		let paper = {};

		let paper_jobs = this.state.kioskServerJobID.filter((e) => e.KioskID == kiosk_id && e.JobID[0] == 'P');
		if (paper_jobs.length > 0) {
			paper_jobs = paper_jobs[0].Jobs.filter((el) => el.KioskID == kiosk_id);
			let temp = paper_jobs.map((job) => {
				return job.Type.map((el) => {
					return {
						Completed: el.Completed,
						PaperType: el.PaperType,
					};
				});
			});

			temp[0].forEach((paper_type) => {
				if (paper_type.Completed != undefined && paper_type.Completed == false) {
					paper[paper_type.PaperType] = paper_type.PaperType;
				}
			});
		}

		return Object.keys(paper);
	}

	/**
	 * Generates an array of options for selecting a day or year.
	 *
	 * @param {string} DayOrYear - The type of options to generate ('Day' or 'Year').
	 * @param {number} todaysDayYear - The current day or year.
	 * @returns - {Array} - An array of options for selecting a day or year.
	 */
	getDayYear(DayOrYear, todaysDayYear) {
		const returnOptions = [];
		let maxLoops;
		let i = 0;

		if (DayOrYear === 'Day') {
			maxLoops = 31;
		} else {
			maxLoops = todaysDayYear + 1;
			i = todaysDayYear - 2;
		}
		for (i; i < maxLoops; i++) {
			returnOptions.push(
				<option key={i + 1} value={i + 1}>
					{i + 1}
				</option>
			);
		}
		return returnOptions;
	}

	/**
	 * Checks if a paper job exists and returns a JSX element representing a paper change select dropdown.
	 *
	 * @param {boolean} exists - Indicates whether a paper job exists.
	 * @returns - {JSX.Element|null} - The JSX element representing the paper change select dropdown, or null if no paper job exists.
	 */
	checkIfPaperJob(exists) {
		if (exists) {
			let options = [];
			if (this.state.PaperToComplete.length > 0) {
				// check to make sure that there is paper to change for this job
				this.state.PaperToComplete.forEach((paper_type, index) => {
					options.push(
						<option key={index + 2} value={paper_type}>
							{paper_type}
						</option>
					);
				});
			}

			return (
				<div>
					<p
						style={{
							fontSize: '16px',
							color: 'white',
							fontFamily: 'sans-serif',
							fontWeight: 'bold',
						}}
					>
						What paper did you change at this kiosk?
					</p>
					<select id='PaperChangeSelect' tabIndex='7'>
						<option key='0' value=''></option>
						<option key='1' value='All'>
							All Paper
						</option>
						<option key='2' value='None'>
							None
						</option>
						{options}
					</select>
				</div>
			);
		} else {
			return null;
		}
	}

	/**
	 * Renders the ServiceReportForm component.
	 *
	 * @returns - {JSX.Element} The rendered ServiceReportForm component.
	 */
	render() {
		let renderPage = this.state.OpenJobs ? (
			<div className='ServiceReportForm'>
				<h1>EZ-TAB Online Service Form</h1>
				<h4>
					Use Tab (next question) and Shift+Tab (previous question) to navigate the page. Use the arrows (up and down)
					to select the correct option. Use Enter/Space or your finger to press the &apos;Submit&apos; button. Use your
					keyboard or a stylus for easier use.
				</h4>
				<h3>Date</h3>
				<select
					id='DateMonth'
					value={this.state.DateMonth}
					tabIndex='1'
					onChange={this.onChange.bind(this, 'DateMonth')}
				>
					<option key='1' value='1'>
						January
					</option>
					<option key='2' value='2'>
						February
					</option>
					<option key='3' value='3'>
						March
					</option>
					<option key='4' value='4'>
						April
					</option>
					<option key='5' value='5'>
						May
					</option>
					<option key='6' value='6'>
						June
					</option>
					<option key='7' value='7'>
						July
					</option>
					<option key='8' value='8'>
						August
					</option>
					<option key='9' value='9'>
						September
					</option>
					<option key='10' value='10'>
						October
					</option>
					<option key='11' value='11'>
						November
					</option>
					<option key='12' value='12'>
						December
					</option>
				</select>
				<select id='DateDay' value={this.state.DateDay} tabIndex='2' onChange={this.onChange.bind(this, 'DateDay')}>
					{this.getDayYear('Day', new Date().getDate())}
				</select>
				<select id='DateYear' value={this.state.DateYear} tabIndex='3' onChange={this.onChange.bind(this, 'DateYear')}>
					{this.getDayYear('Year', new Date().getFullYear())}
				</select>
				{/*<input type="Date" id="ServiceDate" value={this.state.ServiceDate} onChange={this.onChange.bind(this, "ServiceDate")} />*/}
				<h3>Service Tech</h3>
				{this.RenderTechnicianName(this.state.OpenJobs)}
				<h3>Kiosk ID and Server Name</h3>
				{this.RenderKioskIDServerID(this.state.KiosksServerID)}
				<h3>Job ID</h3>
				{this.RenderJobID(this.state.kioskServerJobID)}
				{this.checkIfPaperJob(this.state.filteredPaperJobs)}
				<h3>Kiosk Issue</h3>
				<select id='IssueSelect' tabIndex='8'>
					<option key='0' value=''></option>
					<option key='1' value='Datamax - PAPER JAM'>
						Datamax - PAPER JAM
					</option>
					<option key='2' value='Datamax - FAULTED NOT JAMMED'>
						Datamax - FAULTED NOT JAMMED
					</option>
					<option key='3' value='Star - PAPER JAM'>
						Star - PAPER JAM
					</option>
					<option key='4' value='Star - OTHER ISSUE'>
						Star - OTHER ISSUE
					</option>
					<option key='5' value='Internet - MODEM ISSUE'>
						Internet - MODEM ISSUE
					</option>
					<option key='6' value='Internet - KIOSK ISSUE'>
						Internet - KIOSK ISSUE
					</option>
					<option key='7' value='Power Outage'>
						Power Outage
					</option>
					<option key='8' value='Paper Change - LOW'>
						Paper Change - LOW
					</option>
					<option key='9' value='Paper Change - RAN OUT'>
						Paper Change - RAN OUT
					</option>
					<option key='10' value='Paper Change - NEW YEAR'>
						Paper Change - NEW YEAR
					</option>
					<option key='11' value='Other Issue - Kiosk'>
						Other Issue - Kiosk
					</option>
					<option key='12' value='Re-Image'>
						Re-Image
					</option>
					<option key='13' value='Credit Card Reader'>
						Credit Card Reader
					</option>
					<option key='14' value='Scanner'>
						Scanner
					</option>
					<option key='15' value='Touch Monitor'>
						Touch Monitor
					</option>
					<option key='16' value='Part Install'>
						Part Install
					</option>
					<option key='17' value='Kiosk Install'>
						Kiosk Install
					</option>
				</select>
				<h3>Part Installed</h3>
				<select id='part_installed' tabIndex='9'>
					<option key='0' value='None'>
						None
					</option>
					<option key='1' value='Cable(s)'>
						Cable(s)
					</option>
					<option key='2' value='Credit Card Reader'>
						Credit Card Reader
					</option>
					<option key='3' value='Harddrive'>
						Harddrive
					</option>
					<option key='4' value='Modem'>
						Modem
					</option>
					<option key='5' value='Monitor'>
						Monitor
					</option>
					<option key='6' value='Motherboard'>
						Motherboard
					</option>
					<option key='7' value='Multiple - See notes'>
						Multiple - See notes
					</option>
					<option key='8' value='Other - See notes'>
						Other - See notes
					</option>
					<option key='9' value='Pico Unit'>
						Pico Unit
					</option>
					<option key='10' value='Power Supply 5V'>
						Power Supply 5V
					</option>
					<option key='11' value='Power Supply 12V'>
						Power Supply 12V
					</option>
					<option key='12' value='Printer (Datamax)'>
						Printer (Datamax)
					</option>
					<option key='13' value='Printer (Star)'>
						Printer (Star)
					</option>
					<option key='14' value='Printer Power Supply'>
						Printer Power Supply
					</option>
					<option key='15' value='RAM Stick'>
						RAM Stick
					</option>
					<option key='16' value='Ski Slope'>
						Ski Slope
					</option>
					<option key='17' value='USB Hub'>
						USB Hub
					</option>
				</select>
				<h3>Work Time At Store</h3>
				{/*<button onClick={this.AddTime.bind(this, "work")}>+</button> <button onClick={this.SubtractTime.bind(this, "work")}>-</button>*/}{' '}
				{/*this.RenderWorkTime(this)*/}
				{this.renderWorkTimeSelect()}
				<h3>Total Drive Time</h3>
				{/*<button onClick={this.AddTime.bind(this, "drive")}>+</button> <button onClick={this.SubtractTime.bind(this, "drive")}>-</button>*/}{' '}
				{/*this.RenderDriveTime(this)*/}
				{/*Drive Time Increments Every 10 Minutes*/}
				{this.renderDriveTimeSelect()}
				{this.renderAdditionalPrompt()}
				<h3>Datamax - Did you do any manual form feeds?</h3>
				<select id='HowManyFeedsSelect' tabIndex='12'>
					<option key='0' value=''></option>
					<option key='1' value='Yes, I did manual form feeds and updated the page counts.'>
						Yes, I did manual form feeds and updated the page counts.
					</option>
					<option key='2' value='No, I did not do any form feeds.'>
						No, I did not do any form feeds.
					</option>
				</select>
				<h3>Datamax 1 Test Prints</h3>
				<select id='DMOneTestPrintsSelect' tabIndex='13'>
					<option key='0' value=''></option>
					<option key='1' value='1+  Test Print is Required / 6+ for Paper Change or Jam'>
						1+ Test Print is Required / 6+ for Paper Change or Jam
					</option>
					<option key='2' value='6+  Test Prints'>
						6+ Test Prints
					</option>
					<option key='3' value='Internet Issue - Could not access Test Print page at time of service'>
						Internet Issue - Could not access Test Print page at time of service
					</option>
				</select>
				<h3>Datamax 2 Test Prints</h3>
				<select id='DMTwoTestPrintsSelect' tabIndex='14'>
					<option key='0' value=''></option>
					<option key='1' value='1+  Test Print is Required / 6+ for Paper Change or Jam'>
						1+ Test Print is Required / 6+ for Paper Change or Jam
					</option>
					<option key='2' value='6+  Test Prints'>
						6+ Test Prints
					</option>
					<option key='3' value='Internet Issue - Could not access Test Print page at time of service'>
						Internet Issue - Could not access Test Print page at time of service
					</option>
				</select>
				<h3>Star Test Prints</h3>
				<select id='StarTestPrintsSelect' tabIndex='15'>
					<option key='0' value=''></option>
					<option key='1' value='1+ COVR Test Print is Required / 6+ for Paper Change or Jam'>
						1+ COVR Test Print is Required / 6+ for Paper Change or Jam
					</option>
					<option key='2' value='3+ COVR Test Prints'>
						3+ COVR Test Prints
					</option>
					<option key='3' value='Internet Issue - Could not access Test Print page at time of service'>
						Internet Issue - Could not access Test Print page at time of service
					</option>
				</select>
				<h3>Did Star Printer have a physical jam?</h3>
				<select id='star-jam' tabIndex='16'>
					<option key='0' value=''></option>
					<option key='1' value='Yes'>
						Yes
					</option>
					<option key='2' value='No'>
						No
					</option>
				</select>
				<h3>How many Extra Rolls of Star Printer Paper are there in the Kiosk?</h3>
				<select id='ExtraStarRollsSelect' tabIndex='17'>
					<option key='0' value=''></option>
					<option key='1' value='0'>
						0
					</option>
					<option key='2' value='1'>
						1
					</option>
					<option key='3' value='2'>
						2
					</option>
					<option key='4' value='3'>
						3
					</option>
					<option key='5' value='4'>
						4
					</option>
					<option key='6' value='5'>
						5
					</option>
					<option key='7' value='6'>
						6
					</option>
					<option key='8' value='7'>
						7
					</option>
					<option key='9' value='8'>
						8
					</option>
					<option key='10' value='9'>
						9
					</option>
					<option key='11' value='10'>
						10
					</option>
					<option key='12' value='11'>
						11
					</option>
					<option key='13' value='12'>
						12
					</option>
					<option key='14' value='13'>
						13
					</option>
					<option key='15' value='14'>
						14
					</option>
					<option key='16' value='15'>
						15+ Fill out Notes section
					</option>
				</select>
				<h3>Sticky Rolls</h3>
				<select id='StickyRollsSelect' tabIndex='18'>
					<option key='0' value=''></option>
					<option key='1' value='Yes 200'>
						Yes, Unrolled and Re-Rolled 200 Sticker Forms
					</option>
					<option key='2' value='Yes 40'>
						Yes, Unrolled 40 Forms - Did not hear any sticking
					</option>
					<option key='3' value='No'>
						No, I did not check
					</option>
				</select>
				<h3>Did you update Modem Cell Carrier Info on the Maintenance Screen?</h3>
				<select id='UpdateModemSelect' tabIndex='19'>
					<option key='0' value=''></option>
					<option key='1' value='Yes'>
						Yes, I updated Modem Info
					</option>
					<option key='2' value='No'>
						No, I did not update Modem Info
					</option>
					<option key='3' value='UnKnown'>
						No, I do not know how to update Modem Info
					</option>
				</select>
				{this.RenderPhoneInput()}
				<div id='CarrierArea'></div>
				<h3>Did you update the page counts?</h3>
				<select id='UpdatePageCountSelect' tabIndex='20'>
					<option key='0' value=''></option>
					<option key='1' value='Yes'>
						Yes, I updated the page counts
					</option>
					<option key='2' value='No'>
						No, I did not update the page counts
					</option>
					<option key='3' value='UnKnown'>
						No, I do not know how to update the page counts
					</option>
				</select>
				<h3>Notes</h3>
				<h5 name='NotesNote'>If any of the following apply, please contact tech support at 763-225-1171.</h5>
				<h5 name='NotesNote'>*You do not see your Name above</h5>
				<h5 name='NotesNote'>*You see your Name but you do not see the Job ID above</h5>
				<h5 name='NotesNote'>*You experienced issues with the kiosk and would like the office to be aware</h5>
				<h5 name='NotesNote'>*You have any questions/suggestions regarding the new service log form</h5>
				<textarea className='NotesField' id='NotesField'></textarea>
				<p className='pTextArea' id='pTextArea'>
					{' '}
				</p>
				<br />
				<button
					className='SaveAndSendServiceReportBtn'
					id='SaveAndSendServiceReportBtn'
					onClick={this.SaveAndSendServiceReport.bind(this)}
				>
					Submit
				</button>
			</div>
		) : (
			<div>LOADING...</div>
		);

		return renderPage;
	}

	/**
	 * Renders the work time select component based on the state.
	 * If the newFeature state is true, it renders two select elements for hours and minutes.
	 * If the newFeature state is false, it renders a select element with predefined options for work time.
	 * @returns - {JSX.Element} The rendered work time select component.
	 */
	renderWorkTimeSelect() {
		if (this.state.newFeature) {
			return (
				<div>
					<select id='hour-work' tabIndex='10'>
						{this.state.Hours.map((hour) => (
							<option key={hour} value={hour}>
								{hour}
							</option>
						))}
					</select>
					<p className='small-text'>hour(s)</p>
					<select id='min-work' tabIndex='10'>
						{this.state.Mins.map((min) => (
							<option key={min} value={min}>
								{min}
							</option>
						))}
					</select>
					<p className='small-text'>min(s)</p>
				</div>
			);
		} else {
			return (
				<select id='WorkTimeSelect' tabIndex='10'>
					<option key='0' value=''></option>
					<option key='1' value='15 minutes'>
						15 minutes
					</option>
					<option key='2' value='30 minutes'>
						30 minutes
					</option>
					<option key='3' value='45 minutes'>
						45 minutes
					</option>
					<option key='4' value='1 hour'>
						1 hour
					</option>
					<option key='5' value='1 hour 15 minutes'>
						1 hour 15 minutes
					</option>
					<option key='6' value='1 hour 30 minutes'>
						1 hour 30 minutes
					</option>
					<option key='7' value='1 hour 45 minutes'>
						1 hour 45 minutes
					</option>
					<option key='8' value='2 hour'>
						2 hour
					</option>
					<option key='9' value='2 hour 15 minutes'>
						2 hour 15 minutes
					</option>
					<option key='10' value='2 hour 30 minutes'>
						2 hour 30 minutes
					</option>
					<option key='11' value='2 hour 45 minutes'>
						2 hour 45 minutes
					</option>
					<option key='12' value='3 hour'>
						3 hour
					</option>
					<option key='13' value='3+ hour(Fill out Notes section)'>
						3+ hour(Fill out Notes section)
					</option>
				</select>
			);
		}
	}

	/**
	 * Renders the drive time select component.
	 *
	 * @returns - {JSX.Element} The JSX element representing the drive time select component.
	 */
	renderDriveTimeSelect() {
		if (this.state.newFeature) {
			return (
				<div>
					<select id='hour-drive' tabIndex='11'>
						{this.state.Hours.map((hour) => (
							<option key={hour} value={hour}>
								{hour}
							</option>
						))}
					</select>
					<p className='small-text'>hour(s)</p>
					<select id='min-drive' tabIndex='11'>
						{this.state.Mins.map((min) => (
							<option key={min} value={min}>
								{min}
							</option>
						))}
					</select>
					<p className='small-text'>min(s)</p>
				</div>
			);
		} else {
			return (
				<select id='DriveTimeSelect' tabIndex='11'>
					<option key='0' value=''></option>
					<option key='1' value='10 Minutes (5 minutes One-Way)'>
						10 Minutes (5 minutes One-Way)
					</option>
					<option key='2' value='20 Minutes (10 minutes One-Way)'>
						20 Minutes (10 minutes One-Way)
					</option>
					<option key='3' value='30 Minutes (15 minutes One-Way)'>
						30 Minutes (15 minutes One-Way)
					</option>
					<option key='4' value='40 Minutes (20 minutes One-Way)'>
						40 Minutes (20 minutes One-Way)
					</option>
					<option key='5' value='50 Minutes (25 minutes One-Way)'>
						50 Minutes (25 minutes One-Way)
					</option>
					<option key='6' value='60 Minutes (30 minutes One-Way)'>
						60 Minutes (30 minutes One-Way)
					</option>
					<option key='7' value='70 Minutes (35 minutes One-Way)'>
						70 Minutes (35 minutes One-Way)
					</option>
					<option key='8' value='80 Minutes (40 minutes One-Way)'>
						80 Minutes (40 minutes One-Way)
					</option>
					<option key='9' value='90 Minutes (45 minutes One-Way)'>
						90 Minutes (45 minutes One-Way)
					</option>
					<option key='10' value='100 Minutes (50 minutes One-Way)'>
						100 Minutes (50 minutes One-Way)
					</option>
					<option key='11' value='110 Minutes (55 minutes One-Way)'>
						110 Minutes (55 minutes One-Way)
					</option>
					<option key='12' value='120 Minutes (60 minutes One-Way)'>
						120 Minutes (60 minutes One-Way)
					</option>
					<option key='13' value='120+ Minutes (Fill out Notes section)'>
						120+ Minutes (Fill out Notes section)
					</option>
					<option key='14' value='Multi-Trip'>
						Multi-Trip
					</option>
				</select>
			);
		}
	}

	/**
	 * Renders additional prompts based on the state of the component.
	 *
	 * @returns - {JSX.Element|null} The additional prompts as JSX elements or null if the newFeature state is false.
	 */
	renderAdditionalPrompt() {
		if (this.state.newFeature) {
			return (
				<div>
					<h3>Performed Regular Cleaning / Cable Management</h3>
					<select id='cleaning' tabIndex='11'>
						<option key='0' value=''></option>
						<option key='1' value='true'>
							Yes
						</option>
						<option key='2' value='false'>
							No
						</option>
					</select>

					<h3>Datamax - Paper Bridge Installed?</h3>
					<select id='paper-bridge' tabIndex='11'>
						<option key='0' value=''></option>
						<option key='1' value='true'>
							Yes
						</option>
						<option key='2' value='false'>
							No
						</option>
						<option key='3' value='true'>
							Already Installed
						</option>
					</select>

					<h3>Reset Switch Installed?</h3>
					<select id='reset-switch' tabIndex='11'>
						<option key='0' value=''></option>
						<option key='1' value='true'>
							Yes
						</option>
						<option key='2' value='false'>
							No
						</option>
						<option key='3' value='true'>
							Already Installed
						</option>
					</select>

					<h3>DM1 and DM2 Tape Guides in Place?</h3>
					<select id='tape-guides' tabIndex='11'>
						<option key='0' value=''></option>
						<option key='1' value='none'>
							Neither
						</option>
						<option key='2' value='dm1'>
							Only DM1
						</option>
						<option key='3' value='dm2'>
							Only DM2
						</option>
						<option key='4' value='both'>
							Both DM1 and DM2
						</option>
					</select>

					<h3>Performed Star Printer Firmware Update?</h3>
					<select id='star-firmware' tabIndex='11'>
						<option key='0' value=''></option>
						<option key='1' value='false'>
							No
						</option>
						<option key='2' value='false'>
							Already Updated
						</option>
						<option key='3' value='true'>
							Yes
						</option>
					</select>

					<h3>Performed Ski Slope Installation?</h3>
					<select id='ski-slope' tabIndex='12'>
						<option key='0' value=''></option>
						<option key='1' value='false'>
							No
						</option>
						<option key='2' value='false'>
							Already Installed
						</option>
						<option key='3' value='true'>
							Yes
						</option>
					</select>

					<h3>Performed OS Installation?</h3>
					<select id='os-installed' tabIndex='12'>
						<option key='0' value=''></option>
						<option key='1' value='false'>
							No
						</option>
						<option key='2' value='false'>
							Already Installed
						</option>
						<option key='3' value='true'>
							Yes
						</option>
					</select>
				</div>
			);
		} else {
			return null;
		}
	}

	/**
	 * Renders the phone input area based on the state of the component.
	 * If the inputs already exist, they are removed to prevent screen clutter.
	 * If the kiosk is not null and the kiosk ID matches a valid phone in the kiosks database,
	 * a phone number input area and a carrier input area are created and appended to the CarrierArea element.
	 */
	RenderPhoneInput() {
		let kiosks = this.state.KiosksDB;

		let kiosk = this.state.KiosksServerID;
		let kioskID = this.state.KioskID;

		// if inputs already exist, remove them so that it doesn't fill up the screen
		if (document.getElementById('PhoneInput') != null) {
			let temp = document.getElementById('PhoneInput');
			document.getElementById('CarrierArea').removeChild(temp);
			let temp2 = document.getElementById('PhonePrompt');
			document.getElementById('CarrierArea').removeChild(temp2);
		}

		if (document.getElementById('CarrierInput') != null) {
			let temp = document.getElementById('CarrierInput');
			document.getElementById('CarrierArea').removeChild(temp);
			let temp2 = document.getElementById('CarrierPrompt');
			document.getElementById('CarrierArea').removeChild(temp2);
		}

		if (kiosk != null) {
			for (let i = 0; i < kiosks.length; i++) {
				if (kioskID == kiosks[i].KioskID && kiosks[i].ValidPhone == 'No') {
					const phoneHeader = document.createElement('h3');
					phoneHeader.innerHTML = 'Phone Number (required)';
					phoneHeader.id = 'PhonePrompt';

					const phoneInputArea = document.createElement('TEXTAREA');
					phoneInputArea.id = 'PhoneInput';
					phoneInputArea.className = 'PhoneInput';
					phoneInputArea.style.height = '20px';
					phoneInputArea.style.width = '200px';

					const carrierHeader = document.createElement('h3');
					carrierHeader.innerHTML = 'Carrier';
					carrierHeader.id = 'CarrierPrompt';

					const carrierInputArea = document.createElement('TEXTAREA');
					carrierInputArea.id = 'CarrierInput';
					carrierInputArea.className = 'CarrierInput';
					carrierInputArea.style.height = '20px';
					carrierInputArea.style.width = '200px';

					document.getElementById('CarrierArea').appendChild(phoneHeader);
					document.getElementById('CarrierArea').appendChild(phoneInputArea);
					document.getElementById('CarrierArea').appendChild(carrierHeader);
					document.getElementById('CarrierArea').appendChild(carrierInputArea);
				}
			}
		}
	}

	/**
	 * Renders the technician names in a select dropdown.
	 *
	 * @param {Array} passedOpenJobsArray - The array of open jobs.
	 * @returns - {JSX.Element} - The select dropdown element.
	 */
	RenderTechnicianName(passedOpenJobsArray) {
		passedOpenJobsArray.push({
			TechnicianName: 'Fill out Notes section',
			JobID: 'Fill out Notes section',
			KioskID: 'Fill out Notes section',
			ServerID: '',
			KioskStatus: 'Fill out Notes section',
			Issue: '',
		});

		const techNamesHTML = [];
		const unrepeatedTechNames = [];
		let noRepeats = null;
		let kioskID = [];
		let passedArray = null;
		techNamesHTML.push(<option key='0' id='' value=''></option>);

		if (passedOpenJobsArray[0].KioskID[0] == '[') {
			passedArray = JSON.parse(passedOpenJobsArray[0].KioskID);
			for (let j = 0; j < passedArray.length; j++) {
				if (passedArray[j].KioskStatus == 'Open') {
					kioskID.push({
						KioskID: passedArray[j].KioskID,
						ServerID: passedArray[j].ServerID,
						KioskStatus: passedArray[j].KioskStatus,
					});
				}
			}
		} else {
			kioskID.push({
				KioskID: passedOpenJobsArray[0].KioskID,
				ServerID: passedOpenJobsArray[0].ServerID,
				KioskStatus: 'Open',
			});
		}

		//Store First Tehnician with jobs
		unrepeatedTechNames.push({
			TechName: passedOpenJobsArray[0].TechnicianName,
			JobKioskID: [
				{
					JobID: passedOpenJobsArray[0].JobID,
					KioskID: JSON.stringify(kioskID),
					AlertStatus: passedOpenJobsArray[0].KioskStatus,
					Issue: passedOpenJobsArray[0].Issue,
					ServerID: passedOpenJobsArray[0].ServerID,
				},
			],
		});
		//Store the Rest of the Technician names with Jobs
		for (let i = 1; i < Object.keys(passedOpenJobsArray).length; i++) {
			noRepeats = true;
			kioskID = [];
			if (passedOpenJobsArray[i].KioskID[0] == '[') {
				passedArray = JSON.parse(passedOpenJobsArray[i].KioskID);
				for (let j = 0; j < passedArray.length; j++) {
					if (passedArray[j].KioskStatus == 'Open') {
						kioskID.push({
							KioskID: passedArray[j].KioskID,
							ServerID: passedArray[j].ServerID,
							KioskStatus: passedArray[j].KioskStatus,
						});
					}
				}
			} else {
				kioskID.push({
					KioskID: passedOpenJobsArray[i].KioskID,
					ServerID: passedOpenJobsArray[i].ServerID,
					KioskStatus: 'Open',
				});
			}
			for (let j = 0; j < unrepeatedTechNames.length; j++) {
				if (passedOpenJobsArray[i].TechnicianName == unrepeatedTechNames[j].TechName) {
					//Add JOBID, KioskID/ServerID to Object Array
					noRepeats = false;
					unrepeatedTechNames[j].JobKioskID.push({
						JobID: passedOpenJobsArray[i].JobID,
						KioskID: JSON.stringify(kioskID),
						AlertStatus: passedOpenJobsArray[i].KioskStatus,
						Issue: passedOpenJobsArray[i].Issue,
						ServerID: passedOpenJobsArray[i].ServerID,
					});
				}
			}
			if (noRepeats) {
				unrepeatedTechNames.push({
					TechName: passedOpenJobsArray[i].TechnicianName,
					JobKioskID: [
						{
							JobID: passedOpenJobsArray[i].JobID,
							KioskID: JSON.stringify(kioskID),
							AlertStatus: passedOpenJobsArray[i].KioskStatus,
							Issue: passedOpenJobsArray[i].Issue,
							ServerID: passedOpenJobsArray[i].ServerID,
						},
					],
				});
			}
		}

		for (let k = 0; k < Object.keys(unrepeatedTechNames).length; k++) {
			techNamesHTML.push(
				<option
					key={k + 1}
					id={JSON.stringify(unrepeatedTechNames[k].JobKioskID)}
					value={unrepeatedTechNames[k].TechName}
				>
					{unrepeatedTechNames[k].TechName}
				</option>
			);
		}

		return (
			<select id='TechSelect' tabIndex='4' onChange={this.onChange.bind(this, 'TechName')}>
				{techNamesHTML}
			</select>
		);
	}

	/**
	 * Renders the job ID select element with options based on the passed job IDs.
	 *
	 * @param {Array} passedJobIDs - The array of job IDs to populate the select element with options.
	 * @returns - {JSX.Element} The rendered select element with options.
	 */
	RenderJobID(passedJobIDs) {
		const jobIDHTML = [];
		jobIDHTML.push(<option key='0' id='' value=''></option>);
		if (passedJobIDs) {
			for (let k = 0; k < passedJobIDs.length; k++) {
				jobIDHTML.push(
					<option
						key={k + 1}
						id={
							'{"KioskID": "' +
							passedJobIDs[k].KioskID +
							'", "AlertStatus": "' +
							passedJobIDs[k].AlertStatus +
							'", "Issue": "' +
							passedJobIDs[k].Issue +
							'"}'
						}
						value={passedJobIDs[k].JobID}
					>
						{`${passedJobIDs[k].JobID} - ${passedJobIDs[k].Issue}`}
					</option>
				);
			}
		}
		return (
			<select id='JobIDSelect' tabIndex='6' onChange={this.onChange.bind(this, 'JobID')}>
				{jobIDHTML}
			</select>
		);
	}

	/**
	 * Renders the paper jobs as options in a select element.
	 *
	 * @param {Array} passedPaperJobs - The array of paper jobs to render.
	 * @returns - {JSX.Element} - The select element with the paper jobs as options.
	 */
	RenderPaperJobs(passedPaperJobs) {
		const html = [];
		if (passedPaperJobs) {
			for (let i = 0; i < Object.keys(passedPaperJobs).length; i++) {
				html.push(
					<option id='PaperJobs' key={i} style={{ color: 'black' }} value={passedPaperJobs[i].JobID}>
						{passedPaperJobs[i].JobID}
					</option>
				);
			}
		}

		return (
			<select id='PaperJobIDSelect' tabIndex='6'>
				<option value='Select a Job'>Select a Job</option>
				{html}
			</select>
		);
	}

	/**
	 * Renders a select element with options for Kiosk ID and Server ID.
	 *
	 * @param {Array} passedKioskServerIDs - An array of objects containing Kiosk ID and Server ID.
	 * @returns - {JSX.Element} - The rendered select element.
	 */
	RenderKioskIDServerID(passedKioskServerIDs) {
		const kioskIDOptions = [];
		let kiosks = {};

		if (passedKioskServerIDs) {
			passedKioskServerIDs.map((e) => {
				kiosks[e.KioskID] = e;
			});
			passedKioskServerIDs = Object.values(kiosks);

			kioskIDOptions.push(<option key='0' id='' value=''></option>);

			for (let i = 0; i < passedKioskServerIDs.length; i++) {
				kioskIDOptions.push(
					<option key={i + 1} id={'KioskIDOptions-' + passedKioskServerIDs[i].KioskID}>
						{passedKioskServerIDs[i].KioskID + ' / ' + passedKioskServerIDs[i].ServerID}
					</option>
				);
			}
		}
		return (
			<select id='KioskIDSelect' tabIndex='5' onChange={this.onChange.bind(this, 'KioskID')}>
				{kioskIDOptions}
			</select>
		);
	}

	/**
	 * Renders the work time based on the state value.
	 *
	 * @returns - {JSX.Element} The JSX element representing the rendered work time.
	 */
	RenderWorkTime() {
		const workTime = this.state.workTime;
		let workTimeReturn = '';

		if (workTime > 0) {
			const minutes = workTime % 60;
			let hours = 0;
			if (workTime >= 60) {
				hours = (workTime - minutes) / 60;
			}

			if (hours > 0) {
				workTimeReturn += hours + ' Hour';
			}
			if (hours > 1) {
				workTimeReturn += 's ';
			} else {
				workTimeReturn += ' ';
			}
			if (minutes > 0) {
				workTimeReturn += minutes + ' Minutes ';
			}
			//1 hour 15 minutes
			//return workTimeReturn;
		} else {
			workTimeReturn += 'Multi-Trip';
		}
		return (
			<label className='WorkTimeSelect' id='WorkTimeSelect' tabIndex='8'>
				{workTimeReturn}
			</label>
		);
	}

	/**
	 * Renders the drive time and one-way drive time in hours and minutes.
	 *
	 * @returns - {JSX.Element} The JSX element containing the rendered drive time.
	 */
	RenderDriveTime() {
		const driveTime = this.state.driveTime;
		const oneWay = driveTime / 2;

		const minutes = driveTime % 60;
		let hours = 0;
		if (driveTime >= 60) {
			hours = (driveTime - minutes) / 60;
		}

		const oneWayMinutes = oneWay % 60;
		let oneWayHours = 0;
		if (oneWay >= 60) {
			oneWayHours = (oneWay - oneWayMinutes) / 60;
		}

		let driveTimeReturn = '';

		if (hours > 0) {
			driveTimeReturn += hours + ' Hour';
		}
		if (hours > 1) {
			driveTimeReturn += 's ';
		} else {
			driveTimeReturn += ' ';
		}
		if (minutes > 0) {
			driveTimeReturn += minutes + ' Minutes ';
		}
		driveTimeReturn += '(';
		if (oneWayHours > 0) {
			driveTimeReturn += oneWayHours + ' Hour';
		}
		if (oneWayHours > 1) {
			driveTimeReturn += 's ';
		} else {
			driveTimeReturn += ' ';
		}
		if (oneWayMinutes > 0) {
			driveTimeReturn += oneWayMinutes + ' Minutes';
		}
		driveTimeReturn += ' One - Way)';
		//10 Minutes (5 minutes One-Way)

		return (
			<label className='DriveTimeSelect' id='DriveTimeSelect' tabIndex='9'>
				{driveTimeReturn}
			</label>
		);
	}

	/**
	 * Adds time to the workTime or driveTime state based on the given time parameter.
	 *
	 * @param {string} time - The type of time to add. Can be either 'WorkTimeSelect' or 'DriveTimeSelect'.
	 * @returns - {void}
	 */
	AddTime(time) {
		if (time == 'WorkTimeSelect') {
			const workTime = this.state.workTime + 15;
			this.setState({ workTime: workTime });
		} else if (time == 'DriveTimeSelect') {
			const driveTime = this.state.driveTime + 10;
			this.setState({ driveTime: driveTime });
		}
	}

	/**
	 * Subtracts time from the workTime or driveTime state based on the given time parameter.
	 *
	 * @param {string} time - The type of time to subtract. Can be either 'WorkTimeSelect' or 'DriveTimeSelect'.
	 * @returns - {void}
	 */
	SubtractTime(time) {
		if (time == 'WorkTimeSelect') {
			let workTime = this.state.workTime - 15;
			if (workTime < 0) {
				workTime = 0;
			}
			this.setState({ workTime: workTime });
		} else if (time == 'DriveTimeSelect') {
			let driveTime = this.state.driveTime - 10;
			if (driveTime < 10) {
				driveTime = 10;
			}
			this.setState({ driveTime: driveTime });
		}
	}

	/**
	 * Validates a phone number.
	 *
	 * @param {string} phone - The phone number to validate.
	 * @returns - {boolean} - Returns true if the phone number is valid, false otherwise.
	 */
	ValidatePhone(phone) {
		if (phone != null) {
			// phone number is required
			let numbers = this.state.PhoneNumbers;

			let temp = phone;
			temp = temp.replace(/[^\d]/g, '');

			for (let i = 0; i < numbers.length; i++) {
				if (temp == numbers[i].Number) {
					return true;
				}
			}
		} else if (phone == null) {
			// doesn't require a phone number
			return true;
		}

		return false; // phone not found
	}

	/**
	 * Retrieves paper job updates based on the provided parameters.
	 *
	 * @param {string} paper_changed - The type of paper change. Can be 'All' or a specific paper type.
	 * @param {object} serviceReportObject - The service report object containing job details.
	 * @returns - {object} - An object containing the job updates.
	 */
	GetPaperJobUpdates(paper_changed, serviceReportObject) {
		if (
			(serviceReportObject.JobID[0] == 'P' && this.state.kioskServerJobID.length == 1) ||
			(this.state.kioskServerJobID.length > 1 && serviceReportObject.PaperChanged != 'None')
		) {
			// check if paper_changed == 'none' and throw error.
			let job_id = this.state.kioskServerJobID.filter((e) => e.JobID[0] == 'P')[0].JobID;
			let paper_job_complete = true;
			let sub_paper_job_complete = true;
			let paper_job_copy = this.state.kioskServerJobID.filter((e) => e.JobID == job_id);
			let paper_job = paper_job_copy[0].Jobs;
			let selected_index = 0;

			paper_job.forEach((job, index) => {
				if (job.KioskID == serviceReportObject.KioskID.split('/')[0].trim()) {
					selected_index = index;
					job.Type.forEach((paper_type) => {
						if (paper_changed == 'All') {
							// if all paper has been changed at the kiosk set all paper to completed, and set the kiosk status to completed.
							paper_type.Completed = paper_type.Completed = true;
						} else if (paper_changed == paper_type.PaperType) {
							// if only one type of paper was changed, then set completed just for that paper type and leave kiosks status as open.
							paper_type.Completed = paper_type.Completed = true;
						}
					});
				}
			});

			paper_job[selected_index].Type.forEach((paper_type) => {
				sub_paper_job_complete = paper_type.Completed == false ? false : sub_paper_job_complete;
			});

			if (sub_paper_job_complete) {
				paper_job[selected_index].KioskStatus = 'Completed';
			}

			paper_job.forEach((job) => {
				paper_job_complete = job.KioskStatus == 'Open' ? false : paper_job_complete;
			});

			return {
				UpdateJob: true,
				Complete: paper_job_complete,
				JobID: job_id,
				Jobs: JSON.stringify(paper_job),
			};
		}

		return { Updatejob: false };
	}

	/**
	 * Validates the time input based on the given type.
	 *
	 * @param {string} _type - The type of time input ('drivetime' or 'worktime').
	 * @returns - {string|null} - The validated time input in the format of 'X hour Y minutes', 'X hour', 'Y minutes', or null if the input is invalid.
	 */
	ValidateTimeInput(_type) {
		if (this.state.newFeature && _type == 'drivetime') {
			const _hours = document.getElementById('hour-drive').value;
			const _mins = document.getElementById('min-drive').value;
			const h = parseInt(_hours);
			const m = parseInt(_mins);

			return h && m ? `${h} hour ${m} minutes` : !h && m ? `${m} minutes` : h && !m ? `${h} hour` : null;
		} else if (this.state.newFeature && _type == 'worktime') {
			const _hours = document.getElementById('hour-work').value;
			const _mins = document.getElementById('min-work').value;
			const h = parseInt(_hours);
			const m = parseInt(_mins);

			return h && m ? `${h} hour ${m} minutes` : !h && m ? `${m} minutes` : h && !m ? `${h} hour` : null;
		} else {
			if (_type == 'drivetime') {
				return document.getElementById('DriveTimeSelect').value;
			} else if (_type == 'worktime') {
				return document.getElementById('WorkTimeSelect').value;
			}
		}
	}

	/**
	 * Saves and sends the service report.
	 */
	SaveAndSendServiceReport() {
		let formNotEmpty = true;
		const date = new Date();
		const currentTimeStamp = FormatDateTime(date);
		const dateString =
			document.getElementById('DateYear').value +
			'-' +
			document.getElementById('DateMonth').value +
			'-' +
			document.getElementById('DateDay').value;
		const ServiceReportPaperJobCheckbox = document.getElementById('PaperJobIDSelect');
		const completedPaperJobs = [];
		let validPhone = false;
		let phone = null;
		let carrier = null;
		let star_firmware = document.getElementById('star-firmware')
			? document.getElementById('star-firmware').value
			: null;
		let ski_slope = document.getElementById('ski-slope') ? document.getElementById('ski-slope').value : null;
		let os_installed = document.getElementById('os-installed') ? document.getElementById('os-installed').value : null;
		let cleaning = document.getElementById('cleaning') ? document.getElementById('cleaning').value : null;
		let bridge = document.getElementById('paper-bridge') ? document.getElementById('paper-bridge').value : null;
		let guides = document.getElementById('tape-guides') ? document.getElementById('tape-guides').value : null;
		let _switch = document.getElementById('reset-switch') ? document.getElementById('reset-switch').value : null;

		if (document.getElementById('PhoneInput') != null) {
			phone = document
				.getElementById('PhoneInput')
				.value.replace(/\r?\n|\r/g, ' ')
				.replace(/["'`]/g, '``');
		}

		if (document.getElementById('CarrierInput') != null) {
			carrier = document
				.getElementById('CarrierInput')
				.value.replace(/\r?\n|\r/g, ' ')
				.replace(/["'`]/g, '``');
		}

		if (ServiceReportPaperJobCheckbox !== null) {
			if (ServiceReportPaperJobCheckbox.value !== 'Select a Job') {
				completedPaperJobs.push(ServiceReportPaperJobCheckbox.value);
			}
		}

		let paper_changed = '';
		if (document.getElementById('PaperChangeSelect') != null) {
			paper_changed = document.getElementById('PaperChangeSelect').value;
		}

		let serviceReportObject = {
			TimeSubmited: currentTimeStamp,
			ServiceDate: dateString,
			Technician: document.getElementById('TechSelect').value,
			JobID: document.getElementById('JobIDSelect').value,
			Issue: document.getElementById('IssueSelect').value,
			WorkTime: this.ValidateTimeInput('worktime'),
			DriveTime: this.ValidateTimeInput('drivetime'),
			KioskID: document.getElementById('KioskIDSelect').value.replace(/["'`]/g, ''),
			DatamaxFeeds: document.getElementById('HowManyFeedsSelect').value,
			DMOneTestPrints: document.getElementById('DMOneTestPrintsSelect').value,
			DMTwoTestPrints: document.getElementById('DMTwoTestPrintsSelect').value,
			StarTestsPrints: document.getElementById('StarTestPrintsSelect').value,
			ExtraRolls: document.getElementById('ExtraStarRollsSelect').value,
			StickyRolls: document.getElementById('StickyRollsSelect').value,
			UpdateModemInfo: document.getElementById('UpdateModemSelect').value,
			UpdatePageCounts: document.getElementById('UpdatePageCountSelect').value,
			PhoneNumber: phone,
			PartInstalled: document.getElementById('part_installed').value,
			Carrier: carrier,
			StarJam: document.getElementById('star-jam').value,
			Notes: document
				.getElementById('NotesField')
				.value.replace(/\r?\n|\r/g, ' ')
				.replace(/["'`]/g, '``'),
			PaperChanged: paper_changed ? paper_changed : 'None',
			CompletedPaperJobs: '[]',
			PaperJobStatus: '',
			StarFirmware: star_firmware === 'true' ? 'Yes' : star_firmware === 'false' ? 'No' : null,
			SkiSlope: ski_slope === 'true' ? 'Yes' : ski_slope === 'false' ? 'No' : null,
			OSInstall: os_installed === 'true' ? 'Yes' : os_installed === 'false' ? 'No' : null,
			Cleaning: cleaning === 'true' ? 'Yes' : cleaning === 'false' ? 'No' : null,
			PaperBridge: bridge === 'true' ? 'Yes' : bridge === 'false' ? 'No' : null,
			Guides:
				guides === 'none'
					? 'No guides in place'
					: guides === 'dm1'
					? 'Only DM1'
					: guides === 'dm2'
					? 'Only DM2'
					: guides === 'both'
					? 'Both DM1 and DM2'
					: null,
			ResetSwitch: _switch === 'true' ? 'Yes' : _switch === 'false' ? 'No' : null,
		};

		validPhone = this.ValidatePhone(serviceReportObject.PhoneNumber);

		if (!validPhone) {
			alert(
				'ERROR SERVICE_REPORT_PHONENUMBER_INVALID: The Phone Number you have entered is not valid.  Please check that the Phone Number you entered is correct, and does not contain any special characters.  If you think this is a mistake, please call tech support at (763) 225-1171 for an override.'
			);
		} else if (!serviceReportObject.WorkTime && this.state.newFeature) {
			alert(
				`ERROR: INVALID WORK TIME:\n\nThe value you have entered for "Work Time At Store" is not valid. Please try again.`
			);
		} else if (!serviceReportObject.DriveTime && this.state.newFeature) {
			alert(
				`ERROR: INVALID DRIVE TIME:\n\nThe value you have entered for "Total Drive Time" is not valid. Please try again.`
			);
		}

		if (!serviceReportObject.ServiceDate) {
			document.getElementById('DateMonth').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('DateMonth').style.border = '0 solid white';
		}
		if (!serviceReportObject.ServiceDate) {
			document.getElementById('DateDay').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('DateDay').style.border = '0 solid white';
		}
		if (!serviceReportObject.ServiceDate) {
			document.getElementById('DateYear').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('DateYear').style.border = '0 solid white';
		}

		if (!serviceReportObject.Technician) {
			document.getElementById('TechSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('TechSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.JobID) {
			document.getElementById('JobIDSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('JobIDSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.Issue) {
			document.getElementById('IssueSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('IssueSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.WorkTime) {
			formNotEmpty = false;
		}
		if (!serviceReportObject.DriveTime) {
			formNotEmpty = false;
		}
		if (!serviceReportObject.KioskID) {
			document.getElementById('KioskIDSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('KioskIDSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.DatamaxFeeds) {
			document.getElementById('HowManyFeedsSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('HowManyFeedsSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.DMOneTestPrints) {
			document.getElementById('DMOneTestPrintsSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('DMOneTestPrintsSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.DMTwoTestPrints) {
			document.getElementById('DMTwoTestPrintsSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('DMTwoTestPrintsSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.StarTestsPrints) {
			document.getElementById('StarTestPrintsSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('StarTestPrintsSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.StarJam) {
			document.getElementById('star-jam').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('star-jam').style.border = '0 solid white';
		}
		if (!serviceReportObject.ExtraRolls) {
			document.getElementById('ExtraStarRollsSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('ExtraStarRollsSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.StickyRolls) {
			document.getElementById('StickyRollsSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('StickyRollsSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.UpdateModemInfo) {
			document.getElementById('UpdateModemSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('UpdateModemSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.UpdatePageCounts) {
			document.getElementById('UpdatePageCountSelect').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('UpdatePageCountSelect').style.border = '0 solid white';
		}
		if (!serviceReportObject.Notes) {
			document.getElementById('NotesField').style.border = '2px solid red';
			formNotEmpty = false;
		} else {
			document.getElementById('NotesField').style.border = '0 solid white';
		}

		if (document.getElementById('PhoneInput') != null) {
			if (!serviceReportObject.PhoneNumber) {
				document.getElementById('PhoneInput').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('PhoneInput').style.border = '0 solid white';
			}
		}

		if (document.getElementById('CarrierInput') != null) {
			if (!serviceReportObject.Carrier) {
				document.getElementById('CarrierInput').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('CarrierInput').style.border = '0 solid white';
			}
		}

		if (document.getElementById('cleaning') != null) {
			if (!serviceReportObject.Cleaning) {
				document.getElementById('cleaning').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('cleaning').style.border = '0 solid white';
			}
		}

		if (document.getElementById('star-firmware') != null) {
			if (!serviceReportObject.StarFirmware) {
				document.getElementById('star-firmware').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('star-firmware').style.border = '0 solid white';
			}
		}

		if (document.getElementById('ski-slope') !== null) {
			if (!serviceReportObject.SkiSlope) {
				document.getElementById('ski-slope').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('ski-slope').style.border = '0 solid white';
			}
		}

		if (document.getElementById('os-installed') !== null) {
			if (!serviceReportObject.OSInstall) {
				document.getElementById('os-installed').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('os-installed').style.border = '0 solid white';
			}
		}

		if (document.getElementById('paper-bridge') != null) {
			if (!serviceReportObject.PaperBridge) {
				document.getElementById('paper-bridge').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('paper-bridge').style.border = '0 solid white';
			}
		}

		if (document.getElementById('tape-guides') != null) {
			if (!serviceReportObject.Guides) {
				document.getElementById('tape-guides').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('tape-guides').style.border = '0 solid white';
			}
		}

		if (document.getElementById('reset-switch') != null) {
			if (!serviceReportObject.Guides) {
				document.getElementById('reset-switch').style.border = '2px solid red';
				formNotEmpty = false;
			} else {
				document.getElementById('reset-switch').style.border = '0 solid white';
			}
		}

		if (this.state.PaperToComplete.length && paper_changed != 'All') {
			if (paper_changed && paper_changed == 'None') {
				formNotEmpty = false;
				document.getElementById('PaperChangeSelect').style.border = '2px solid red';
				alert(
					`It looks like you have paper to change at this kiosk, but selected "None".\n\nPlease make sure all paper has been changed at this kiosk and select the "All" option.`
				);
			} else if (paper_changed && this.state.PaperToComplete.length == 1) {
				if (!this.state.PaperToComplete.filter((paper) => paper == paper_changed).length) {
					formNotEmpty = false;
					document.getElementById('PaperChangeSelect').style.border = '2px solid red';
					alert(
						`It looks like the paper you have selected as being "changed" at this kiosk does not match the paper listed on your job.\n\nPlease make sure you have changed ALL paper at this kiosk and submit your report with an updated selection.`
					);
				}
			} else if (paper_changed && this.state.PaperToComplete.length > 1) {
				formNotEmpty = false;
				document.getElementById('PaperChangeSelect').style.border = '2px solid red';
				alert(
					`It looks like you only changed part of what is listed on your paper letter for this kiosk.\n\nPlease make sure ALL paper is changed at this kiosk listed on your letter and select the "All" option.`
				);
			}
		}

		let kiosk = serviceReportObject.KioskID.split('/');
		kiosk = kiosk[0].trim();

		const kiosk_info = this.state.KiosksDB.filter(
			(kiosk) => kiosk.KioskID == serviceReportObject.KioskID.substring(0, 6)
		)[0];
		serviceReportObject.KioskID_Loc = kiosk_info.KioskID_Loc;

		let paper_job_obj = this.GetPaperJobUpdates(paper_changed, serviceReportObject);
		const snapshot = {
			dm1: kiosk_info.PrintCountOne,
			dm2: kiosk_info.PrintCountTwo,
			star: kiosk_info.PrintCountCOVR,
		};

		if (formNotEmpty && validPhone) {
			document.getElementById('SaveAndSendServiceReportBtn').disabled = true;
			socket.emit('insertServiceReportv2', serviceReportObject, snapshot, (boolean) => {
				const serviceDate = serviceReportObject.ServiceDate;
				const serviceTechnician = serviceReportObject.Technician;
				const serviceJobID = serviceReportObject.JobID;
				const serviceKioskID = serviceReportObject.KioskID;
				const serviceIssue = serviceReportObject.Issue;
				const servicePartsInstalled = serviceReportObject.PartInstalled;
				const serviceDriveTime = serviceReportObject.DriveTime;
				const serviceWorkTime = serviceReportObject.WorkTime;
				const serviceCleaning = serviceReportObject.Cleaning;
				const servicePaperBridge = serviceReportObject.PaperBridge;
				const serviceGuides = serviceReportObject.Guides;
				const serviceResetSwitch = serviceReportObject.ResetSwitch;
				const serviceStarFirmware = serviceReportObject.StarFirmware;
				const serviceSkiSlope = serviceReportObject.SkiSlope;
				const serviceOSInstall = serviceReportObject.OSInstall;
				const serviceDatamaxFeeds = serviceReportObject.DatamaxFeeds;
				const serviceDMOneTestPrints = serviceReportObject.DMOneTestPrints;
				const serviceDMTwoTestPrints = serviceReportObject.DMTwoTestPrints;
				const serviceStarTestsPrints = serviceReportObject.StarTestsPrints;
				const serviceStarJam = serviceReportObject.StarJam;
				const serviceExtraRolls = serviceReportObject.ExtraRolls;
				const serviceStickyRolls = serviceReportObject.StickyRolls;
				const serviceUpdateModemInfo = serviceReportObject.UpdateModemInfo;
				const serviceCarrier = serviceReportObject.Carrier;
				const servicePhoneNumber = serviceReportObject.PhoneNumber;
				const serviceUpdatePageCounts = serviceReportObject.UpdatePageCounts;
				const serviceNotes = serviceReportObject.Notes;
				if (boolean) {
					socket.emit('getDriveTimesForJob', `'${serviceTechnician}'`, kiosk, async (drivetime) => {
						const to = this.state.lists.EmailTo;
						const text =
							`<div width="100%",style="text-align:left;">` +
							`<h1 style="text-align:center;">Service Report</h1>` +
							`<table border="1", width="100%">` +
							`<tbody style="text-align:left;">` +
							`<tr><td width="300">Report Submitted</td> <td>` +
							currentTimeStamp +
							`</td></tr>` +
							`<tr><td>Service Date</td> <td>` +
							serviceDate +
							`</td></tr>` +
							`<tr><td>Technician</td> <td>` +
							serviceTechnician +
							`</td></tr>` +
							`<tr><td>Job ID</td> <td>` +
							serviceJobID +
							`</td></tr>` +
							`<tr><td>Kiosk ID/Server</td> <td>` +
							serviceKioskID +
							`</td></tr>` +
							`<tr><td>Kiosk Issue</td> <td>` +
							serviceIssue +
							`</td></tr>` +
							`<tr><td>Part(s) Installed</td> <td>` +
							servicePartsInstalled +
							`</td></tr>` +
							`<tr><td>Drive Time</td> <td>` +
							serviceDriveTime +
							`</td></tr>` +
							`<tr><td>Estimated Drive Time</td> <td>` +
							`${drivetime.length > 0 ? drivetime[0].DriveTime : 'No Drive Time Available'}` +
							`</td></tr>` +
							`<tr><td>Work Time</td> <td>` +
							serviceWorkTime +
							`</td></tr>` +
							`${
								this.state.newFeature
									? `<tr><td>Performed Cleaning / Cable Management</td><td>${serviceCleaning}</td></tr>`
									: ''
							}` +
							`${
								this.state.newFeature ? `<tr><td>Paper Bridge Installed</td><td>${servicePaperBridge}</td></tr>` : ''
							}` +
							`${this.state.newFeature ? `<tr><td>Tape Guides Installed</td><td>${serviceGuides}</td></tr>` : ''}` +
							`${
								this.state.newFeature ? `<tr><td>Reset Switch Installed</td><td>${serviceResetSwitch}</td></tr>` : ''
							}` +
							`${
								this.state.newFeature
									? `<tr><td>Performed Star Printer Firmware Update</td><td>${serviceStarFirmware}</td></tr>`
									: ''
							}` +
							`${
								this.state.newFeature
									? `<tr><td>Performed Ski Slope Installation</td><td>${serviceSkiSlope}</td></tr>`
									: ''
							}` +
							`${
								this.state.newFeature ? `<tr><td>Performed OS Installation</td><td>${serviceOSInstall}</td></tr>` : ''
							}` +
							`<tr><td>Datamax - Manual Form Feeds?</td> <td>` +
							serviceDatamaxFeeds +
							`</td></tr>` +
							`<tr><td>Datamax1 - Test Prints</td> <td>` +
							serviceDMOneTestPrints +
							`</td></tr>` +
							`<tr><td>Datamax1 - Current Count</td> <td>` +
							kiosk_info.PrintCountOne +
							`</td></tr>` +
							`<tr><td>Datamax2 - Test Prints</td> <td>` +
							serviceDMTwoTestPrints +
							`</td></tr>` +
							`<tr><td>Datamax2 - Current Count</td> <td>` +
							kiosk_info.PrintCountTwo +
							`</td></tr>` +
							`<tr><td>Star - Test Prints</td> <td>` +
							serviceStarTestsPrints +
							`</td></tr>` +
							`<tr><td>Star - Current Count</td> <td>` +
							kiosk_info.PrintCountCOVR +
							`</td></tr>` +
							`<tr><td>Star Printer Jam</td> <td>` +
							serviceStarJam +
							`</td></tr>` +
							`<tr><td>Star Paper Rolls</td> <td>` +
							serviceExtraRolls +
							`</td></tr>` +
							`<tr><td>Sticky Rolls</td> <td>` +
							serviceStickyRolls +
							`</td></tr>` +
							`<tr><td>Updated the Modem Info</td> <td>` +
							serviceUpdateModemInfo +
							`</td></tr>` +
							`<tr><td>Phone Number</td> <td>` +
							servicePhoneNumber +
							`</td></tr>` +
							`<tr><td>Carrier</td> <td>` +
							serviceCarrier +
							`</td></tr>` +
							`<tr><td>Updated the Page Counts</td> <td>` +
							serviceUpdatePageCounts +
							`</td></tr>` +
							`<tr><td>Notes</td> <td>` +
							serviceNotes +
							`</td></tr>` +
							`</tbody>` +
							`</table >` +
							`</div >`;

						$.get('/send', {
							to: to,
							subject: 'Service Log - ' + document.getElementById('KioskIDSelect').value,
							text: text,
						});

						if (paper_job_obj.UpdateJob) {
							socket.emit('updatePaperJobStatus', paper_job_obj, paper_job_obj.JobID, (res) => {
								if (!res) {
									console.error(`ERROR: There was an issue updating this paper job status.`);
								}
							});
						}

						// commented out until the service report can be refactored, this may have been overwriting the phone numbers in the kiosks for a long time now.
						// socket.emit('updatePhoneInfo', kiosk, 'Yes', (boolean) => {
						// 	if (!boolean) {
						// 		console.error('ERROR: There was an issue updating ValidPhone for Kiosk ' + kiosk);
						// 	}
						// });

						const reminder_msg = serviceReportObject.Issue.includes('Paper Change')
							? `\nThank you for completing this paper change. All test prints, and any extra stickers removed from this kiosk need to be returned to us A.S.A.P\n\n`
							: serviceReportObject.Issue === 'Part Install'
							? `\nThank you for completing this part installation.  Any part that you removed during this installation must be returned to us A.S.A.P\n\n`
							: '';

						//MOBILE MODE
						const techPhoneNumber = (await getTechNumber(serviceTechnician)) || '';
						const messageList = JSON.parse(this.state.lists.MsgTo);
						messageList.push(techPhoneNumber);
						const mobileModeMsg = messageList.map((list) => ({
							body:
								'Service Report Received\n' +
								reminder_msg +
								'Technician: ' +
								serviceTechnician +
								'\n' +
								'Kiosk: ' +
								serviceKioskID +
								'\n' +
								'Issue: ' +
								serviceIssue +
								'\n' +
								'JobID: ' +
								serviceJobID +
								'JobID: ' +
								serviceJobID +
								'\n' +
								'Store Time: ' +
								serviceWorkTime +
								'\n' +
								'Drive Time: ' +
								serviceDriveTime +
								'\n\n',
							to: list,
							from: 'Mobile Mode',
							time: CurrentTime(),
						}));

						axios.post('/api/refreshJob');
						axios.post('/api/refreshAlert');
						axios.post('/api/send_message_bulk', { messages: mobileModeMsg }, () => {
							// Empty callback to handle the request completion if needed
							console.log('Request completed.');
						});

						alert('Thank you, we have received your Service Report.');
						window.location.reload();
					});
				} else {
					//Notify user of Failure
					alert(
						'ERROR SERVICE_REPORT_SUBMISSION_FAILURE: We did not receive your Service Report. Please try submitting your log again without any special characters or new lines in the notes section. If this problem persists, please call tech support at (763) 225-1171.'
					);
				}
			});
			//END
		}
	}
}
