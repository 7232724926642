import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const HeaderContainer = ({ LogsData, BackButton, CloseButton }) => {
	return (
		<div className='report-header-container' id='report-header-container'>
			{!LogsData.AllReports ? (
				<div className='report-header' id='report-header'>
					<div>
						<Button color='rti-close white' onClick={() => BackButton(false)}>
							<div style={{ width: '30px', fontSize: '20px' }}>&#10094;</div>
						</Button>
					</div>
					<div style={{ width: '100%' }}>
						<h6
							className='chat-header-name'
							id='chat-header-name'
							style={{
								textAlign: 'center',
								paddingTop: '10px',
								width: '90%',
							}}>
							{LogsData.LogHeader}
						</h6>
					</div>
				</div>
			) : (
				<div className='chat-header' id='chat-header'>
					<div style={{ margin: '0px 0px', display: 'flex', width: '100%' }}>
						<div style={{ width: '100%', marginRight: 'auto' }}>
							<h6
								style={{
									paddingTop: '8px',
									margin: 'auto',
									color: 'white',
									width: '20px',
								}}>
								Logs
							</h6>
						</div>
						<div style={{ width: '8%' }}>
							<Button style={{ fontSize: '30px' }} color='rti-close white' onClick={CloseButton}>
								&times;
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

HeaderContainer.propTypes = {
  LogsData: PropTypes.shape({
    AllReports: PropTypes.array.isRequired,
    LogHeader: PropTypes.string.isRequired,
  }).isRequired,
  BackButton: PropTypes.element.isRequired,
  CloseButton: PropTypes.element.isRequired,
};

export default HeaderContainer;
