import React, { Component } from 'react';

// Formats the IssueModal Content
export default class IssueModal extends Component {
    constructor(props) {
        super(props);
        if (this.props.issueKey) {
            //["KioskIssueID", "ErrorCode", "Description", "ToDoNext"]
            this.state = {
                KioskIssueID: this.props.issueModal.KioskIssueID,
                ErrorCode: this.props.issueModal.ErrorCode,
                Description: this.props.issueModal.Description,
                ToDoNext: this.setJson(JSON.parse(this.props.issueModal.ToDoNext)),
                ToDoNextInput: ""
            };
        }
        else {
            this.state = {
                KioskIssueID: "",
                ErrorCode: "",
                Description: "",
                ToDoNext: {},
                ToDoNextInput: ""
            };
        }
    }

    setJson = (passedJson) => {
        if (passedJson) {
            return passedJson
        }
        return [];
    }


    IssueCloseButton = () => {
        (document.getElementById('modal_kiosk_issues')).style.display = "none";
        (document.getElementById('myModal')).style.display = "none";
    }

    onChange = (e, whichValue) => {

        //this.setState(() => ({ [whichValue] : e.target.value }))
        switch (whichValue) {
            case "ErrorCode":
                this.setState({ ErrorCode: e.target.value });
                break;
            case "Description":
                this.setState({ Description: e.target.value });
                break;
            case "ToDoNextInput":
                this.setState({ ToDoNextInput: e.target.value });
                break;
            case "ToDoNext":
                this.setState({ ToDoNext: e.target.value });
                break;
        }
    }


    render() {
            //["KioskIssueID", "ErrorCode", "Description", "ToDoNext"]
        let divToRender = (
            <div className="IssueForm" >
                <div className="FirstLayer" >
                    <div className="DispatcherNameInput" >
                        <label>Error</label><br />
                        <input className="IssueErrorCodeInput" id="IssueErrorCodeInput" type="text" maxLength="40" placeholder="Error Code" title="This is the Error code we recieve from Timothy. This needs to match." value={this.state.ErrorCode} onChange={(e) => this.onChange(e, "ErrorCode")} />
                    </div>
                </div>

                <div className="SecondLayer">
                    <div className="IssueDescription" >
                        <label >Description</label><br />
                        <textarea className="IssueDescriptionInput" id="IssueDescriptionInput" value={this.state.Description} onChange={(e) => this.onChange(e, "Description")} placeholder="Description of Error." title="This is the error that we see on our end in Kiosk Alerts. It does not need to match what Timothy sends over. "> </textarea>
                    </div>
                </div>

                <div className="ToDoNextTableContent" >
                    <div className="ToDoNextDiv">
                        <label className="ToDoNextLabel">To Do Next</label>
                        <div className="ToDoNextHeader">
                            <input className="ToDoNextTextInput" id="ToDoNextTextInput" type="text" placeholder="Next Step" value={this.state.ToDoNextInput} title="These are the steps take to solve this issue. It is better to edit the steps in the MySQL Database, but if you just need to add a quick step, this will work too." onChange={(e) => this.onChange(e, "ToDoNextInput")}/>

                            <button className="MinusTechButton" id="MinusTechButton" onClick={this.MinusTechButton}>-</button>
                            <button className="PlusTechButton" id="PlusTechButton" onClick={this.PlusTechButton}>+</button>
                        </div>
                        {this.RenderList("", (this.state.ToDoNext), "ToDoNextList", "ToDoNextListTable")}
                    </div>
                </div>
                <div>
                    <button className="RTIButton" id="ISSUFormClose" onClick={this.IssueCloseButton}>CLOSE</button>
                    {(this.props.issueKey && this.props.role) ? <button className="RTIButton" id="ISSUFormDelete" onClick={this.IssueDeleteButton} style={{ position: 'absolute', top: '0', right: '0' }}>DELETE</button> : undefined}
                    {(this.props.issueKey && this.props.role) ? <button className="RTIButton" id="ISSUFormSave" onClick={this.IssueSaveButton} style={{ float: 'right' }}>SAVE</button> : undefined}
                    {(!this.props.issueKey && this.props.role) ? <button className="RTIButton" id="ISSUFormAdd" onClick={this.IssueAddButton} style={{ float: 'right' }}>ADD</button> : undefined}
                </div>
            </div>
        );

        return divToRender;
    }

    //Reactive Table List that will only Display a list that matches string from the input
    RenderList(passedValue, passedArray, passedListClassName, passedTableListClassName) {
        //Saves the filteredArray into the array that will display as a list under the text inputs
        let returnArray = [];
        let keys = Object.keys(passedArray);
        if ((passedArray)) {
            for (let i = 0; i < keys.length; i++) {
                returnArray.push(
                    <li key={i} className={passedListClassName} id={passedArray[keys[i]]} value={passedArray[keys[i]]} >{passedArray[keys[i]]}</li>
                )
            }
        }

        //Returns an Unordered List
        return (
            <ul className={passedTableListClassName} id={passedTableListClassName}>
                {returnArray}
            </ul>
        );

    }

    //Adds the Current Item in Select to the Parts List
    PlusTechButton = (e) => {

        if ((document.getElementById('ToDoNextTextInput')).value) {
            let ToDoNext = this.state.ToDoNext;
            let selectValue = (document.getElementById('ToDoNextTextInput')).value;
            if ((Object.values(ToDoNext).indexOf(selectValue) > -1)) {
                return false;
            }
            let step = String.fromCharCode(Object.keys(ToDoNext).length + 65);
            ToDoNext[step] = selectValue;
            this.setState({ ToDoNext: ToDoNext });
            document.getElementById("ToDoNextTextInput").value = "";
        }

    }

    //When a Parts List Item is Selected (Grey) Clicking the Minus Button Removes it From the List
    MinusTechButton = (e) => {
        let ToDoNext = this.state.ToDoNext;
        let search_term = this.state.PrimaryTechValue;
        let partsListArray = (document.getElementsByClassName('ToDoNextList'));

        //for (let i = 0; i < ToDoNext.length; i++) {
         //   if (ToDoNext[i] === search_term) {
          //      ToDoNext.splice(i, 1);
         //           partsListArray[i].style.backgroundColor = "#ffffff";
         //       }
         //   }

        //this.setState({ ToDoNext: ToDoNext })

    }

    IssueDeleteButton = () => {
        let areYouSure = prompt("Are you sure you want to delete this issue? This could cause problems. Alerts will not properly display if you remove the alerts that come in from Timothy. Type 'YES' to confirm", "");
        if (areYouSure) {
            if (areYouSure.toLowerCase() == "yes") {
                let confirmDelete = prompt("Type 'DELETE' to confirm deleting this issue.", "");

                if (confirmDelete) {
                    if (confirmDelete.toLowerCase() == "delete") {
                        let KioskIssueID = this.state.KioskIssueID;
                        socket.emit('deleteKioskIssueForTM', KioskIssueID, (boolean) => {
                            if (boolean) {
                                this.props.refresh_data();
                                this.IssueCloseButton();
                            }
                            else {
                                alert("Kiosk Issue was not deleted.");
                            }
                        });
                    }
                }
            }
        }

    }

    IssueSaveButton = () => {
        let objectToInsert = {
            KioskIssueID: this.state.KioskIssueID,
            ErrorCode: this.state.ErrorCode,
            Description: this.state.Description,
            ToDoNext: this.state.ToDoNext
        }

        socket.emit('updateIssueForTM', objectToInsert, (boolean) => {
            if (boolean) { //Close CreateJobModal

                this.props.refresh_data();
                this.IssueCloseButton();
            }
            else {//Notify user of Failure
                alert("You did not fill the form out correctly");
            }
        });
    }

    IssueAddButton = () => {
        let objectToInsert = {
            ErrorCode: this.state.ErrorCode,
            Description: this.state.Description,
            ToDoNext: this.state.ToDoNext
        }

        socket.emit('insertIssueForTM', objectToInsert, (boolean) => {
            if (boolean) { //Close CreateJobModal
                alert("Issue Saved Successfully.");
                this.props.refresh_data();
                this.IssueCloseButton();
            }
            else {//Notify user of Failure
                alert("You did not fill the form out correctly");
            }
        });
    }
}