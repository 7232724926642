import React from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import PropTypes from 'prop-types';
import '../../StyleSheets/Calendar.css';

const CalendarHeader = ({ curDate, onMonthChange, onYearChange }) => {
	const years = Array.from({ length: 21 }, (_, i) => curDate.year - 5 + i);

	const yearHandler = years.map((year) => (
		<option key={year} value={year}>
			{year}
		</option>
	));

	const yearChange = (e) => onYearChange(parseInt(e.target.value, 10));

	return (
		<div id='calendar-header' className='calendar-header'>
			<div className='calendar-header-content'>
				<button onClick={() => onMonthChange(-1)} className='calendar-nav-button'>
					<FaArrowAltCircleLeft />
				</button>
				<h1 className='calendar-month'>{curDate.month}</h1>
				<button onClick={() => onMonthChange(1)} className='calendar-nav-button'>
					<FaArrowAltCircleRight />
				</button>
				<select value={curDate.year} onChange={yearChange} className='calendar-year-select'>
					{yearHandler}
				</select>
			</div>
		</div>
	);
};

CalendarHeader.propTypes = {
	curDate: PropTypes.object.isRequired,
	onMonthChange: PropTypes.func.isRequired,
	onYearChange: PropTypes.func.isRequired,
};

export default CalendarHeader;
