import React from 'react';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { AiOutlineLeft, AiOutlineClose, AiOutlineSend } from 'react-icons/ai';
import { MdMoreHoriz } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import ChatImg from '../../../public/Images/chat.png';
import CloseImg from '../../../public/Images/multiply-white.png';
import SearchImg from '../../../public/Images/search-black.png';
import ChatRecievedMsg from '../../../public/Sounds/ChatRecieved.mp3';
import JobResponse from '../../../public/Sounds/JobResponse.mp3';
import { CurrentTime } from '../API/Moment';
import '../../StyleSheets/Chat.css';
import '../../StyleSheets/SavedChats.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(LocalizedFormat)

const image_base = 'https://s3.us-west-2.amazonaws.com/rti.images/Tech-Images/';

export default class ChatModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ModalOpen: false,
			AllMessages: true,
			Loading: false,
			MessageSending: false,
			AllChat: false,
			MessageList: [],
			User: localStorage.getItem('user'),
			FullName: '',
			Messages: [],
			TechSelected: {
				name: '',
				id: 0,
			},
			Unread: 0,
			snackbar: {
				visible: false,
				message: '',
				severity: 'success',
			},
			anchorEl: null,
			loadMore: false,
			numPages: 1,
		};
	}

	componentDidMount() {
		let username = localStorage.getItem('user');

		socket.emit('getUserFullName', username, (full_name) => {
			if (!full_name) {
				console.error('There was an issue getting this users full name');
			} else {
				this.setState({ FullName: full_name[0].FullName });
			}
		});

		this.GetSavedChats(username, 'all-messages', null, null);

		document.querySelectorAll('#chat-modal').forEach((element) => {
			if (element instanceof HTMLElement) {
				element.draggable = false;
			}
		});

		socket.on('newMessage', (tech, isIncoming, snippet) => {
			if (isIncoming) {
				let audio = document.getElementById('ChatRecievedMsg');
				document.getElementById('ChatRecievedMsg').muted = false;
				audio.play();

				const technician = this.state.MessageList.find((e) => e.name === tech);
				if (this.state.ModalOpen && technician.tech_id === this.state.TechSelected.id) {
					this.GetMessagesForTech(technician, this.state.Unread);
				} else if (!this.state.ModalOpen && technician.tech_id === this.state.TechSelected.id) {
					this.GetMessagesForTech(technician, this.state.Unread);
				} else if (
					(!this.state.ModalOpen && !this.state.AllMessages && technician.tech_id !== this.state.TechSelected.id) ||
					(this.state.ModalOpen && !this.state.AllMessages && technician.tech_id !== this.state.TechSelected.id)
				) {
					this.GetSavedChats(this.state.User, 'notify', technician, snippet);
				} else {
					this.GetSavedChats(this.state.User, 'all-messages', technician, snippet);
				}
			}
		});

		socket.on('newResponse', (tech, isJob, snippet) => {
			if (isJob) {
				let audio = document.getElementById('responseRecievedAudio');
				document.getElementById('responseRecievedAudio').muted = false;
				audio.play();

				const technician = this.state.MessageList.find((e) => e.name === tech);
				if (this.state.ModalOpen && technician.tech_id === this.state.TechSelected.id) {
					this.GetMessagesForTech(technician, this.state.Unread);
				} else if (!this.state.ModalOpen && technician.tech_id === this.state.TechSelected.id) {
					this.GetMessagesForTech(technician, this.state.Unread);
				} else if (
					(!this.state.ModalOpen && !this.state.AllMessages && technician.tech_id !== this.state.TechSelected.id) ||
					(this.state.ModalOpen && !this.state.AllMessages && technician.tech_id !== this.state.TechSelected.id)
				) {
					this.GetSavedChats(this.state.User, 'notify', technician, snippet);
				} else {
					this.GetSavedChats(this.state.User, 'all-messages', technician, snippet);
				}
			}
		});
	}

	GetSavedChats(username, type, tech, snippet) {
		socket.emit('getSavedChats', username, (messages, techs, snippets) => {
			if (!messages) {
				console.error('There was an issue getting saved chats');
			} else {
				const message_list = JSON.parse(messages);

				if (tech) {
					message_list.forEach((msg) => {
						if (snippet && msg.tech_id === tech.tech_id) {
							msg.snippet = `${snippet.replace(/[\n\t\r]/g, '')}...`;
						}
					});
				} else {
					message_list.forEach((msg) => {
						const _snippet = snippets.find((_tech) => _tech.from === msg.tech_id);
						if (_snippet) {
							msg.snippet = `${_snippet.snippet.replace(/[\n\t\r]/g, '')}...`;
						}
					});
				}

				if (tech) {
					let unread = 0;
					let temp = [];
					let found = false;

					message_list.forEach((message) => {
						if (tech.tech_id === message.tech_id) {
							found = true;
							unread += message.unreadCount + 1;
							message.unreadCount++;
							if (snippet) {
								message.snippet = `${snippet.replace(/[\n\t\r]/g, '')}...`;
							}
						} else {
							unread += message.unreadCount;
							const _snippet = snippets.find((_tech) => _tech.from === message.tech_id);
							if (_snippet) {
								message.snippet = `${_snippet.snippet.replace(/[\n\t\r]/g, '')}...`;
							}
						}
					});

					if (!found) {
						unread++;
						temp.push({
							tech_id: tech.tech_id,
							name: tech.name,
							unreadCount: 1,
							snippet: `${snippet.replace(/[\n\t\r]/g, '')}...`,
						});
					}

					const final_list = [...message_list, ...temp];
					final_list.sort((a, b) => b.unreadCount - a.unreadCount);
					this.SaveChats(final_list, username, type, unread, techs);
				} else {
					const unread = message_list.reduce((total, message) => total + message.unreadCount, 0);
					const final_list = [...message_list];
					final_list.sort((a, b) => b.unreadCount - a.unreadCount);
					this.SaveChats(final_list, username, type, unread, techs);
				}
			}
		});
	}

	SaveChats = (message_list = [], user = '', type = '', unread = 0, techs = []) => {
		socket.emit('saveChats', JSON.stringify(message_list), user, (response) => {
			if (!response) {
				console.error('There was an issue updating users saved chats');
			} else {
				if (type === 'notify') {
					this.setState({ Unread: unread });
				} else if (type === 'all-messages') {
					this.setState({
						Messages: message_list,
						Unread: unread,
						MessageList: techs,
					});
				} else if (type === 'back') {
					this.setState({
						Messages: message_list,
						TechSelected: { name: '', id: 0 },
						MessageList: techs,
						AllMessages: true,
						Loading: false,
						numPages: 1,
					});
				}
			}
		});
	};

	ModalHandler = () => {
		let modal_open = this.state.ModalOpen;
		modal_open = !modal_open;
		document.querySelectorAll('#chat-modal').forEach((element) => {
			if (modal_open) {
				element.style.display = 'block';
			} else {
				element.style.display = 'none';
			}
		});
		this.setState({ ModalOpen: modal_open });
	};

	BackButton = () => {
		this.setState({
			Loading: true,
			Messages: [],
			numPages: 1,
			loadMore: false,
		});
		this.GetSavedChats(this.state.User, 'back', null);
	};

	RenderMessageList() {
		return this.state.MessageList.map((tech) => <option key={tech.tech_id} id={tech.tech_id}>{`${tech.name}`}</option>);
	}

	RenderMessageBody() {
		if (this.state.AllMessages) {
			const all_messages = this.state.Messages.map((message) => (
				<tr key={message.tech_id} id={message.tech_id}>
					<td id={message.tech_id}>
						<div className='all-message-body-container' id={message.tech_id} style={{ userSelect: 'text' }}>
							<div id={message.tech_id} className='flex-box'>
								<div className='flex-column flex-just-center' id={message.tech_id}>
									{message.unreadCount ? <span className='new-message-badge'>{message.unreadCount}</span> : null}
								</div>
								<div
									className='message-body2'
									id={message.tech_id}
									style={{
										marginRight: 'auto',
										padding: '6px 10px',
										width: '100%',
									}}
									onClick={this.GetTechMessages}
								>
									<div
										style={{
											width: '160px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
										id={message.tech_id}
									>
										{message.name}
									</div>
									<label
										id={message.tech_id}
										style={{ fontSize: 14, color: 'rgba(0,0,0,0.52)' }}
										onClick={this.GetTechMessages}
									>
										{message.snippet}
									</label>
								</div>
								<div id={message.tech_id} style={{ fontSize: '12px', padding: '6px 0px' }}>
									<Button id={message.tech_id} color='rti-close' onClick={(e) => this.UpdateSavedChats(e, 'remove')}>
										&times;
									</Button>
								</div>
							</div>
						</div>
					</td>
				</tr>
			));

			const all_chat = (
				<tr key={`all_chat_button`} id={`all_chat_button`}>
					<td id={`all_chat_button`}>
						<div className='all-message-body-container' id={`all_chat_button`}>
							<div
								className='message-body'
								id={`all_chat_button`}
								style={{
									marginRight: 'auto',
									padding: '6px 0px',
									width: '100%',
								}}
								onClick={this.GetTechMessages}
							>
								<div
									style={{
										width: '160px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
									id={`all_chat_button`}
								>
									All Chat
								</div>
							</div>
						</div>
					</td>
				</tr>
			);

			if (this.props.allChatPerms) {
				return [all_chat, ...all_messages];
			} else {
				return all_messages;
			}
		} else {
			const _messages = this.state.Messages.map((message) => {
				
				const date = dayjs(new Date(message.date)).local().format('YYYY-MM-DD HH:mm:ss');
				if (this.state.TechSelected.name === 'All Chat') {
					const _all_chat = message.from === 'Auto Msg' || message.from === 'Mobile Mode';

					return (
						<tr key={message.chat_id}>
							<td>
									<div style={{ userSelect: 'text' }}>
									{!_all_chat ? (
										<div className='from-message-signature'>
											{message.from} {`${date}`}
										</div>
									) : (
										<div className='to-message-signature'>{`${date}`}</div>
									)}
									<div className={_all_chat ? 'from-message-body-container' : 'to-message-body-container'}>
										<div className='message-body'>
											<div className='message-body-text'>{message.body}</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					);
				} else {
					return (
						<tr key={message.chat_id}>
							<td>
									<div style={{ userSelect: 'text' }}>
									{message.from !== this.state.TechSelected.name ? (
										<div className='from-message-signature'>
											{message.from} {`${date}`}
										</div>
									) : (
										<div className='to-message-signature'>{`${date}`}</div>
									)}
									<div
										className={
											this.state.TechSelected.name === 'All Chat' || message.from === this.state.TechSelected.name
												? 'from-message-body-container'
												: 'to-message-body-container'
										}
									>
										<div className='message-body'>
											<div className='message-body-text'>{message.body}</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					);
				}
			});

			_messages.unshift(
				<tr key='load-more'>
					<td className='flex-just-even'>
						{!this.state.loadMore ? (
							<MuiButton className='rti-blue-round' variant='contained' onClick={this.onLoadMore}>
								load more
							</MuiButton>
						) : (
							<CircularProgress />
						)}
					</td>
				</tr>
			);

			return _messages;
		}
	}

	onLoadMore = () => {
		const messages = this.state.Messages;
		const _pages = this.state.numPages + 1;

		if (this.state.TechSelected.id !== 'all') {
			const tech_unread = {
				tech_id: this.state.TechSelected.id,
				name: this.state.TechSelected.name,
			};
			this.setState({ Loading: false, loadMore: true, numPages: _pages }, () => {
				this.GetMessagesForTech(tech_unread, this.state.Unread, messages, false);
			});
		} else {
			this.setState({ Loading: false, loadMore: true, numPages: _pages }, () => {
				this.GetMessagesForTech('all', this.state.Unread, messages, false);
			});
		}
	};

	GetTechMessages = (e) => {
		const tech = e.target.id;
		const messages = this.state.Messages;
		if (tech !== 'all_chat_button') {
			const techId = Number(tech); // Convert tech to an integer
			const tech_unread = messages.filter((e) => {
				return e.tech_id === techId;
			})[0];
			const unread = this.state.Unread - tech_unread.unreadCount;
			this.setState({ Loading: true, Messages: [] });
			this.GetMessagesForTech(tech_unread, unread, messages, true);
		} else {
			this.setState({ Loading: true, Messages: [] });
			this.GetMessagesForTech('all', this.state.Unread, messages, false);
		}
	};

	GetMessagesForTech(tech, unread, saved_messages, message_read) {
		socket.emit(
			'getMessagesForTech',
			tech === 'all' ? 'all' : tech.tech_id,
			message_read,
			this.state.numPages,
			(messages) => {
				if (!messages) {
					console.error('There was an issue getting messages from this tech.');
					this.setState({ Loading: false, Messages: [] });
				} else {
					if (message_read) {
						const list_to_update = saved_messages
							.map((message) =>
								message.tech_id === tech.tech_id && message.saved
									? { ...message, unreadCount: 0 }
									: message.tech_id === tech.tech_id && !message.saved
									? null
									: message
							)
							.filter((message) => message !== null);

						socket.emit('saveChats', JSON.stringify(list_to_update), this.state.User, (response) => {
							if (!response) {
								console.error('There was an issue updating users saved chats');
							} else {
								this.setState(
									{
										Loading: false,
										loadMore: false,
										AllMessages: false,
										Unread: unread,
										TechSelected: { name: tech.name, id: tech.tech_id },
										Messages: messages,
									},
									() => {
										if (this.state.numPages === 1) {
											document.querySelectorAll('#scrollable-table').forEach((element) => {
												element.scrollTop = document.getElementById('scroll-body').scrollHeight;
											});
										} else {
											document.querySelectorAll('#scrollable-table').forEach((element) => {
												element.scrollTop =
													document.getElementById('scrollable-table').scrollHeight / this.state.numPages;
											});
										}
									}
								);
							}
						});
					} else {
						if (tech === 'all') {
							this.setState(
								{
									Loading: false,
									loadMore: false,
									AllMessages: false,
									Unread: unread,
									TechSelected: { name: 'All Chat', id: 'all' },
									Messages: messages,
								},
								() => {
									if (this.state.numPages === 1) {
										document.querySelectorAll('#scrollable-table').forEach((element) => {
											element.scrollTop = document.getElementById('scroll-body').scrollHeight;
										});
									} else {
										document.querySelectorAll('#scrollable-table').forEach((element) => {
											element.scrollTop =
												document.getElementById('scrollable-table').scrollHeight / this.state.numPages;
										});
									}
								}
							);
						} else {
							this.setState(
								{
									Loading: false,
									loadMore: false,
									AllMessages: false,
									Unread: unread,
									TechSelected: { name: tech.name, id: tech.tech_id },
									Messages: messages,
								},
								() => {
									if (this.state.numPages === 1) {
										document.querySelectorAll('#scrollable-table').forEach((element) => {
											element.scrollTop = document.getElementById('scroll-body').scrollHeight;
										});
									} else {
										document.querySelectorAll('#scrollable-table').forEach((element) => {
											element.scrollTop =
												document.getElementById('scrollable-table').scrollHeight / this.state.numPages;
										});
									}
								}
							);
						}
					}
				}
			}
		);
	}

	UpdateSavedChats = (e, type) => {
		if (type === 'add') {
			const name = document.getElementById('chat-select').value;
			const valid = this.state.Messages.filter((e) => e.name === name);
			const tech = this.state.MessageList.find((tech) => tech.name === name);

			if (name && tech) {
				const list_to_update = [
					...this.state.Messages,
					{ tech_id: tech.tech_id, name: name, unreadCount: 0, saved: true },
				];
				if (!valid.length) {
					socket.emit('saveChats', JSON.stringify(list_to_update), this.state.User, (response) => {
						if (!response) {
							console.error('There was an issue updating users saved chats');
						} else {
							document.getElementById('chat-select').value = '';
							this.setState({ Messages: list_to_update });
						}
					});
				} else {
					this.props.onAlert({
						message: 'WARNING: Cannot add chat because it has already been saved to your list.',
						severity: 'warning',
					});
				}
			} else {
				this.props.onAlert({
					message:
						'WARNING: Technicians name cannot be blank.  Please make sure that you select the chat that you would like to add and try again.',
					severity: 'warning',
				});
			}
		} else if (type === 'remove') {
			const user = this.state.User;
			const tech = e.target.id;
			const tech_unread = this.state.Messages.filter((e) => e.tech_id === Number(tech))[0];
			const list_to_update = this.state.Messages.filter((e) => e.tech_id !== Number(tech));
			const unread = this.state.Unread - tech_unread.unreadCount;

			socket.emit('saveChats', JSON.stringify(list_to_update), user, (response) => {
				if (!response) {
					console.error('There was an issue updating users saved chats');
				} else {
					socket.emit('clearTechsUnreadMessages', tech, (response) => {
						if (!response) {
							console.error('There was an issue clearing this technicians unread chat messages.');
							this.setState({ Messages: list_to_update, Unread: unread });
						} else {
							this.setState({ Messages: list_to_update, Unread: unread });
						}
					});
				}
			});
		}
	};

	ClickSend(e) {
		if (e.keyCode === 13) {
			document.getElementById('send-message-button').click();
		}
	}

	/**
	 * Sends a message to either all technicians or a selected technician.
	 *
	 * If the message input is not empty, it will clear the input field and set the state to indicate that a message is being sent.
	 *
	 * If the selected technician is "all", it sends a mass message to all technicians by emitting a socket event and making a POST request to the server.
	 * If the selected technician is an individual, it sends a message to that specific technician using the Fetch API.
	 *
	 * After sending the message, it updates the state with the new messages and scrolls the message view to the bottom.
	 *
	 * If there is an error during the process, it logs the error and displays an alert.
	 *
	 * @function SendMessage
	 * @memberof ChatModal
	 */
	SendMessage = async () => {
		let message = document.getElementById('message-input').value;

		if (message) {
			document.getElementById('message-input').value = '';
			this.setState({ MessageSending: true });

			if (this.state.TechSelected.id === 'all') {
				socket.emit('SelectTechsForAllChat', (returnObject) => {
					let technicians = returnObject;
					const message_to_send = {
						body: message,
						from: this.state.User,
						time: CurrentTime(),
					};

					$.post('/api/send_message_all', { messages: message_to_send, techs: technicians }, (res) => {
						socket.emit('getMessagesForTech', 'all', false, this.state.numPages, (messages) => {
							if (!messages) {
								console.error('There was an issue getting messages from this tech.');
								this.setState({ MessageSending: false });
							} else {
								this.setState(
									{
										AllMessages: false,
										AllChat: false,
										Messages: messages,
										MessageSending: false,
									},
									() => {
										document.querySelectorAll('#scrollable-table').forEach((element) => {
											element.scrollTop = document.getElementById('scroll-body').scrollHeight;
										});
									}
								);
							}
						});
					});
				});
			} else {
				socket.emit('getTechNumber', this.state.TechSelected.name, (tech_number) => {
					if (!tech_number) {
						console.error('There was an issue getting this technicians phone number');
						this.setState({ MessageSending: false });
					} else {
						const text_message = {
							body: message.trim(),
							to: tech_number,
							from: this.state.FullName,
							time: CurrentTime(),
							type: 'tech',
						};
						fetch('/api/send_message_async', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({ messages: text_message }),
						})
							.then((response) => {
								if (response.status === 200) {
									socket.emit(
										'getMessagesForTech',
										this.state.TechSelected.id,
										false,
										this.state.numPages,
										(messages) => {
											if (!messages) {
												console.error('There was an issue getting messages from this tech.');
												this.setState({ MessageSending: false });
											} else {
												this.setState(
													{
														AllMessages: false,
														Messages: messages,
														MessageSending: false,
														Loading: false,
													},
													() => {
														document.querySelectorAll('#scrollable-table').forEach((element) => {
															element.scrollTop = document.getElementById('scroll-body').scrollHeight;
														});
													}
												);
											}
										}
									);
								} else {
									this.props.onAlert({
										message: 'ERROR: There was an issue sending this message to this technician',
										severity: 'error',
									});

									this.setState({ MessageSending: false });
								}
							})
							.catch((error) => {
								this.props.onAlert({
									message: 'ERROR: There was an issue sending this message to this technician',
									severity: 'error',
								});
								this.setState({ MessageSending: false });
							});
					}
				});
			}
		} else {
			this.props.onAlert({
				message: 'WARNING: The message you are trying to send cannot be blank.',
				severity: 'warning',
			});
		}
	};

	handleClearAllMessages = () => {
		socket.emit('saveChats', JSON.stringify([]), this.state.User, (response) => {
			if (!response) {
				console.error('There was an issue updating users saved chats');
				this.props.onAlert({
					message: 'ERROR: Server encountered an issue trying to clear messages.  Please notify the developer.',
					severity: 'error',
				});
			} else {
				this.setState({ Messages: [], Unread: 0, numPages: 1 });
				this.props.onAlert({
					message: 'Success!  Messages have been cleared.',
					severity: 'success',
				});
			}
		});
	};

	RenderMessaging() {
		let divtorender = null;

		if (!this.state.Loading) {
			divtorender = (
				<div className='chat-messages-container'>
					<div
						style={this.state.AllMessages ? { height: '482px' } : null}
						className='message-container'
						id='message-table'
					>
						<div
							style={
								this.state.AllMessages
									? { height: '482px', borderTop: '1px solid lightgray' }
									: { height: '478px', borderTop: '1px solid lightgray' }
							}
							className='scrollable-table'
							id='scrollable-table'
						>
							<table className='chat-table' id='chat-table'>
								<tbody id='scroll-body'>{this.RenderMessageBody()}</tbody>
							</table>
						</div>
					</div>
					{!this.state.AllMessages && !this.state.MessageSending ? (
						<div className='chat-input-container' id='chat-input-container'>
							<div className='chat-input' id='chat-input'>
								<textarea id='message-input' placeholder='Send a message...' onKeyDown={this.ClickSend}></textarea>
								<IconButton style={{ width: 46, height: 46 }} onClick={this.SendMessage} id='send-message-button'>
									<AiOutlineSend />
								</IconButton>
							</div>
						</div>
					) : !this.state.AllMessages && this.state.MessageSending ? (
						<div className='chat-input-container' id='chat-input-container'>
							<div className='chat-input' id='chat-input'>
								<Spinner size='small'></Spinner>
							</div>
						</div>
					) : (
						<div className='chat-input-container' id='chat-input-container'>
							<div className='chat-input' id='chat-input'>
								<img style={{ padding: '15px 0px' }} src={`${SearchImg}`} width='25px' height='25px'></img>
								<input id='chat-select' list='users' name='message-list' type='text' placeholder='Search...'></input>
								<datalist id='users'>{this.RenderMessageList()}</datalist>
								<div style={{ position: 'relative', bottom: '6px' }}>
									<Button
										name='add'
										color='rti-blue'
										size='small'
										onClick={(e) => this.UpdateSavedChats(e, 'add')}
									></Button>
								</div>
							</div>
						</div>
					)}
				</div>
			);
		} else {
			divtorender = <Spinner margin='chat-margin'></Spinner>;
		}

		return divtorender;
	}

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
	};

	handleMenuOpen = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	RenderMessages() {
		let divtorender = null;

		divtorender = (
			<div className='chat-modal' id='chat-modal'>
				<div className='chat-header-container' id='chat-header-container'>
					{!this.state.AllMessages ? (
						<div className='chat-header' id='chat-header'>
							<IconButton onClick={this.BackButton} style={{ color: 'white', padding: 6 }}>
								<AiOutlineLeft />
							</IconButton>
							<div id='tech-avatar' style={{ paddingRight: 10 }}>
								<Avatar variant='circular' src={`${image_base}${this.state.TechSelected.id}.jpg`}>{`${
									this.state.TechSelected.name.split(' ')[0][0]
								}${this.state.TechSelected.name.split(' ')[1][0]}`}</Avatar>
							</div>
							<div style={{ display: 'flex' }}>
								<label style={{ fontSize: 22 }} className='chat-header-name' id='chat-header-name'>
									{this.state.TechSelected.name}
								</label>
							</div>
							<IconButton onClick={this.ModalHandler} style={{ color: 'white', padding: 6 }}>
								<AiOutlineClose />
							</IconButton>
						</div>
					) : (
						<div className='chat-header' id='chat-header'>
							<IconButton onClick={this.handleMenuOpen} style={{ color: 'white', padding: 6 }}>
								<MdMoreHoriz />
							</IconButton>
							<Menu anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleMenuClose}>
								<MenuItem onClick={this.handleClearAllMessages}>Clear Messages</MenuItem>
							</Menu>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									marginLeft: 16,
								}}
							>
								<h6 style={{ margin: '0px', color: 'white' }}>Messages</h6>
							</div>
							<IconButton onClick={this.ModalHandler} style={{ color: 'white', padding: 6 }}>
								<AiOutlineClose />
							</IconButton>
						</div>
					)}
				</div>
				{this.RenderMessaging()}
			</div>
		);

		return divtorender;
	}

	render() {
		return (
			<div className='chat-modal-container'>
				{this.RenderMessages()}
				<div style={{ paddingTop: '10px', marginLeft: 'auto', width: '63px' }}>
					<Button color='rti-blue' size='medium-circle' onClick={this.ModalHandler}>
						{!this.state.ModalOpen ? (
							<img src={`${ChatImg}`} width='75%'></img>
						) : (
							<img src={`${CloseImg}`} width='75%'></img>
						)}
					</Button>
				</div>
				{this.state.Unread ? <span className='message-badge'>{this.state.Unread}</span> : null}
				<audio className='ChatRecievedMsg' id='ChatRecievedMsg' src={ChatRecievedMsg} muted={true}></audio>
				<audio id='responseRecievedAudio' src={JobResponse} muted={true}></audio>
			</div>
		);
	}
}